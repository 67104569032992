import React, { useState, useEffect } from 'react';

export const files_seporator = '[(----)]';
export const makeTextFile = (name, text) => {

    return new File([new Blob([text], { type: 'text/plain' })], [name]) 
}

export function print(...obj){
    console.log(...obj);
}
export const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
}
export const fecthJSON = async (url, _json={}) =>{
    return await fetch(url, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'key': 'super secret key',
    },
    body: JSON.stringify(_json)
    })
    .then((response) => response.json())
    .then((data) => {
        if(typeof(data) == 'string'){
        var _data = JSON.parse(data);
        }else{var _data = data;}
        //captureData(_data);
        return _data;
    })
  }

export const cleanNumberString = (string) => {
    if(typeof string !== 'string') return '0';
    let hasOneDot = false;
    let n = string.split('').filter(v=>{let i = '1234567890'.split('').indexOf(v) > -1; if(i) {return i;} if(v == '.' && !hasOneDot) {hasOneDot = true;return true;} return false;}).join('');
    return n === '.' ? '0.' : n;
}



import { useSyncExternalStore } from 'react';

export function useWindowDimensions() {
    // the 3rd parameter is optional and only needed for server side rendering
    return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}

function subscribe(callback) {
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
}

function getSnapshot() {
    return { width: window.innerWidth, height: window.innerHeight };
}

function getServerSnapshot() {
    return {
        width: 0,
        height: 0,
    };
}




