import React, { Component, useState, useEffect, forwardRef, useCallback, useRef, useImperativeHandle, useMemo } from "react";
import Spreadsheet, { DataEditor } from "react-spreadsheet";

import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from '../Custom/Loader.js';

import { GooeyCircleLoader } from "react-loaders-kit";
import styled from "styled-components";
import { HookableComponent } from 'react-hookable-component';
import { motion } from "framer-motion"//"framer-motion/dist/framer-motion";
import { Col, OverlayTrigger, Row, Tooltip } from "@themesberg/react-bootstrap";
import { isObject, random } from "lodash";
import { FormControlUnstyled } from "@mui/base";
import {distance} from './Levenshtein.js'
import { cleanNumberString } from "../utilities.js";

const timeout = (delay) => {
  return new Promise( res => setTimeout(res, delay) );
}
// import $ from 'jquery';

//import Typed from "react-typed";
const Style = styled.div`
  .side-by-side {
    display: flex;
    flex-direction: row;
  }
  .container--row{
    display: flex;
    display: grid !important;
    align-items: center;
  }
  .lay1{
    grid-column: 1 !important;
    grid-row: 1 !important;
  }
  .lay2{
    grid-column: 1 !important;
    grid-row: 1 !important;
    //margin: auto !important;
    align-self: right !important;
    margin-left: 80% !important;
  }
  /* .layer1{
    @media screen and (max-width: 768px) {
        transform: scale(0.75);
    }
    //position: absolute;
    //margin-left: -15%;
    //margin-top: 5%;
    //width: 100%;
  }  */
/*   .layer2{
    grid-column: 1;
    grid-row: 1;
  } */
  .layer2 {
    //width: 100%;
    //margin-left: -100%;
    //position: fixed;
    display: flex;
    padding-bottom: 150px;
    //z-index: 6;
    pointer-events: none;
  }

  .fadeOut{
    opacity: initial !important;
    opacity: 0  !important;
     
     width: 0%;
      height: 0%;
      transition: all 0.2s ease-in-out;
  }
  .fadeIn{
    
    
    //opacity: initial !important;
    opacity: 1 !important;
    
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    /* -webkit-animation: fadein 2s; // Safari, Chrome and Opera > 12.1 
       -moz-animation: fadein 2s; // Firefox < 16 
        -ms-animation: fadein 2s; // Internet Explorer 
         -o-animation: fadein 2s; // Opera < 12.1 
            animation: fadein 2s; */
    }

    /* @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

// Firefox < 16 
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

// Safari, Chrome and Opera > 12.1 
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

// Internet Explorer 
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

// Opera < 12.1 
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */
`;
const CLoader = styled(Loader)`

`;
export const CSpreadsheet = styled(Spreadsheet)`
  /* @media screen and (max-width: 1000px) {
        transform: scaleX(0.85);
  } */
  @media screen and (max-width: 868px) {
      transform: scaleX(0.85);
    }

    @media screen and (max-width: 770px) {
      transform: scaleX(1);
    }
`;

const CInput = styled(motion.input)` //styled.input
  border-radius: 0;
  width: 150px;
  :focus {
    //width: 180px;
    outline-style: none;
    outline: none;
    border-radius: 0;
  }
  @media screen and (max-width: 1000px) {
        width: 100px;
  }
  @media screen and (max-width: 768px) {
        width: 75px;
    }

    @media screen and (max-width: 480px) {
        width: 75px;
    }
`;

const CSpan = styled.span`
  width: 20px;
`;

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const myHookValue = usePromiseTracker();
    return <Component {...props} myHookValue={myHookValue} />;
  }
}

/* var TRUE_TEXT = "TRUE";
var FALSE_TEXT = "FALSE";

var DataViewer = function (_a) {
    var cell = _a.cell, formulaParser = _a.formulaParser;
    var value = getComputedValue({ cell: cell, formulaParser: formulaParser });
    return typeof value === "boolean" ? (React__namespace.createElement("span", { className: "Spreadsheet__data-viewer Spreadsheet__data-viewer--boolean" }, convertBooleanToText(value))) : (React__namespace.createElement("span", { className: "Spreadsheet__data-viewer" }, value));
};
function convertBooleanToText(value) {
    return value ? TRUE_TEXT : FALSE_TEXT;
}


var DataEditor = function (_a) {
    var _b;
    var onChange = _a.onChange, cell = _a.cell;
    var inputRef = React__namespace.useRef(null);
    var handleChange = React__namespace.useCallback(function (event) {
        onChange(__assign(__assign({}, cell), { value: event.target.value }));
    }, [onChange, cell]);
    React__namespace.useEffect(function () {
        if (inputRef.current) {
            moveCursorToEnd(inputRef.current);
        }
    }, [inputRef]);
    var value = (_b = cell === null || cell === void 0 ? void 0 : cell.value) !== null && _b !== void 0 ? _b : "";
    return (React__namespace.createElement("div", { className: "Spreadsheet__data-editor" },
        React__namespace.createElement("input", { ref: inputRef, type: "text", onChange: handleChange, value: value, autoFocus: true })));
}; */
const sendAsin = async (a, v, r, c, name) => {
  console.log(String(`a: ${a}, v ${v}, r ${r} c ${c} name ${name}`));
  await fetch('/set-asins-in-cbreport-for-streport/'+name, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ row: r, col: c, asin: a, value: v })
  })
  .then((response) => response.json())
  .then((data) => {
    if(typeof(data) == 'string'){
      var _data = JSON.parse(data);
    }else{var _data = data;}
    console.log(_data);
});
}

const sendAsins = async (items) => {
  
  var newItems = {};
  while (items.length > 0) {
    const [r, c, v, a, n] = items.shift();
    if(!(n in newItems)){
      newItems[n] = {};
      newItems[n].rs = []; newItems[n].cs = []; 
      newItems[n].vs = []; newItems[n]._as = [];
    }
    newItems[n].rs.push(r); newItems[n].cs.push(c);
    newItems[n].vs.push(v); newItems[n]._as.push(a);
  }
  for (var key of Object.keys(newItems)) {
    console.log(key + " -> " + newItems[key]);
    const {rs, cs, vs, _as} = newItems[key]
    console.log(String(`as: ${_as}, vs ${vs}, rs ${rs}, cs ${cs}, name ${key}`));
      fetch('/set-asins-in-cbreport-for-streport/'+key, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ rows: rs, cols: cs, asins: _as, values: vs })
      })
      .then((response) => response.json())
      .then((data) => {
        if(typeof(data) == 'string'){
          var _data = JSON.parse(data);
        }else{var _data = data;}
        console.log(_data);
    });
    await timeout(200);
  }
}

const RangeView = ({ cell }) => {
  
  var _value = 'ok';
  const getValue = (_data) => {
    let val = "";
    if(_data != ""){ 
      if(_data.data.value != undefined)
      { val = _data.data.value; }
    }
    //this.cellChange(val);
    //this.setState({cell: val});
    return val;
  };
  return (<><span><span
    /* value={getValue({ data: cell }) || getValue("")} */
    /* onChange={e=>{this.setState({cell: e.target.value});
      console.log(e.target.value)}} */
    disabled
    /* precision={2} */
    style={{ pointerEvents: "none" }}
    maxLength={100}
  />{(getValue({ data: cell }) || getValue(""))}</span></>
  );
};

class Input extends Component {
  constructor(props){
    super(props);
    this.props = props;
  }
  render() {
    return (<input {...this.props}/>);
  }
}
const fecthJSON = async (url, body) =>{
  return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then((response) => response.json())
    .then((data) => {
        if(typeof(data) == 'string'){
          var _data = JSON.parse(data);
        }else{var _data = data;}
        return _data;
    })
}
/**
 * A hook to fetch async data.
 * @class useAsync
 * @borrows useAsyncObject
 * @param {object} _                props
 * @param {async} _.asyncFunc         Promise like async function
 * @param {bool} _.immediate=false    Invoke the function immediately
 * @param {object} _.funcParams       Function initial parameters
 * @param {object} _.initialData      Initial data
 * @returns {useAsyncObject}        Async object
 * @example
 *   const { execute, loading, data, error } = useAync({
 *    asyncFunc: async () => { return 'data' },
 *    immediate: false,
 *    funcParams: { data: '1' },
 *    initialData: 'Hello'
 *  })
 */
const useAsync = (props) => {
  const {
    asyncFunc, immediate, funcParams, initialData
  } = {
    ...props
  }
  const [loading, setLoading] = useState(immediate)
  const [data, setData] = useState(initialData)
  const [error, setError] = useState(null)
  const mountedRef = useRef(true)

  const execute = useCallback(params => {
    setLoading(true)
    return asyncFunc({ ...funcParams, ...params })
      .then(res => {
        if (!mountedRef.current) return null
        setData(res)
        setError(null)
        setLoading(false)
        return res
      })
      .catch(err => {
        if (!mountedRef.current) return null
        setError(err)
        setLoading(false)
        throw err
      })
  }, [asyncFunc, funcParams])

  useEffect(() => {
    if (immediate) {
      execute(funcParams)
    }
    return () => {
      mountedRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    execute,
    loading,
    data,
    error
  }
}

function CCell(props){
  const [val, setVal] = useState(props.items.v);
  const [loadingProductGroup, setLoadingProductGroup] = useState(false);
  const [loadingTargetACoS, setLoadingTargetACoS] = useState(false);
  const onChangeVal = useCallback(
    (e) => {
      setVal(e.target.value);
      console.log(props);
      props.items.onCellChange(props.items.r, props.items.c, e.target.value, props.items.asin, props.items.name);
    },
    [val]
    );
    
    /* useEffect(()=>{
      let mounted = true;
      (async()=>{
        await timeout(Math.floor(2000 + Math.random() * 2000));
        if(props.items.c != 1){
          while(mounted){
            if(loadingProductGroup || loadingTargetACoS){
              await fecthJSON('/api/get-df-key-value/'+props.items.name, {key: props.items.asin, base_value: val})
              .then((d) => {
                console.log(d);
                if(props.items.c == 2){
                  if(val == d.value['Product Group']) { setLoadingProductGroup(false); 
                  }
                  else{setLoadingProductGroup(true)}
                }else if(props.items.c == 3){
                  if(val == d.value['ACoS']) { setLoadingTargetACoS(false); 
                  }
                  else{setLoadingTargetACoS(true)}
                }else{console.log('we have a big problem')}
                //{value: {ACoS: "", Product Group: ""}}
              })
            }
            if (mounted && (loadingProductGroup || loadingTargetACoS)) {
              await send/Asin(props.items.asin, val, props.items.r, props.items.c, props.items.name); 
            }
          }
          await timeout(!(loadingProductGroup || loadingTargetACoS) ? 10000 : 1000);
        }
      })()
      //return () => { mounted = false; }
    }, []); */
  //useEffect(()=>{
    let mounted = true;
    const someFunc =async()=>{
      await timeout(Math.floor(2000 + Math.random() * 2000));
      while(true){
        try {
        //console.log(val);
        if(props.items.c != 1){
          await fecthJSON('/api/get-df-key-value/'+props.items.name, {key: props.items.asin, base_value: val})
          .then((d) => {
            // console.log(d);
            if(props.items.c == 2){
              console.log(`${val} == ${d.value['Product Group']}`)
              if(val == d.value['Product Group']) { setLoadingProductGroup(false);
              }
              else{setLoadingProductGroup(true)}
            }else if(props.items.c == 3){
              console.log(`${val} == ${d.value['ACoS']}`)
              if(val == d.value['ACoS']) { setLoadingTargetACoS(false); 
              }
              else{setLoadingTargetACoS(true)}
            }else{console.log('we have a big problem')}
            //{value: {ACoS: "", Product Group: ""}}
          })
        }
        if (mounted && (loadingProductGroup || loadingTargetACoS)) {
          //await send/Asin(props.items.asin, val, props.items.r, props.items.c, props.items.name); 
        }
        //else{ break; }
        
      }catch(err){console.log(err)}
      await timeout(3000);
      }
      
    }
    someFunc();
    // return () => { mounted = false; };
  //},[])
  return (
  <div className="container--row">
    <div  className="lay1">
      <CInput
      defaultValue={props.items.v}
      type={props.items.type}
      onChange={onChangeVal}
      
      autoFocus
      disabled={props.items.c == 1}
      maxLength={100}/>
    </div>
    <div className="lay2">
      <Loader loading={(loadingProductGroup || loadingTargetACoS)} /* promiseTracker={usePromiseTracker} */ gooey={true} 
      custom={{loading: true, size: 25, duration: 5, colors: ["#262B40", "#262B40", "#262B40"]}}/>
    </div>
  </div> );
}

export class DropDown extends Component {
  static defaultProps = {
    value: '<value>',
    options:  ['1', '2', 'three'],
    startOpen: true,
    forceValue: false,
    onChange: e=>{},
  }
  ref = 0;
  state = {
    value: this.props.value,
    options: this.props.options,
    open: this.props.startOpen,
    forceValue: this.props.forceValue,
  }

  handleInputChange = (e) => {
    this.setState({
      value: e.target.value, 
      options: this._sortOptions(this.state.options, e.target.value),
      open: true
    });
  }

  handleItemClick = (r) => {
    this.setState({value: r, open: true});
    this.props.onChange({target: {value: r}})
  }

  onExit = () => {
    if(this.state.forceValue){
      let v = this.sortOptions();
      console.log('v1: ', v)

      let val = v[0];
      console.log('val: ', val)
      this.props.onChange({target: {value: val}})
    }
  }

  handleKeyDown = e => {
    if(e.key == 'Enter') {
      this.onExit();
    }
  }

  sortOptions = () => {
    return this._sortOptions(this.state.options, this.state.value)
  }

  _sortOptions = (_options, value) => {
    if(typeof(value) != 'string') return _options;
    if(value.length < 1) return _options;

    return [].concat(_options).sort((a, b) => distance(a, value) > distance(b, value) ? 1 : -1);
  }
  
  render () {
    const {value, options, open} = this.state;
    return (<>
      <div style={{backgroundColor: "white", textAlign: 'center', transition: "all .3s ease-in-out"}}
      onClick={()=>{this.setState({open: !open});}}>
        <CInput ref={this.ref} /* value={value} 
        onChange={} */ {...{...this.props, onKeyDown: this.handleKeyDown, onBlur: this.onExit, onChange: e=>{this.props.onChange(e);this.handleInputChange(e)}}}>
          
        </CInput>
        <p> {value} </p>
      </div>
      <div style={{backgroundColor: "white", textAlign: 'center', transform: open ? "scale(1)" : "scale(0)", transition: "all .3s ease-in-out"}}>
        {(open ? options : [])?.map(r => <p onClick={()=>{this.handleItemClick(r)}}> {r} </p>)}
        {/* {(open ? options : [])?.map(r => <p onClick={()=>{this.setState({value: r, open: false});this.onValueChange(r);}}> {r} </p>)} */}
      </div>
    </>);
  }
}

class Cell extends Component {
    constructor(props){
      super();
      //[this.v, this.setV] = useState(null)
      this.state = {
        cell: props.v,
        r: props.r,
        c: props.c,
        asin: props.asin,
        name: props.name,
        type: props.type,
        extra: props.extra,
        key: props.key,
      };


      this.first = 0;
      this.lastValue = props.v;
      this.val = props.v;
      this.viewLastValue = '';
      this.onCellChange = props.onCellChange;
      this.cellChange = this.cellChange.bind(this);

      this.input = React.createRef()
      // this.loop = this.loop.bind(this);
      // this.loop();
    }
    async cellChange(v){
      this.onCellChange(this.state.r, this.state.c, String(v), this.state.asin, this.state.name);
      return
      if(String(v) != String(this.lastValue)){
        this.lastValue = String(v);
        this.onCellChange(this.state.r, this.state.c, String(v), this.state.asin, this.state.name);
      }else{
        this.first++;
      }
    }

    async loop() {
      await timeout(Math.random() * 2000.0);
      while(true){
        if (this.viewLastValue != this.val) {
          //send/Asin(this.state.asin, this.val, this.state.r, this.state.c, this.state.name);
          this.viewLastValue = this.val;
        }
        await timeout(5000);
      }
    }
    rangeView = ({ cell }) => {
      //const [cust, setCust] = useState('');
      var _value = 'ok';
      const getValue = (_data) => {
        let val = "";
        if(_data != ""){ 
          if(_data.data.value != undefined)
          { val = _data.data.value; }
          //else{send/Asin(this.state.asin, "", this.state.r, this.state.c, this.state.name);}
        }
        this.cellChange(val);
        this.setState({cell: val});
        this.val = val;
        return val;
      };
      return (<><span><span
        /* value={getValue({ data: cell }) || getValue("")} */
        /* onChange={e=>{this.setState({cell: e.target.value});
          console.log(e.target.value)}} */
        disabled
        /* precision={2} */
        style={{ pointerEvents: "none" }}
        maxLength={100}
      />&nbsp;{(getValue({ data: cell }) || getValue(""))+this.state.extra}</span></>
      );
    };

    rangeEdit = ({ cell, onChange }) => {
      var _cell = 'ok';
      const getValue = (_data) => {
        let val = "";

        if(_data != ""){ 
          if(_data.data.value != undefined)
          { val = _data.data.value; }
        }
        return val;};
      return (<>
      {/* <DropDown options={['5', 'six', '7'] }
      type={this.state.type}
      onChange={e => {
        onChange({...cell, value: e.target.value});
      }}
      precision={2}
      value={getValue({ data: cell }) || getValue("")}
      autoFocus
      maxLength={100}
      forceValue={true}
      /> */}
      <CInput
        type={this.state.type}
        onChange={e => {
          onChange({...cell, value: e.target.value});
        }}
        precision={2}
        value={getValue({ data: cell }) || getValue("")}
        autoFocus
        maxLength={100}
      />
      </>);
      };

      render() {
        return (
        <input
        ref={this.input}
        key={this.state.cell ? `notLoadedYet${[this.props.r, this.props.c]}` : [this.props.r, this.props.c]}
        defaultValue={this.state.cell}
        
        type={this.state.type}
        onChange={e => {
          this.setState({cell: e.target.value});
        }}
        autoFocus
        maxLength={100}
        /> 
        );
      }
  }


const data = [
    [{ value: "Vanilla" }, { value: "Chocolate" }],
  [{ value: "Vanilla" }, { value: "Chocolate" }],
  [{ value: "Strawberry" }, { value: "Cookies" }],
  [{ value: "Vanilla" }, { value: "Chocolate" }],
];

//ok
const col = ["Asin" , "Product Group", "Target ACoS" ];
class Excel extends Component {
    constructor(props){
        super(props);
        this.onCellChange = this.onCellChange.bind(this);
        /* this.name = props.name */
        this.props = props
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.state = {
          excelData: [[{ value: "" }, { value: "" }, { value: "" }]],
          loading: false,
          name: 'none',
          cursorR: 0, 
          cursorC: 0,
          displayText: 'saved',
        }
        this.cellChanges = [];
        this.lastTime = new Date();
        //this.state.excelData = props.data; 
        //this.setExcelData = (d) => {this.setState({excelData: d});}//{this.state.excelData = d;}
        //[this.excelData, this.setExcelData] = useState(props.data);
        //this.cellData = props.data;
        this.setCellData = (d) => {this.cellData = d;}
        //this.setData(this.state.excelData, 'none');
        // setInterval(
        // ()=>{
        //     console.log(this.getData());
        // }, 
        // 10000);
        //this.customFetchData();
        this.customFetchData = this.customFetchData.bind(this);
        this.setData = this.setData.bind(this);
        this.setData([[{ value: "Placement text" }, { value: "" }, { value: "" }],
        [{ value: "Placement text" }, { value: "" }, { value: "" }],
        [{ value: "Placement text" }, { value: "" }, { value: "" }],
        [{ value: "Placement text" }, { value: "" }, { value: "" }]], 'none')
        this.getData = this.getData.bind(this);
        this.getDataState = this.getDataState.bind(this);
        this.updateData = this.updateData.bind(this);
        /* if(typeof(props.name) == 'string'){
          if(props.name.length > 0){
            const myFunc = async() =>{
              await setTimeout(2000);
              this.customFetchData(props.name)
            }
            myFunc();
          }
        } */
        this.sendAll = this.sendAll.bind(this);
        
        
        this.loop = this.loop.bind(this);
        this.loop();
    }
    async sendAll(){
      let vals = [];
      if(this.cellChanges.length > 0){ this.setState({displayText: 'saving...'}); }
      // deep copy
      while(this.cellChanges.length > 0)
      { let [r, c, v, a, n] = this.cellChanges.shift(); vals.push([r, c, v, a, n]); }
      if(vals.length > 0)
      { sendAsins(vals); }
    }
    async loop(){
      await timeout(100);
      while (true) {
        //this.getData();
        if(this.cellChanges.length > 0){
          this.setState({displayText: 'saving...'})
          let [r, c, v, a, n] = this.cellChanges.shift();
          let indexes = [];
          for (let i = 0; i < this.cellChanges.length; i++) {
            const [r1, c1, v1, a1, n1] = this.cellChanges[i];
            if(r1 == r && c1 == c && a == a1 && n1 == n){
              v = v1;
              indexes.push(i);
            }
          }
          for (let i = indexes.length-1; i >= 0; i--) {
            const e = indexes[i];
            this.cellChanges.splice(e, 1);
          }
          await sendAsin(a, v, r, c, n);
          if((new Date() - this.lastTime) / 1000.0 > 7.0){
            this.sendAll()
          }
        }
        else{
          this.setState({displayText: 'saved'})
        }
        await timeout(1000);
      }
      //here
    }
    

    /* componentDidUpdate(prevProps, prevState) {
      Object.entries(this.props).forEach(([key, val]) =>
        prevProps[key] !== val && console.log(`Prop '${key}' changed`)
      );
      if (this.state) {
        Object.entries(this.state).forEach(([key, val]) =>
          prevState[key] !== val && console.log(`State '${key}' changed`)
        );
      }
    } */
   /*  componentDidMount() {
      this.props.fetchData(this.customFetchData);
    } */
    /* useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
        setData(data);
        const myInterval = setInterval(
            ()=>{
              console.log(cellData);
            }, 
            10000);
    
            return () => clearInterval(myInterval);
      }, []); */
    
    onCellChange(r, c, v, a, name){
      this.lastTime = new Date();
      if(c != 1){
        this.cellChanges.push([r, c, v, a, name]);
        //console.log([r, c, v, a, name]);
      }
      return;
      //console.log('r: '+String(r)+',c: '+String(c)+', v: '+String(v)+', a: '+String(a)+' yay passed the fiirst step!!!!!');

      
      const sendAsinS = async(a, v) => {
        for (let i = 0; i < 3; i++) {
          await sendAsin(a, v, r, c, name);
          await timeout(1000);
          break;
        }
      }
      sendAsinS(a, v);
    }
    setData = (__data, name) => {
        var _cellData = [];
        var _data = [];
        console.log(name, __data)
        
        for (let i = 0; i < __data.length; i++) {
            const element = __data[i];
            _data.push([]);
            _cellData.push([]);
            for (let j = 0; j < element.length; j++) {
                let _type = j < 2 ? 'text' : 'number';
                let _extra = j < 2 ? '' : '%';
                var _cell = new Cell({type: _type, extra: _extra,
                    v: element[j].value, c: j+1, r: i+1, 
                    onCellChange: this.onCellChange, asin: j==0 ? 'none' : element[0].value,
                    name: name});
                    // _data[i].push({type: _type, extra: _extra,
                    //   v: element[j].value, c: j+1, r: i+1, 
                    //   onCellChange: this.onCellChange, asin: j==0 ? 'none' : element[0].value,
                    //   name: name});
                _data[i].push( { value: element[j].value, DataViewer: _cell.rangeView, DataEditor: _cell.rangeEdit });
                _cellData[i].push(_cell);
            }
        }
        //this.setExcelData(_data);
        //this.state.excelData = _data;
        this.setState({excelData: _data},
          ()=>{//this.forceUpdate();
            this.setState(this.state);
            (async()=>{
              await timeout(1000);
              this.sendAll();
            })()
          });
        //console.log(this.state.excelData )
        this.setCellData(_cellData);
        
        
    };

    updateData = async() => {
      return;
      for (let i = 0; i < this.cellData.length; i++) {
        const element = this.cellData[i];
        for (let j = 0; j < element.length; j++) {
          if(j != 0 && this.viewLastValue != this.val){
            const e = element[j];
            sendAsin(e.state.asin, e.val, e.state.r, e.state.c, e.state.name);
            //await timeout(500);
          }
        }
      }
      return ;
        for (let i = 0; i < this.cellData.length; i++) {
            const element = this.cellData[i];
            for (let j = 0; j < element.length; j++) {
                const e = element[j];
                if(e.name == 'none'){break;}
                this.onCellChange(j+1, i+1, e.lastValue, e.asin, e.name);
            }
        }
    }
    getData = async() =>{
      return;
        var _data = [];
        for (let i = 0; i < this.cellData.length; i++) {
          const element = this.cellData[i];
          _data.push([])
          for (let j = 0; j < element.length; j++) {
              const e = element[j];
              //sendAsin(e.state.asin, e.val, e.state.r, e.state.c, e.state.name);
              _data[i].push([e.state.asin, e.val, e.state.r, e.state.c, e.state.name]); //_element.val);
          }
      }
      //console.log(_data);
      return _data;
        //console.log(this.cellData);
        for (let i = 0; i < this.cellData.length; i++) {
            const element = this.cellData[i];
            _data.push([])
            for (let j = 0; j < element.length; j++) {
                const _element = element[j];
                _data[i].push(_element.val);
            }
        }
        //console.log(_data)
        return _data;
    }

    customFetchData = async(name, value) =>{
      //this.updateData();
      console.log('yaaayyy !!!!!')
      console.log('/asins-in-cbreport-for-streport/'+name);
      if(this.state.loading == false){
        this.setState({loading: true});
      }
      trackPromise(
      fetch('/asins-in-cbreport-for-streport/'+name)
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        try{
        if(typeof(data) == 'string'){
          var _data = JSON.parse(data);
        }else{var _data = data;}
        //console.log(_data);
        this.setData(_data, name)
        }catch(err){console.log(err)}
        this.setState({loading: false});
      })
      );
    }

    getDataState(){
      //console.log(this.state.excelData);
      if(this.state.excelData){return this.state.excelData;}
      else {return [[{ value: "" }, { value: "" }]];}
    }

    handleKeyDown(e) {
      const { cursorR, cursorC, excelData } = this.state
      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && cursorR > 0) {
        this.setState( prevState => ({
          cursorR: prevState.cursor - 1
        }))
      } else if (e.keyCode === 40 && cursorR < excelData.length - 1) {
        this.setState( prevState => ({
          cursorR: prevState.cursor + 1
        }))
      }
    }

    render1(){
      const { cursorR, cursorC } = this.state
      return (
        <Style>
          <form>
            <div className="side-by-side">
              <CCell {...{items: {v: "Asins", type: "text", c: 1}}}/>
              <CCell {...{items: {v: "Product Group", type: "text", c: 1}}}/>
              <CCell {...{items: {v: "Target ACoS", type: "text", c: 1}}}/>
            </div>
            {this.state.excelData?.map(function(item){
              return <div className="side-by-side">{item?.map(function(items){
              return <CCell
              {...{items, onCellChange: this.onCellChange}}
              /> 
            })}</div>})}
          </form>
        </Style>);
    }

    render(){ 
      return (
      <Style>
      <div className='container--row'>
        <div className='layer1--'>
          <CSpreadsheet {...{...this.props, data: this.state.excelData }}/>
          <OverlayTrigger
            placement="bottom"
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip> You may submit the report before saving is complete. </Tooltip>
            }>
          <p>{this.state.loading ? 'fetching data...' : this.state.displayText}</p>
         </OverlayTrigger>
        </div>
        <div className={this.state.loading ? 'fadeIn' : 'fadeOut' }>
          <Loader 
          /* loading={this.state.loading} */ 
          wave={true} 
          promiseTracker={usePromiseTracker}
          custom={{size: 50, color: "#ffffff", duration: 2, loading: true}}
          />
        </div>
      </div>
      
      </Style>); }
};
/* 
class Example extends Component {
  constructor(props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.state = {
      cursor: 0,
      result: []
    }
  }

  handleKeyDown(e) {
    const { cursor, result } = this.state
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > 0) {
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }))
    } else if (e.keyCode === 40 && cursor < result.length - 1) {
      this.setState( prevState => ({
        cursor: prevState.cursor + 1
      }))
    }
  }

  render() {
    const { cursor } = this.state

    return (
      <Container>
       
        <List>
          {
            result.map((item, i) => (
              <List.Item
                key={ item._id }
                className={cursor === i ? 'active' : null}
              >
                <span>{ item.title }</span>
              </List.Item>
            ))
          }
        </List>
      </Container>
    )
  }
} */

export default Excel;

/* class Excel2 extends Spreadsheet{
  constructor(props){
    super(props);

  }
}; */

// export const MExcel = (props) => {
//   const setData = (data) => {
//     props.onChange()
//     _setData(data);
//   }
//   const [data, _setData] = useState(props.data);
//   return (<>
//   <button onClick={()=>{
//                     setData([
//                         [{ value: "Vanilla" }, { value: "Chocolate" }],
//                         [{ value: "Strawberry" }, { value: "Cookies" }],
//                       ])
//                 }}> reset </button>
//   <Spreadsheet data={data} onChange={setData} /> {props.reset ? '' : ''}</>);
// };

// export class MExcel extends Component {
//   constructor(props){
//       super(props); this.props = props;
//       this.state = {
//         data: props.data ? props.data : [[{ value: "" }, { value: "" }, { value: "" }]]
//       }
//       this.customFetchData = this.customFetchData.bind(this);
//       this.setData = this.setData.bind(this);
//   }

//   customFetchData = (value) =>{
//     this.setData(value)
//   }

//   setData = async (_data) => {
//     this.setState({data: _data}, ()=>{});
//   }

//   render(){ 
//     return (
//     <Style>
//     <div className='container--row'>
//       <div className='layer1--'>
//         <CSpreadsheet {...{...this.props, data: this.state.data, onChange: this.setData }}/>
//       </div>
//     </div>
    
//     </Style>); }
// };

export function NumberEditor (props) {
  const {onChange, cell} = props;
  const _onChange = _cell => { 
      return onChange({...cell, value: cleanNumberString(_cell.value)}); 
  }
  return <DataEditor onChange={_onChange} cell={cell}/>;
}

export const CExcel = forwardRef((props, ref) => {
  const purifyCell = _cell => ({value: typeof _cell.value === 'string' ? _cell.value : '', 
                                ...('number' in _cell ? {number: true} : {}),
                                ...('readOnly' in _cell ? {readOnly: _cell.readOnly} : {})})
  const purifyData = _data => {return _data.map(row=>row.map(cell=>purifyCell(cell)))}
  const [table, _setTable] = useState(props.data ? props.data : [[{ value: "" }, { value: "" }, { value: "" }]]);
  const setTable = useCallback(v=>{ _setTable(v); if(props.onChange){props.onChange(purifyData(v))} }, [table]);

  const [loading, _setLoading] = useState(false);
  const setLoading = useCallback(v=>{ _setLoading(v); }, [table]);

  useImperativeHandle(ref, () => ({
    async setData (data){
      setTable(data);
    },
    handleLoading(promise){setLoading(true);trackPromise(promise.then(v=>{setLoading(false);}));},
    purify(data) {
      return purifyData(data);
    }
  }));

  const checkForNumber = _data => {
    const addNumberEditor = __cell => { return {...__cell, DataEditor: NumberEditor} }
    return _data.map(row=>row.map(_cell=>{
      return !isObject(_cell) ? _cell : ('number' in _cell ? addNumberEditor(_cell) : _cell)
    }))
  }

return (<Style>
  <div className='container--row'>
    <div className='layer1--'>
      <CSpreadsheet {...{...props, data: checkForNumber(table), onChange: setTable }}/>
    </div>
    <div className={loading ? 'fadeIn' : 'fadeOut' }>
          <Loader 
          /* loading={loading} */
          wave={true} 
          promiseTracker={usePromiseTracker}
          custom={{size: 50, color: "#ffffff", duration: 2, loading: true}}
          />
        </div>
  </div>
  </Style>);
});

