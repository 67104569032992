import * as React from 'react';
import { Form, Button } from '@themesberg/react-bootstrap';
import { write_json_to_excel } from 'src/utils/yoy';


const ValidInput = React.forwardRef((props, ref) => {
    const {type, defaultValue, placer, label='', checker} = props;

    const [response, setResponse] = React.useState(defaultValue);
    const [valid, setValid] = React.useState(true);
    const values = React.useRef({valid: checker(defaultValue), json: defaultValue}).current;

    React.useImperativeHandle(ref, ()=>{return {
        get: response,
        valid: valid
    }}, [response, valid]);

    const check = (value) => {
        if(checker) {
            // console.log('checking', )
            return checker(value);}
        return true;
    }

    const onChange = evt => {
        const v = (evt?.currentTarget?.value || '');
        setResponse(v);
        setValid(check(v));
        values.valid = check(v);
        values.json = v;
        // console.log('val', values.valid)
    }

    const kwargs = {};
    if(type === 'textarea'){kwargs.as = type;}
    else{kwargs.type = type;}

    return <Form>
        <Form.Group className="mb-3" >
            <Form.Label>{label}</Form.Label>
            <Form.Control {...kwargs} rows="3" placeholder={placer} isInvalid={!valid} isValid={valid}
            value={response} onChange={onChange}/>
            <Form.Control.Feedback type={valid ? "valid" : "invalid"}>{valid ? 'Valid' : 'Not Valid'}</Form.Control.Feedback>
        </Form.Group>
    </Form>
})


export const Yoy = React.forwardRef((props, ref2) => {
    const configRef = React.useRef();
    const jsonRef = React.useRef();

    const btn = () => {

        if(!configRef.current.valid || !jsonRef.current.valid){
            alert('values must be valid.');
            console.log('not valid');
            return;
        }
        console.log('valid');
        
        try{
            console.log(
                write_json_to_excel(jsonRef.current.get, (configRef.current.get || '{}'))
            );
        }catch(e){
            alert(`error: ${e}`)
        }
    }

    const isJson = j => {
        try{
            JSON.parse(j);
            return true;
        }catch(e){}
        return false;
    }

    return <div>
        {/* <input type='text' onChange={evt=>console.log(evt)}/> */}
        <ValidInput ref={configRef} label='Config' type='textarea' defaultValue={'{\n\t"spend":100000, \n\t"sales":100000, \n\t"cpc":100000, \n\t"ctr":100, \n\t"acos":100\n}'} placer='{}..' checker={isJson}/>
        <ValidInput ref={jsonRef} label='Year to Year Json' type='textarea' defaultValue={''} placer='{}..' checker={isJson}/>
        <Button variant="primary" className="m-1" onClick={btn}> Create and Download Excel </Button>
    </div>
})

export default Yoy;


const val = `{
    "campaigns": null,
    "data": [
        {
            "id": "A02755561J83CH3G8QXA9",
            "state": "ENABLED",
            "name": "Dot Markers - SD PT - STR WS - 081120",
            "calculatedStatus": {
                "statusName": "CAMPAIGN_STATUS_ENABLED",
                "statusReasons": [
                    "CAMPAIGN_STATUS_ENABLED_DETAIL"
                ]
            },
            "budget": {
                "millicents": 1000000,
                "currencyCode": "USD",
                "budgetType": "DAILY"
            },
            "status": "ENABLED",
            "startDate": 1597129200000,
            "endDate": null,
            "creationDate": 1597162592000,
            "isBidBoostEnabled": false,
            "isSyndicationActive": false,
            "mobileBidAdjustment": 0,
            "programType": "DA",
            "targetingType": "MANUAL",
            "bidType": "CPC",
            "portfolioId": "A27YTMW0UZEE7M",
            "portfolioName": "1 - Dot Markers",
            "bidAdjustments": [
                {
                    "bidAdjustmentPredicate": "PLACEMENT_GROUP_DETAIL_PAGE",
                    "percentage": 0
                },
                {
                    "bidAdjustmentPredicate": "PLACEMENT_GROUP_TOP",
                    "percentage": 0
                },
                {
                    "bidAdjustmentPredicate": "PLACEMENT_GROUP_OTHER",
                    "percentage": 0
                }
            ],
            "isAverageDaily": false,
            "campaignType": "SDCTX",
            "activeBudgetRule": {
                "ruleId": null,
                "ruleName": null,
                "ruleBasedBudget": null,
                "effectiveBudget": {
                    "millicents": 1000000,
                    "currencyCode": "USD"
                },
                "isBugetRuleProcessing": null,
                "areRulesAssociated": false
            },
            "campaignApiExternalId": "258821708050788",
            "unitsSold": 0,
            "detailPageViews": 0,
            "impressions": 3153402,
            "clicks": 13575,
            "spend": {
                "millicents": 745977000,
                "currencyCode": "USD"
            },
            "spendCoV": null,
            "sales": {
                "millicents": 2777638000,
                "currencyCode": "USD"
            },
            "salesCoV": null,
            "cpc": {
                "millicents": 54952,
                "currencyCode": "USD"
            },
            "cpcCoV": null,
            "ctr": 0.43048745450151926,
            "acos": 26.856523420258462,
            "roas": 3.723490134414338,
            "orders": 1072,
            "ntbOrders": 0,
            "ntbSales": {
                "millicents": 0,
                "currencyCode": "USD"
            },
            "ntbSalesCoV": null,
            "ntbPercentOfSales": 0.0,
            "ntbPercentOfOrders": 0.0,
            "conversionRate": null,
            "kindleUnlimitedPagesRead": 0,
            "kindleUnlimitedRoyalties": {
                "millicents": 0,
                "currencyCode": null
            },
            "kindleUnlimitedRoyaltiesCoV": null,
            "appDownloads": 1072,
            "videoStreams": 0,
            "purchases": 1072,
            "purchasesClicks": null,
            "purchasesViews": null,
            "rentals": 0,
            "subscriptionSignUps": 0,
            "costPerVideoStream": {
                "millicents": 0,
                "currencyCode": null
            },
            "cpm": {
                "millicents": 0,
                "currencyCode": null
            },
            "costPerAppDownload": {
                "millicents": 0,
                "currencyCode": null
            },
            "costPerSubscriptionSignUp": {
                "millicents": 0,
                "currencyCode": null
            },
            "detailPageViewsRate": 0.0,
            "appDownloadRate": 0.0,
            "videoStreamRate": 0.0,
            "subscriptionSignUpRate": 0.0,
            "effectiveDailyCoverage": 0.0,
            "estimatedMissedClicksRangeMin": null,
            "estimatedMissedClicksRangeMax": null,
            "estimatedMissedSalesRangeMin": null,
            "estimatedMissedSalesRangeMax": null,
            "estimatedMissedImpressionsRangeMin": null,
            "estimatedMissedImpressionsRangeMax": null,
            "viewableImpression": 0,
            "vcpm": {
                "millicents": 0,
                "currencyCode": null
            },
            "vcpmCoV": null,
            "topOfSearchImpressionShare": null,
            "cumulativeReach": 0,
            "brandedSearches": 0,
            "addToCart": 0,
            "videoStart": 0,
            "videoFirstQuartile": 0,
            "videoMidpoint": 0,
            "videoThirdQuartile": 0,
            "videoComplete": 0,
            "videoCompletionRate": 0.0,
            "totalDpvCount": 0,
            "correctedAcos": 26.856523420258462,
            "correctedRoas": 3.723490134414338,
            "videoUnmute": 0,
            "viewThroughRate": 0.0,
            "clickThroughRateForViews": 0.0,
            "appstoreFirstOpens": 0,
            "appstoreFirstOpenRate": 0.0,
            "costPerAppstoreFirstOpen": {
                "millicents": 0,
                "currencyCode": null
            },
            "appstoreFirstSessionHours": 0.0,
            "appstoreOpens": 0,
            "appstoreOpenRate": 0.0,
            "costPerAppstoreOpens": {
                "millicents": 0,
                "currencyCode": null
            },
            "appstoreUsageHours": 0.0,
            "totalSkillInvocations": 0,
            "ecpvc": {
                "millicents": 0,
                "currencyCode": null
            },
            "salesViews": null,
            "salesClicks": null,
            "orderViews": null,
            "orderClicks": null,
            "unitsSoldViews": null,
            "unitsSoldClicks": null,
            "brandedSearchesViews": null,
            "brandedSearchesClicks": null,
            "dpvViews": null,
            "dpvClicks": null,
            "ntbOrdersViews": null,
            "ntbOrdersClicks": null,
            "ntbSalesViews": null,
            "ntbSalesClicks": null
        }
    ],
    "timeSeries": {
        "categories": [
            "2020-08-01",
            "2020-09-01",
            "2020-10-01",
            "2020-11-01",
            "2020-12-01",
            "2021-01-01",
            "2021-02-01",
            "2021-03-01",
            "2021-04-01",
            "2021-05-01",
            "2021-06-01",
            "2021-07-01",
            "2021-08-01",
            "2021-09-01",
            "2021-10-01",
            "2021-11-01",
            "2021-12-01",
            "2022-01-01",
            "2022-02-01",
            "2022-03-01",
            "2022-04-01",
            "2022-05-01",
            "2022-06-01",
            "2022-07-01",
            "2022-08-01",
            "2022-09-01",
            "2022-10-01",
            "2022-11-01",
            "2022-12-01",
            "2023-01-01",
            "2023-02-01",
            "2023-03-01",
            "2023-04-01",
            "2023-05-01",
            "2023-06-01",
            "2023-07-01",
            "2023-08-01",
            "2023-09-01",
            "2023-10-01"
        ],
        "metrics": [
            {
                "name": "videoComplete",
                "summary": 0.0,
                "dataSet": [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            },
            {
                "name": "ntbPercentOfSales",
                "summary": 0.0,
                "dataSet": [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    null,
                    null,
                    null,
                    null,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    null
                ]
            },
            {
                "name": "videoMidpoint",
                "summary": 0.0,
                "dataSet": [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            },
            {
                "name": "sales",
                "summary": 2.777638E9,
                "dataSet": [
                    1.46191E8,
                    2.14266E8,
                    2.08772E8,
                    2.9718E8,
                    2.54662E8,
                    2.10687E8,
                    2.01908E8,
                    1.73721E8,
                    5.0577E7,
                    3.7383E7,
                    4.6179E7,
                    3.2985E7,
                    2.7929999E7,
                    1.5364999E7,
                    4.7646E7,
                    9.5447E7,
                    1.82939E8,
                    4.4985E7,
                    2.9989999E7,
                    5.6879999E7,
                    2.5244999E7,
                    4590000.0,
                    1.776E7,
                    5.068E7,
                    7.961E7,
                    4.8684999E7,
                    2.8534999E7,
                    7.058E7,
                    2.8855E7,
                    9885000.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    9980000.0,
                    9980000.0,
                    1.0975E7,
                    6584999.0,
                    0.0
                ]
            },
            {
                "name": "videoThirdQuartile",
                "summary": 0.0,
                "dataSet": [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            },
            {
                "name": "spend",
                "summary": 7.45977E8,
                "dataSet": [
                    5.5773999E7,
                    8.7814E7,
                    5.2618999E7,
                    4.226E7,
                    4.4959999E7,
                    4.2536999E7,
                    4.0564999E7,
                    3.4184E7,
                    1.2863E7,
                    8772000.0,
                    1.2257E7,
                    1.0071E7,
                    1.0154E7,
                    6834999.0,
                    2.2044E7,
                    3.5657999E7,
                    2.8944E7,
                    2.0711E7,
                    1.8602E7,
                    1.6726999E7,
                    9210000.0,
                    1652000.0,
                    7713000.0,
                    2.1014E7,
                    2.5584E7,
                    1.9606E7,
                    1.0013E7,
                    1.5063999E7,
                    1.1052E7,
                    2328000.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    6454000.0,
                    7263000.0,
                    3733999.0,
                    717000.0,
                    222000.0
                ]
            },
            {
                "name": "cpc",
                "summary": 54952.0,
                "dataSet": [
                    68771.0,
                    66829.0,
                    41628.0,
                    37497.0,
                    46350.0,
                    50043.0,
                    52409.0,
                    52349.0,
                    57168.0,
                    53487.0,
                    45735.0,
                    49126.0,
                    47227.0,
                    49172.0,
                    49648.0,
                    51528.0,
                    58949.0,
                    50391.0,
                    49605.0,
                    51626.0,
                    55481.0,
                    56965.0,
                    82935.0,
                    83059.0,
                    85852.0,
                    68313.0,
                    74170.0,
                    74206.0,
                    77286.0,
                    52909.0,
                    null,
                    null,
                    null,
                    null,
                    97787.0,
                    86464.0,
                    79446.0,
                    79666.0,
                    111000.0
                ]
            },
            {
                "name": "roas",
                "summary": 3.7234901344143316,
                "dataSet": [
                    2.6211317101158254,
                    2.439998177967067,
                    3.967616260286211,
                    7.032181732134406,
                    5.664190391459075,
                    4.953029127583047,
                    4.9773943054357215,
                    5.081938918792418,
                    3.9319754334136663,
                    4.261627906976745,
                    3.767561393489435,
                    3.2752457551385157,
                    2.750640141816033,
                    2.247988295537674,
                    2.1614044637996734,
                    2.6767345336249946,
                    6.320446379215037,
                    2.172034184732751,
                    1.6121922373938284,
                    3.4004902253841087,
                    2.7410423452768713,
                    2.7784503631961255,
                    2.3026059898872036,
                    2.411725516322452,
                    3.1117104440275174,
                    2.483168417831275,
                    2.849795266153999,
                    4.685342538502391,
                    2.610839667028592,
                    4.246134020618557,
                    null,
                    null,
                    null,
                    null,
                    1.5463278586922837,
                    1.3740878424893295,
                    2.9392072844134978,
                    9.184100418410042,
                    0.0
                ]
            },
            {
                "name": "ntbSales",
                "summary": 0.0,
                "dataSet": [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            },
            {
                "name": "ctr",
                "summary": 0.4304874545015193,
                "dataSet": [
                    0.5080816940233054,
                    0.5148539679803148,
                    0.3905562027060848,
                    0.42808576909197954,
                    0.4592672556650853,
                    0.6178043958599837,
                    0.6286243360460017,
                    0.6293005416032226,
                    0.5213039549593383,
                    0.5504648742993321,
                    0.5861126298523783,
                    0.32573289902280134,
                    0.5249151590615005,
                    0.2903272970319778,
                    0.35045622454456477,
                    0.3655785597683965,
                    0.3222101912917938,
                    0.3057103115864952,
                    0.32495948838378147,
                    0.34142640364188165,
                    0.34729486589397046,
                    0.25002155358220535,
                    0.35022972056940577,
                    0.34943303454276753,
                    0.47110155558366007,
                    0.5381687262090045,
                    0.41625555007400106,
                    0.3702690378476972,
                    0.35378525482434436,
                    0.3656003323639385,
                    null,
                    null,
                    null,
                    0.0,
                    0.22150624244865083,
                    0.31870091436809955,
                    0.24503414837599707,
                    0.4964147821290678,
                    0.3527336860670194
                ]
            },
            {
                "name": "ntbPercentOfOrders",
                "summary": 0.0,
                "dataSet": [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    null,
                    null,
                    null,
                    null,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    null
                ]
            },
            {
                "name": "appDownloads",
                "summary": 1072.0,
                "dataSet": [
                    53.0,
                    76.0,
                    76.0,
                    94.0,
                    89.0,
                    102.0,
                    92.0,
                    78.0,
                    22.0,
                    17.0,
                    21.0,
                    14.0,
                    14.0,
                    7.0,
                    18.0,
                    30.0,
                    60.0,
                    15.0,
                    10.0,
                    23.0,
                    10.0,
                    2.0,
                    8.0,
                    23.0,
                    37.0,
                    22.0,
                    9.0,
                    23.0,
                    9.0,
                    2.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    4.0,
                    4.0,
                    5.0,
                    3.0,
                    0.0
                ]
            },
            {
                "name": "purchases",
                "summary": 1072.0,
                "dataSet": [
                    53.0,
                    76.0,
                    76.0,
                    94.0,
                    89.0,
                    102.0,
                    92.0,
                    78.0,
                    22.0,
                    17.0,
                    21.0,
                    14.0,
                    14.0,
                    7.0,
                    18.0,
                    30.0,
                    60.0,
                    15.0,
                    10.0,
                    23.0,
                    10.0,
                    2.0,
                    8.0,
                    23.0,
                    37.0,
                    22.0,
                    9.0,
                    23.0,
                    9.0,
                    2.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    4.0,
                    4.0,
                    5.0,
                    3.0,
                    0.0
                ]
            },
            {
                "name": "totalDpvCount",
                "summary": 0.0,
                "dataSet": [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            },
            {
                "name": "impressions",
                "summary": 3153402.0,
                "dataSet": [
                    159620.0,
                    255218.0,
                    323641.0,
                    263265.0,
                    211206.0,
                    137584.0,
                    123126.0,
                    103766.0,
                    43161.0,
                    29793.0,
                    45725.0,
                    62935.0,
                    40959.0,
                    47877.0,
                    126692.0,
                    189289.0,
                    152385.0,
                    134441.0,
                    115399.0,
                    94896.0,
                    47798.0,
                    11599.0,
                    26554.0,
                    72403.0,
                    63256.0,
                    53329.0,
                    32432.0,
                    54825.0,
                    40420.0,
                    12035.0,
                    0.0,
                    0.0,
                    0.0,
                    59.0,
                    29796.0,
                    26357.0,
                    19181.0,
                    1813.0,
                    567.0
                ]
            },
            {
                "name": "acos",
                "summary": 26.856523420258508,
                "dataSet": [
                    38.15145939216503,
                    40.9836371612855,
                    25.2040503515797,
                    14.220337842385087,
                    17.65477377857709,
                    20.18966523800708,
                    20.090833448897513,
                    19.6775289113003,
                    25.432508847895296,
                    23.465211459754432,
                    26.54236774291344,
                    30.53206002728514,
                    36.35517364840673,
                    44.484217377155865,
                    46.26621332325903,
                    37.35895313629551,
                    15.821667331733524,
                    46.03979104145826,
                    62.0273424474825,
                    29.40752461322082,
                    36.48247177658944,
                    35.991285403050114,
                    43.42905405405405,
                    41.46408839779005,
                    32.1366662479588,
                    40.271130738420474,
                    35.0902400560715,
                    21.343156701615186,
                    38.301854098076596,
                    23.55083459787557,
                    null,
                    null,
                    null,
                    null,
                    64.66933867735472,
                    72.7755511022044,
                    34.022779043280174,
                    10.888382687927107,
                    null
                ]
            },
            {
                "name": "ntbOrders",
                "summary": 0.0,
                "dataSet": [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            },
            {
                "name": "clicks",
                "summary": 13575.0,
                "dataSet": [
                    811.0,
                    1314.0,
                    1264.0,
                    1127.0,
                    970.0,
                    850.0,
                    774.0,
                    653.0,
                    225.0,
                    164.0,
                    268.0,
                    205.0,
                    215.0,
                    139.0,
                    444.0,
                    692.0,
                    491.0,
                    411.0,
                    375.0,
                    324.0,
                    166.0,
                    29.0,
                    93.0,
                    253.0,
                    298.0,
                    287.0,
                    135.0,
                    203.0,
                    143.0,
                    44.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    66.0,
                    84.0,
                    47.0,
                    9.0,
                    2.0
                ]
            },
            {
                "name": "orders",
                "summary": 1072.0,
                "dataSet": [
                    53.0,
                    76.0,
                    76.0,
                    94.0,
                    89.0,
                    102.0,
                    92.0,
                    78.0,
                    22.0,
                    17.0,
                    21.0,
                    14.0,
                    14.0,
                    7.0,
                    18.0,
                    30.0,
                    60.0,
                    15.0,
                    10.0,
                    23.0,
                    10.0,
                    2.0,
                    8.0,
                    23.0,
                    37.0,
                    22.0,
                    9.0,
                    23.0,
                    9.0,
                    2.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    4.0,
                    4.0,
                    5.0,
                    3.0,
                    0.0
                ]
            },
            {
                "name": "videoFirstQuartile",
                "summary": 0.0,
                "dataSet": [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            },
            {
                "name": "viewableImpression",
                "summary": 0.0,
                "dataSet": [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0
                ]
            }
        ]
    },
    "summary": {
        "numberOfRecords": 1,
        "pageSize": 300,
        "pageOffset": 0,
        "maxPageNumber": 1,
        "impressions": 3153402,
        "clicks": 13575,
        "spend": {
            "millicents": 745977000,
            "currencyCode": null
        },
        "sales": {
            "millicents": 2777638000,
            "currencyCode": null
        },
        "cpc": {
            "millicents": 54952,
            "currencyCode": null
        },
        "ctr": 0.4304874545015193,
        "acos": 26.856523420258508,
        "roas": 3.7234901344143316,
        "orders": 1072,
        "ntbOrders": 0,
        "ntbSales": {
            "millicents": 0,
            "currencyCode": null
        },
        "ntbPercentOfOrders": 0.0,
        "ntbPercentOfSales": 0.0,
        "kindleUnlimitedPagesRead": 0,
        "kindleUnlimitedRoyalties": {
            "millicents": 0,
            "currencyCode": null
        },
        "vcpm": {
            "millicents": 0,
            "currencyCode": null
        },
        "viewableImpression": 0,
        "unitsSold": 0,
        "detailPageViews": 0,
        "conversionRate": null,
        "subscriptionSignUps": 0,
        "subscriptionSignUpRate": 0.0,
        "costPerSubscriptionSignUp": {
            "millicents": 0,
            "currencyCode": null
        },
        "videoStreams": 0,
        "videoStreamRate": 0.0,
        "costPerVideoStream": {
            "millicents": 0,
            "currencyCode": null
        },
        "appDownloads": 1072,
        "appDownloadRate": 0.03399503139783637,
        "costPerAppDownload": {
            "millicents": 695874,
            "currencyCode": null
        },
        "rentals": 0,
        "purchases": 1072,
        "purchasesClicks": null,
        "purchasesViews": null,
        "detailPageViewsRate": 0.0,
        "cpm": {
            "millicents": 236562,
            "currencyCode": null
        },
        "effectiveDailyCoverage": 0.0,
        "estimatedMissedClicksRangeMin": null,
        "estimatedMissedClicksRangeMax": null,
        "estimatedMissedSalesRangeMin": null,
        "estimatedMissedSalesRangeMax": null,
        "estimatedMissedImpressionsRangeMin": null,
        "estimatedMissedImpressionsRangeMax": null,
        "cumulativeReach": 0,
        "brandedSearches": 0,
        "addToCart": 0,
        "videoStart": 0,
        "videoFirstQuartile": 0,
        "videoMidpoint": 0,
        "videoThirdQuartile": 0,
        "videoComplete": 0,
        "videoCompletionRate": null,
        "totalDpvCount": 0,
        "videoUnmute": 0,
        "viewThroughRate": null,
        "clickThroughRateForViews": null,
        "appstoreFirstOpens": 0,
        "appstoreFirstOpenRate": 0.0,
        "costPerAppstoreFirstOpen": {
            "millicents": 0,
            "currencyCode": null
        },
        "appstoreFirstSessionHours": 0.0,
        "appstoreOpens": 0,
        "appstoreOpenRate": 0.0,
        "costPerAppstoreOpens": {
            "millicents": 0,
            "currencyCode": null
        },
        "appstoreUsageHours": 0.0,
        "totalSkillInvocations": 0,
        "ecpvc": {
            "millicents": 0,
            "currencyCode": null
        },
        "salesViews": null,
        "salesClicks": null,
        "orderViews": null,
        "orderClicks": null,
        "unitsSoldViews": null,
        "unitsSoldClicks": null,
        "brandedSearchesViews": null,
        "brandedSearchesClicks": null,
        "dpvViews": null,
        "dpvClicks": null,
        "ntbOrdersViews": null,
        "ntbOrdersClicks": null,
        "ntbSalesViews": null,
        "ntbSalesClicks": null
    }
}`