import React from 'react';
import { Auth } from 'aws-amplify';
import btn from './web/vector/btn_google_light_normal_ios.svg';
import { Image } from '@themesberg/react-bootstrap';
import { styled } from 'styled-components';


const Div = styled.div`
     /* height: 45px;
     width: 190px;
     z-index: 100; */
     &:hover{
          cursor: pointer;
     }
`;


const AWSGoogleSignIn = () => {
return (<Div onClick={() => Auth.federatedSignIn({ provider:"Google" })}>
     <Image  src={btn} alt="Google Sign In button"
     className="googleSignIn"
     style={{height:"45px", width:"45px"}}/>
{/* <button 
onClick={() => Auth.federatedSignIn({ provider:"Google" })}>
<img src={btn} alt="Google Sign In button"
     className="googleSignIn"
     style={{height:"45px", width:"190px"}}/>
</button> */}</Div>
);
}
export default AWSGoogleSignIn;