// https://ui.docs.amplify.aws/react/guides/auth-protected
// RequireAuth.js
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Routes as routes } from '../routes';
import { deleteEmailCache, getEmail, _getEmail, isAllowed, getS3Json, putS3Json } from './util';
import { useEffect, useState } from 'react';
import Preloader from 'src/components/Preloader';
import { timeout } from 'src/components/utilities';
import { Auth } from 'aws-amplify';
import { GoogleSignIn } from 'src/components/Custom/GoogleSignIn';
import { delay, tryStringifyJSON } from 'src/utils';

export function RequireAuth({ children }) {
  const location = useLocation();
  const rest = useAuthenticator((context) => [
    context.route,
    context.signOut
  ]);
  const  { route, signOut } = rest;

  const [allowed, setAllowed] = useState(null);
  const [email, setEmail] = useState({});

  const navigate = useNavigate();

  function logOut() {
    deleteEmailCache();
    signOut();
    Auth.forgetDevice();
    // window.location = "https://mail.google.com/mail/u/0/?logout&hl=en";//document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=http://localhost:3000";//http://www.junglr.app";
    // window.open('https://accounts.google.com/o/oauth2/revoke?token='+email.data.refreshToken.token);//"https://mail.google.com/mail/u/0/?logout&hl=en")
    window.open("https://mail.google.com/mail/u/0/?logout&hl=en");
    navigate(routes.Signin.path);
  }

  const Nav = props => {
    const [hidden, hide] = useState(true);
    const waitThenShow = async() => {
      await delay(1500);
      hide(false);
      console.log('context', rest);
      let em = null;
      try{em = await getEmail()}catch(e){}
      getS3Json('attemps/login_fails.json').then(r=>{
        let data = Array.isArray(r) ? r : [];
        data.push(tryStringifyJSON({email: em, context: rest}));
        putS3Json('attemps/login_fails.json', data);
      }).catch(e=>console.log('re', e))
    }
    useEffect(()=>{
      // Auth.federatedSignIn({ provider:"Google" });
      waitThenShow();
    }, [])

    return <>{hidden ? <></> : <div className='fade-in' style={{transition: 'all 1s ease-in-out'}}>
      <div style={{ position: 'relative', float: 'right', textAlign: 'center', padding: '.5%', margin: '1%', borderRadius: '15px', width: '10%', height: '7%', zIndex: 999, display: 'table', cursor: 'pointer', backgroundColor: '#ff3857'}}
      onClick={logOut}>
        <p style={{fontWeight: 800, fontFamily: 'Poppins', color: 'white', textAlign: 'center', verticalAlign: 'middle', display: 'table-cell', cursor: 'pointer'}}>Sign Out</p>
      </div>
      <div style={{zIndex: -5, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
       <div style={{zIndex: -5, backgroundColor: '#ccf656', width: '30%', height: '90%', borderRadius: '15px'}}>
       
        </div>
        </div>
      <div style={{zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}> 
        <div style={{zIndex: 10, backgroundColor: 'white', borderRadius: '15px', padding: '10%'}}>
          {hidden ? <></> : route === 'authenticated' ?  <pre style={{fontSize: '125%', cursor: 'pointer', color: '#000000', fontFamily: 'Roboto'}}> {!email.email ? 'You seem to be sign in but we cannot determine your email.' : <><>{`   It appears your signed in with `}</><b>{`"${email.email}"`}</b><>{`\n which is not authenticated with Junglr.\n Please contact Junglr to resolve this if you believe this is an error.\n\n   On "Sign Out" a window will be opened to loggout of Google. \nThe opened window can be closed. \nThe sign in process of the opened window will only sign into Gmail.\n\n`}</></>} 
            <p style={{fontSize: '125%', fontWeight: 800, cursor: 'pointer', color: '#ff3857', fontFamily: 'Poppins', zIndex: 99}} onClick={logOut}> Sign Out</p> </pre> : <>
            <p style={{margin: 10, padding: 10}}> please, Sign In. </p>
            <div style={{zIndex: 99}}><GoogleSignIn opt={4}/></div>
          </>} 
          </div>
      
       
      </div>
      {/* <div>
        {hidden ? <></> : <pre>{JSON.stringify(email.data, undefined, 3)}</pre>}
      </div> */}
      {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: "50%", left: 0, width: '100%', height: '50%'}}>
        {hidden ? <pre> {!email ? '' : `   It appears your signed in with "${email}"\n which is no auhenticated with Junglr.\n Please contact Junglr to resolve this if you believe this is an error.\n`} 
          <p style={{fontWeight: 800, cursor: 'pointer', color: '#ff3857', fontFamily: 'Poppins'}} onClick={logOut}> Sign Out</p> </pre> : <></>}
      </div> */}
       {/* <pre style={{position: 'absolute', top: '100%', left: 0}}> config: {JSON.stringify(rest, undefined, 4)}</pre> */}
    </div>}</>
  //<Navigate to={props.to} state={{ from: location }} replace />
};

  const figureIfAllowed = async() => {
    setEmail({
      email: await getEmail(),
      data: await Auth.currentSession()
    });
    let tries = 5;
    for (let i = 0; i < tries; i++) {
      if(allowed !== null) return;
      try{
        let a = await isAllowed();
        setAllowed({is: a});
        return;
      }
      catch(e){console.log('auth error', e); await timeout(1000);}
    }
  }

  useState(()=>{
    // if(route === 'idle' || route === 'setup') window.location.reload(true);
    if (route === 'authenticated' || true) (async ()=> await figureIfAllowed())()//setAllowed({is: await isAllowed()}))()
  }, [])

  // if (route !== 'authenticated') {
  //   return <Nav to={routes.Signin.path} />;
  // }

  return <>
    <Preloader show={allowed === null} /> 
    {allowed === null ? '' : (allowed.is ? children : <Nav to={'/'} />
    )}
  </>  
  ;
}