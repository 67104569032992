import { Form } from "@themesberg/react-bootstrap";
import React, {useEffect, useState} from "react";

function SavedCheck(props){
    //const [name, setName] = useState('streport');
    const [checked, setThisChecked] = useState(true);
    const updateTable = async() => {
      console.log(props.name)
      await fetch('/api/saved-check/'+props.name)
      .then((response) => 
      response.text())
      .then((data) => {
        console.log('checks boxes')
        try{
        if(typeof(data) == 'string'){
          var _data = JSON.parse(data);
        }else{var _data = data;}
        console.log('did check')
        
        if(_data.checked != checked){
          props.setChecked(_data.checked);
          setThisChecked(_data.checked);
          console.log(_data.checked);
        console.log(_data);
        }
        }catch(err){}
      })
    };
    useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
      //setName(props.name);
      updateTable();
    }, []);
    return (<>
              <Form>
                <Form.Check label={props.title} id="checkbox1" htmlFor="checkbox1" 
                defaultChecked={checked}
                checked={checked}
                onChange={(e) => {
                  setThisChecked(e.target.checked);
                  props.setChecked(e.target.checked);
                  fetch('/api/set-check/'+props.name, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify({ checked: e.target.checked })
                  });
                  }}/>
              </Form>
            </>);
  }

  export default SavedCheck;