import { Api } from "src/auth/APIHandler";
import { isObject } from ".";

function tryJSON(json){
    try{
        return JSON.stringify(json);
    }catch(e){}
    return String(json);
}

export function checkRequest(request){
    if(isObject(request)){
        if('func' in request && 'args' in request && 'kwargs' in request){
            return typeof request.func === 'string' && Array.isArray(request.args) && isObject(request.kwargs);
        }
    }
    return false;
}

export function createJobRequestObject(func, args, kwargs){
    const request = {
        func: func,  // 'job_status',
        args: args,  // ['865606ee-afe3-44d0-85c2-91dd7c11bb33'],
        kwargs: kwargs,  // {},
    }
    if(!checkRequest(request)) throw new Error('error: func must be string, args an array and kwargs an object(dict) a.k.a. "{key: \'value\'}".' + ` You provided "${tryJSON(request)}"`)
    return request;
}

export async function jobRequest(request){
    if(!checkRequest(request)) throw new Error('error: request object must contain {func: val, args: val, kwargs: val} func must be string, args an array and kwargs an object(dict) a.k.a. "{key: \'value\'}"' + ` You provided "${tryJSON(request)}"`)
    return (await Api('job', '/job', undefined, request)).result;
}

export async function jobStatus(jobID){
    return await jobRequest(createJobRequestObject('job_status', [jobID], {}))
}


