import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { Routes as Routing } from "../routes";

// pages
import StarterPage from './Custom'
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import Reports from './Reports';
import STR from './STR'
import AdminUserAcces from './Admin/AdminPermissions'
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/NotAllowed";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

import SPIAPIAuth from './SPAPIAAuth';

import DownloadPage from './Tools/Downloads';
import Yoy from './Tools/yoy_run';

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";



import ProfilePic from '../assets/img/undraw_female_avatar_efig.svg'
import AmazonAPIPrivacy from './AmazonAPIPrivacy';
// Autho
import { RequireAuth } from '../auth/RequireAuth';
import { Login } from './AWS/Login';

import { Test } from './TestPage';
import { getEmail, getS3Json, isAdmin } from 'src/auth/util';
import { Authenticator } from '@aws-amplify/ui-react';
import { styled } from 'styled-components';
import { timeout } from 'src/components/utilities';
import TestReport from './Report/Test';
import QueryReport from './Report/Report';
import { delay, isObject } from 'src/utils';

const config = [
  {
      "source": "https://junglr.app",
      "target": "https://www.junglr.app",
      "status": "302",
      "condition": null
  },
  {
      "source": "</^[^.]+$|\\.(?!(css|gif|ico|jpg|js|png|txt|svg|woff|woff2|ttf|map|json|webp|html|xml|webmanifest|mp4)$)([^.]+$)/>",
      "target": "/index.html",
      "status": "200",
      "condition": null
  },
  {
      "source": "/<*>",
      "target": "/index.html",
      "status": "404-200",
      "condition": null
  }
];

let pathToElement = {};
pathToElement[`${Routing.DashboardOverview.path}`] = DashboardOverview;
pathToElement[`${Routing.Reports.path}`] = Reports;
pathToElement[`${Routing.STR.path}`] = STR;  //TestReport;//STR;
pathToElement[`${Routing.AdminUserAccess.path}`] = AdminUserAcces;
pathToElement[Routing.TestReport.path] = TestReport;
pathToElement[Routing.ToolDownlods.path] = DownloadPage;
pathToElement[Routing.Yoy.path] = Yoy;

const Change = (props, ...rest) => {
  const {Component} = props;
 return (<>
  {!!Component ? <Component {...rest}/> : ''}
 </>) 
}



const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
   /*  <Route path={path} 
    element={ */
    <> <Preloader show={loaded ? false : true} /> <Component {...rest} /> </>  
  /* } /> */
  );
};

const RouteWithSidebar = ({ name: name, img: img, items: items, component: Component, components, path, ...rest }) => {
  let val = {...pathToElement};
  if(!pathToElement.hasOwnProperty(path)) {pathToElement[path] = Component;}
  const [data, setData] = useState(val)
  const [thisData, setTHisData] = useState('');
  const [loaded, setLoaded] = useState(false);
  

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    display(path);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  const display = async(v) => {
    setLoaded(false);
    try{
    window.history.replaceState(null, "New Page Title", v)
    await timeout(500);
    setTHisData(v);
    }finally{
      await timeout(200);
      setLoaded(true);
    }
  }

  return (<>
    {/* <Route path={path} element={ */}
      <RequireAuth>
        <Preloader show={loaded ? false : true} />
        <Sidebar  name={items.name} img={items.img} admin={items.admin} components={val} display={display} />

        <main className="content">
          <Navbar name={items.name} img={items.img} admin={items.admin} />
          {(Object.keys(pathToElement).filter(v=>v===thisData)).map(k=>{
            const C = pathToElement[k];
            return <C key={k} {...{...rest, profileName: items.name, profileImg: items.img, admin: items.admin, path: path}}/>;
          })}
          {/* <Component {...{...rest, profileName: items.name, profileImg: items.img, admin: items.admin, path: path}} /> */}
          
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </RequireAuth>
    {/* }
    /> */}</>
  );
};

export default () => {//=> (
  const [myName, setName] = useState('');
  const [userImg, setUserImg] = useState(ProfilePic);
  const [userIsAdmin, setIsAdmin] = useState(false);

  const updateInfo = async() => {
    let email = await getEmail();
    if(!!email) setName(email.split('@')[0]);
    //setUserImg(data.userData.profilePic);
    try{
      setIsAdmin(await isAdmin());
    }catch(err){}
  };
  const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
  }
  const userLoop = async(status) => {
    while(status.stillGoing){
      updateInfo();
      if(userIsAdmin){break;}
      await timeout(15000); //for 10 sec delay
    }
  }

  const [reports, setReports] = useState([]);
  const [reportsAreSet, setReportsAreSet] = useState(false);

  useEffect(() => {
    var status = {stillGoing: true}
    if(!userIsAdmin){ userLoop(status); }

    getS3Json('reports/all.json').then(async _reports=>{
      if(isObject(_reports)){
        setReports(await Promise.all(Object.keys(_reports).map(async k=>{
          console.log('report key', _reports[k].s3)
          const report = await getS3Json(_reports[k].s3);
          console.log('report', report);
          const C = r => <QueryReport s3={_reports[k].s3} config={report}/>;
          pathToElement[k] = C;
          
          return {path: k, component: C}
        })));
        await delay(2000);
        setReportsAreSet(true);
        // console.log('all reports', v);
        
        // setReports(Object.keys(_reports).map(k=>({title: _reports[k].display_name, link: k})))
      }
    }).catch(e=>console.log(  `error fetching reports: ${e}`));

    return () => status.stillGoing = false;
  }, []);

  var items = {img: userImg, name: myName, admin: userIsAdmin}


  return (<>
  <Routes>
    <Route path={'/test'} element={< RouteWithLoader component={Test} /> } />

    <Route path={Routing.Presentation.path} element={< RouteWithLoader component={StarterPage} /> } />

    <Route path={Routing.Signin.path} element={< RouteWithLoader component={Login} /> } />
    <Route path={Routing.Signup.path} element={< RouteWithLoader component={Signup} /> } />
    <Route path={Routing.ForgotPassword.path} element={< RouteWithLoader component={ForgotPassword} /> } />
    <Route path={Routing.ResetPassword.path} element={< RouteWithLoader component={ResetPassword} /> } />
    <Route path={Routing.Lock.path} element={< RouteWithLoader component={Lock} /> } />
    <Route path={Routing.NotFound.path} element={< RouteWithLoader component={NotFoundPage} /> } />
    <Route path={Routing.ServerError.path} element={< RouteWithLoader component={ServerError} /> } />
    <Route path={Routing.AmazonAPIPrivacy.path} element={< RouteWithLoader component={AmazonAPIPrivacy} /> } />
    <Route path={Routing.SPAPI.path} element={< RouteWithLoader component={SPIAPIAuth} /> } />

    <Route path={Routing.DashboardOverview.path} element={< RouteWithSidebar path={Routing.DashboardOverview.path} component={DashboardOverview} name={myName} img={userImg} items={items} /> } />
    <Route path={Routing.Upgrade.path} element={< RouteWithSidebar component={Upgrade} items={items} /> } />
    <Route path={Routing.Transactions.path} element={< RouteWithSidebar component={Transactions} items={items} /> } />
    <Route path={Routing.Settings.path} element={< RouteWithSidebar component={Settings} items={items} /> } />
    <Route path={Routing.Reports.path} element={< RouteWithSidebar path={Routing.Reports.path} component={Reports} name={myName} img={userImg} items={items} /> } />
    <Route path={Routing.STR.path} element={< RouteWithSidebar path={Routing.STR.path} component={STR} items={items} /> } />
    <Route path={Routing.TestReport.path} element={< RouteWithSidebar path={Routing.TestReport.path} component={TestReport} items={items} /> } />
    <Route path={Routing.AdminUserAccess.path} element={< RouteWithSidebar path={Routing.AdminUserAccess.path} component={AdminUserAcces} items={items} /> } />
    <Route path={Routing.BootstrapTables.path} element={< RouteWithSidebar component={BootstrapTables} items={items} /> } />
    {reports.map(_props=><Route path={_props.path} element={< RouteWithSidebar {..._props} items={items} /> } />)}
    
    <Route path={Routing.ToolDownlods.path} element={< RouteWithSidebar path={Routing.ToolDownlods.path} component={DownloadPage} items={items} /> } />
    <Route path={Routing.Yoy.path} element={< RouteWithSidebar path={Routing.Yoy.path} component={Yoy} items={items} /> } />
    
    <Route path={Routing.Accordions.path} element={< RouteWithSidebar component={Accordion} /> } />
    <Route path={Routing.Alerts.path} element={< RouteWithSidebar component={Alerts} /> } />
    <Route path={Routing.Badges.path} element={< RouteWithSidebar component={Badges} /> } />
    <Route path={Routing.Breadcrumbs.path} element={< RouteWithSidebar component={Breadcrumbs} /> } />
    <Route path={Routing.Buttons.path} element={< RouteWithSidebar component={Buttons} /> } />
    <Route path={Routing.Forms.path} element={< RouteWithSidebar component={Forms} /> } />
    <Route path={Routing.Modals.path} element={< RouteWithSidebar component={Modals} /> } />
    <Route path={Routing.Navs.path} element={< RouteWithSidebar component={Navs} /> } />
    <Route path={Routing.Navbars.path} element={< RouteWithSidebar component={Navbars} /> } />
    <Route path={Routing.Pagination.path} element={< RouteWithSidebar component={Pagination} /> } />
    <Route path={Routing.Popovers.path} element={< RouteWithSidebar component={Popovers} /> } />
    <Route path={Routing.Progress.path} element={< RouteWithSidebar component={Progress} /> } />
    <Route path={Routing.Tables.path} element={< RouteWithSidebar component={Tables} /> } />
    <Route path={Routing.Tabs.path} element={< RouteWithSidebar component={Tabs} /> } />
    <Route path={Routing.Tooltips.path} element={< RouteWithSidebar component={Tooltips} /> } />
    <Route path={Routing.Toasts.path} element={< RouteWithSidebar component={Toasts} /> } />

    <Route path={Routing.DocsOverview.path} element={< RouteWithSidebar component={DocsOverview} /> } />
    <Route path={Routing.DocsDownload.path} element={< RouteWithSidebar component={DocsDownload} /> } />
    <Route path={Routing.DocsQuickStart.path} element={< RouteWithSidebar component={DocsQuickStart} /> } />
    <Route path={Routing.DocsLicense.path} element={< RouteWithSidebar component={DocsLicense} /> } />
    <Route path={Routing.DocsFolderStructure.path} element={< RouteWithSidebar component={DocsFolderStructure} /> } />
    <Route path={Routing.DocsBuild.path} element={< RouteWithSidebar component={DocsBuild} /> } />
    <Route path={Routing.DocsChangelog.path} element={< RouteWithSidebar component={DocsChangelog} /> } />
    {/* <RouteWithLoader exact path={Routing.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routing.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routing.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routing.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routing.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routing.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routing.ServerError.path} component={ServerError} />
    <RouteWithLoader exact path={Routing.AmazonAPIPrivacy.path} component={AmazonAPIPrivacy} />

    <RouteWithSidebar exact path={Routing.DashboardOverview.path} component={DashboardOverview}  name={myName} img={userImg} items={items} />
    <RouteWithSidebar exact path={Routing.Upgrade.path} component={Upgrade} items={items} />
    <RouteWithSidebar exact path={Routing.Transactions.path} component={Transactions} items={items} />
    <RouteWithSidebar exact path={Routing.Settings.path} component={Settings} items={items} />
    <RouteWithSidebar exact path={Routing.Reports.path} component={Reports}  name={myName} img={userImg} items={items} />
    <RouteWithSidebar exact path={Routing.STR.path} component={STR}  items={items} />
    <RouteWithSidebar exact path={Routing.AdminUserAccess.path} component={AdminUserAcces} items={items} />
    <RouteWithSidebar exact path={Routing.BootstrapTables.path} component={BootstrapTables} items={items} />

    <RouteWithSidebar exact path={Routing.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routing.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routing.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routing.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routing.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routing.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routing.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routing.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routing.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routing.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routing.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routing.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routing.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routing.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routing.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routing.Toasts.path} component={Toasts} />

    <RouteWithSidebar exact path={Routing.DocsOverview.path} component={DocsOverview} />
    <RouteWithSidebar exact path={Routing.DocsDownload.path} component={DocsDownload} />
    <RouteWithSidebar exact path={Routing.DocsQuickStart.path} component={DocsQuickStart} />
    <RouteWithSidebar exact path={Routing.DocsLicense.path} component={DocsLicense} />
    <RouteWithSidebar exact path={Routing.DocsFolderStructure.path} component={DocsFolderStructure} />
    <RouteWithSidebar exact path={Routing.DocsBuild.path} component={DocsBuild} />
    <RouteWithSidebar exact path={Routing.DocsChangelog.path} component={DocsChangelog} /> */}

    {/* <Navigate to={Routing.NotFound.path} /> */}
    <Route path='*' element={ <>{reportsAreSet ? <Navigate to={Routing.NotFound.path} /> : 
    <>
      
    </>}</>} />
  </Routes>
  </>);
}//);
