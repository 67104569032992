import styled from "styled-components";
import {MdArrowForward, MdKeyboardArrowRight} from 'react-icons/md';
import { theme } from "../Theme";

export const HeroContainer = styled.div`
    background: ${theme.darkColor2};//#0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 900px;
    position: relative;
    z-index: 1;

    :before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), 
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const BackDrop = styled.div`
 position: absolute;
    /* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
    width: 50%;
    height: 80%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0.0,0.0,0.0,0.15);
    @media screen and (max-width: 768px) {
        width: 75%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: ${theme.darkColor3};//#232a34;
`;

export const ImgBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: ${theme.darkColor3};//#232a34;
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    padding-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

/*

I have to answer a question in less than 450 characters. The Question asks about 3 roles that I will be using. My application uses Amazon's to show account health, increase sales and give next steps.
Quuestion: "Describe the application or feature(s) you intend to build using the functionality in the requested roles."

Role: "Brand Analytics"
Description: "Access reports that contain information about seller and vendor performance on Amazon and consumer behavior reports that give insight into how customers find and purchase a selling partner's products on Amazon. Example operations that require this role assignment: The createReport operation of the Selling Partner API for Reports, when used to request the GET_BRAND_ANALYTICS_SEARCH_TERMS_REPORT report. This report returns data on the top clicked ASINs by search keyword and department for a marketplace. The createReport operation of the Selling Partner API for Reports, when used to request the GET_PROMOTION_PERFORMANCE_REPORT report. This report returns data from promotion campaigns to help vendors and sellers optimize their promotions and adjust their advertising strategies."

Role: "Selling Partner Insights"
Description: "View information about the Amazon Selling Partner account and performance. Generally used for reports and operations used to return seller insights. Examples of operations that require this role assignment: The createReport operation of the Selling Partner API for Reports, when used to request the GET_V1_SELLER_PERFORMANCE_REPORT report. This report contains individual performance metrics from the Seller Central dashboard. The getMarketplaceParticipations operation of the Selling Partner API for Sellers, which returns a list of marketplaces that a seller can sell in, and information about the seller’s participation in those marketplaces."

Role: "Amazon Business Analytics"
Description: "Access Amazon Business Analytics Reports"

Answer: 

*/


export const HeroH1 = styled.h1`
    color: ${theme.lightColor3};//#fff;
    font-size: 48px;
    text-align: center;
    font-weight: 800;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: ${theme.lightColor3};//#fff;
    font-size: 24px;
    text-align: top;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;