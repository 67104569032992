import { faAddressBook, faAdjust, faArrowCircleDown, faAt, faCalculator, faFileAlt, faInfo, faPhoneVolume, faSortNumericDownAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button, Col, Form, InputGroup, Modal, Row } from '@themesberg/react-bootstrap';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { getEmail, getS3Json, putS3Json } from 'src/auth/util';
import styled from 'styled-components';

const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
}

export function Space(props){
return (<>{props.size == 'sm' ? <p>&nbsp;</p> : <br></br>}</>);
}
export  function CSpace(props){
return (<Col  {...props}><Space size={props.size} /></Col>);
}
export  function RCSpace(props){
return (<Row><CSpace size={props.size} {...props}/></Row>);
}

function isNumber(value) 
{
   return typeof value === 'number' && isFinite(value);
}

export const MyInputText = forwardRef((props, ref) => {
    const {defaultValue, label, onChange, onEnter, ...rest} = props;
    const [textInput, setTextInput] = useState(defaultValue || '');
    const onChangeInput = useCallback(
      (e) => {
        setTextInput(e.target.value);
        if(typeof onChange === 'function') onChange(e.target.value);
      },
      [textInput]
    );
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();  // Form.Control refreshes the page on enter. this disables that feature
        console.log('enter ', typeof onEnter)
        if(typeof onEnter === 'function') onEnter();
    }
    }
    useImperativeHandle(ref, () => ({
        getText(){return textInput;},
        setText(txt){setTextInput(txt);},
    }));
    return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label> {label || 'Report Name'} </Form.Label>
        <InputGroup onChange={onChangeInput}>
          <Form.Control type="text" placeholder="Enter a Report Name..." 
          maxlength="100"
          value={textInput}
          onKeyPress={handleKeyDown}
          />
          <InputGroup.Text><FontAwesomeIcon icon={faFileAlt} /></InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </Form>
    );
  });

function HelpInfo(props){
    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);
    const handleOpen = () => setShowDefault(true);
    
    const Div = styled.div`
       /*  background-color: #fff;
        display: flex;
        align-items: center;
        height: 50px;
        border-radius: 25px; */
    `;
    const P = styled.p`
    /*  background-color: #fff;
     display: flex;
     align-items: center;
     height: 50px;
     border-radius: 25px; */
     &:hover{
        cursor: pointer;
     }
 `;
    return (<Div>
        <CSpace xs={1}/>
        <Col>
      <React.Fragment>
        <P onClick={!showDefault ? handleOpen : ()=>{}}> {props.desc} ( <FontAwesomeIcon style={{scale: "75%"}} icon={faInfo} /> ) </P>
        <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className="h6"> {props.title} </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
          <>
            {props.body()}
          </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="gray" onClick={handleClose}>
              Ok
          </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
              Close
          </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
      </Col>
      <CSpace xs={1}/>
    </Div>);
  }

export const Style = styled.div`
  .container_row{
    display: flex;
   //  display: grid;
    //align-items: center; 
  }

  .layer1{
    position: absolute;
    margin-left: -15%;
    margin-top: 5%;
    width: 100%;
  } 
  //   .layer2{
  //  grid-column: 1;
  //  grid-row: 1;
  //} 
  .layer2 {
    width: 100%;
    margin-left: -100%;
    //position: absolute;
    display: flex;
    z-index: 2;
    pointer-events: none;
  }

  .child3{
      //position: absolute;
      margin-left: 0;//-100%;
      margin-top: 50px;
      
      //width: 100%;
      &:hover {
      //transform: scale(1.02) !important;
      //transition: all .2s ease-in-out;
    }

    .child2 {
      //width: 100%;
      margin-left: -100%;
      position: absolute;
      display: flex;
      z-index: -12 !important;
      pointer-events: none;
    }
      input{
        border: 1px solid #ffffff00; // Red border only if the input is empty 
      }
    }

    .child4 {
      position: absolute;
      left:35%;
      margin-left:-7%;
      width:20%;
      font-weight: 900;
      letter-spacing: 2px;
      z-index: 35;
      // width: 10% !important;
    }
`;

const click = () => {}
function roundTo(num, decimalPlaces){
    return (Math.round(num* (10 * decimalPlaces)) / (10 * decimalPlaces)).toFixed(decimalPlaces)
}
function calcBid(_totalOrders14Day, _totalAdvertisingCostOfSalesACoS, _costPerClickCPC, _targetACoS, v, fp, sp, tp, fop, fip) {
    if(_totalOrders14Day >= v && _totalAdvertisingCostOfSalesACoS <= _targetACoS) {
      return _costPerClickCPC*fp;
    } else if(_totalOrders14Day < v && _totalAdvertisingCostOfSalesACoS <= _targetACoS) {
      return _costPerClickCPC*sp;
    } else if(_totalOrders14Day < v && _totalAdvertisingCostOfSalesACoS >= 1) {
      return _costPerClickCPC*tp;
    } else if(_totalOrders14Day >= v && _totalAdvertisingCostOfSalesACoS > _targetACoS) {
      return _costPerClickCPC*fop;
    } else if(_totalOrders14Day < v && _totalAdvertisingCostOfSalesACoS > _targetACoS) {
      return _costPerClickCPC*fip;
    }
    return '';
  }
function isInDesiredForm(str) {
    str = str.trim();
    if (!str) {
        return true;
    }
    str = str.replace(/^0+/, "") || "0";
    var n = Math.floor(Number(str));
    return String(n) === str && n >= 0;
}


export const CollapsableAdvancedOptions = forwardRef((props, ref) => {
    const [name, _setName] = useState(props.name);
    const setName = useCallback((e) => { _setName(e); }, [name]);
    // const url = '/api/set-df-key-value/formula-'+String(props.name);
    let baseValues = {
        order: '3',
        first_percentage: '150',
        second_percentage: '120',
        third_percentage: '50',
        fourth_percentage: '90',
        fifth_percentage: '75',
    }
    const [textInput, setTextInput] = useState("Hallo");
    const [orders, setOrders] = useState(baseValues.order);
    const numberFilter = (str) => {return isInDesiredForm(str)};
    const getPath = async _name => `CollapsableAdvancedOptions/${await getEmail()}/${_name}.json`
    const tellServer = async(key, value) => {
        const path = await getPath(name);
        let j = await getS3Json(path);
        
        if(Array.isArray(j)){
            putS3Json(path, j.map(el=>{
                return (el.key === key ? {...el, value: value} : el);
            }));
        }
    }
    const updateValues = async(_name) => {
        let array = [
            {key: 'order', baseValue: baseValues.order, func: setOrders},
            {key: 'first_percentage', baseValue: baseValues.first_percentage, func: setFirstPercentage},
            {key: 'second_percentage', baseValue: baseValues.second_percentage, func: setSecondPercentage},
            {key: 'third_percentage', baseValue: baseValues.third_percentage, func: setThirdPercentage},
            {key: 'fourth_percentage', baseValue: baseValues.fourth_percentage, func: setFourthPercentage},
            {key: 'fifth_percentage', baseValue: baseValues.fifth_percentage, func: setFifthPercentage},
        ];
        const path = await getPath(_name);
        let j = await getS3Json(path);
        console.log('jjjj', path, j)
        if(Array.isArray(j)){
            array.forEach(element=>{
                j.forEach(el=>{
                    if(el.key === element.key){
                        element.func(el.value);
                    }
                })
                // updateValue(name, element.key, element.baseValue, element.func)
            })
        }else {
            array.forEach(element=>{
                element.func(element.baseValue);
            });
            putS3Json(path, array.map(el=>({key: el.key, value: el.baseValue})))
        }
    }
    const updateValue = (name, key, baseValue, func) => {
        const _url = '/api/get-df-key-value/formula-'+String(name);
        fetch(_url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ key: key, base_value: baseValue })
        })
        .then((response) => response.json())
        .then((data) => {
            try{
                if(typeof(data) == 'string'){
                var _data = JSON.parse(data);
                }else{var _data = data;}
                func(_data.value)
            }catch(err){console.log(err)}
          });
    }
    useImperativeHandle(ref, () => ({
        update (name){
            setName(name);
            updateValues(name);
        },
        async getData(){
            return await getS3Json(await getPath(name));
        }
      }));
    const onChangeOrders = useCallback(
        (e) => {
            if(numberFilter(e.target.value)) {
                setOrders(e.target.value);
                tellServer('order', e.target.value);
            }
        },
        [orders]
        );
    const [firstPercentage, setFirstPercentage] = useState(baseValues.first_percentage);
    const onChangeFirstPercentage = useCallback(
        (e) => {
            if(numberFilter(e.target.value)) {
                setFirstPercentage(e.target.value);
                tellServer('first_percentage', e.target.value);
            }
        },
        [firstPercentage]
        );
    const [secondPercentage, setSecondPercentage] = useState(baseValues.second_percentage);
    const onChangeSecondPercentage = useCallback(
        (e) => {
            if(numberFilter(e.target.value)) {
                setSecondPercentage(e.target.value);
                tellServer('second_percentage', e.target.value);
            }
        },
        [secondPercentage]
        );
    const [thirdPercentage, setThirdPercentage] = useState(baseValues.third_percentage);
    const onChangeThirdPercentage = useCallback(
        (e) => {
            if(numberFilter(e.target.value)) {
                setThirdPercentage(e.target.value);
                tellServer('third_percentage', e.target.value);
            }
        },
        [thirdPercentage]
        );
    const [fourthPercentage, setFourthPercentage] = useState(baseValues.fourth_percentage);
    const onChangeFourthPercentage = useCallback(
        (e) => {
            if(numberFilter(e.target.value)) {
                setFourthPercentage(e.target.value);
                tellServer('fourth_percentage', e.target.value);
            }
        },
        [fourthPercentage]
        );
    const [fifthPercentage, setFifthPercentage] = useState(baseValues.fifth_percentage);
    const onChangeFifthPercentage = useCallback(
        (e) => {
            if(numberFilter(e.target.value)){
                setFifthPercentage(e.target.value);
                tellServer('fifth_percentage', e.target.value);
            }
        },
        [fifthPercentage]
        );
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();  // Form.Control refreshes the page on enter. this disables that feature
        //doClick();
      }
    }
    const doClick = () => {
      //click(textInput);
    }
    const CInput = styled.input`
        border-radius: 25px;
        outline-style: none;
        outline: none;
        width: 100px;
    `;
    const colSize = 4;
    const Background = styled.div`
        border-radius: 25px;
        background-color: #fff;
    `;
    const icon = faCalculator;
    const [ref1, setRef1] = useState(25);
    const [ref2, setRef2] = useState(40);
    const [ref3, setRef3] = useState(0.49);
    const [ref4, setRef4] = useState(5);

    const Val = () => {
        return (<>
            <p> something test</p>
        </>);
    }
    return (
    <>
    <Accordion defaultActiveKey={""}>
        <Accordion.Item /* eventKey={eventKey} */>
        <Accordion.Button className="d-flex justify-content-between align-items-center">
            <span>
            <span className="sidebar-icon"><FontAwesomeIcon icon={faArrowCircleDown} /> </span>
            <span className="sidebar-text">{"advanced options"}</span>
            </span>
        </Accordion.Button>
        <Accordion.Body className="multi-level">

            <HelpInfo 
            title='This is the calculation to determine a new bid. (leaving this blank with use default values)' 
            body={() => {
            return (<>
                <p> This section allows you to change the prefered number of orders and percentages that effect the CPC to create the New Bid </p>
                <p> The values used are: </p>
                <p> - the ACoS from the Target Asociated with the Search Term </p>
                <p> - the Target ACoS provided by you (above, not in this section)</p>
                <p> - the CPC from the Target Asociated with the Search Term </p>
                <p> - the Number of Orders from the Target Asociated with the Search Term  </p>
                <p> - the Prefered number of Orders provided by you that will separate the rate of growth of the new bid</p>
                <p> </p>
            </>); }} 
            desc='New Bid Calculation' />
        <Form.Group className="mb-3">
            <Form.Label> Prefered Number of Orders </Form.Label>
            <Col xs={3}>
                <InputGroup onChange={onChangeOrders} defaultValue='3'>
                <Form.Control type="number" placeholder="3..." 
                maxlength="100"
                value={orders}
                onKeyPress={handleKeyDown}
                />
                <InputGroup.Text><FontAwesomeIcon icon={icon} /></InputGroup.Text>
                </InputGroup>
            </Col>
        </Form.Group>

           
            <Form.Group className="mb-3">
                <Form.Label>  {"if Orders >= "+orders+" and ACoS <= Target ACoS"} </Form.Label>
                <Row>
                <Col xs={colSize}>
                    <InputGroup onChange={onChangeFirstPercentage} defaultValue='150'>
                    <Form.Control type="number" placeholder="150..." 
                    maxlength="100"
                    value={firstPercentage}
                    onKeyPress={handleKeyDown}
                    />
                    <InputGroup.Text><FontAwesomeIcon icon={icon} /></InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                     <Form.Label> {"then New Bid = CPC * "+firstPercentage+"%"} </Form.Label>
                </Col>
                </Row>
            </Form.Group>
       
        <Form.Group className="mb-3">
            <Form.Label> {"if Orders < "+orders+" and ACoS <= Target ACoS"} </Form.Label>
            <Row>
                <Col xs={colSize}>
                    <InputGroup onChange={onChangeSecondPercentage} defaultValue='120'>
                    <Form.Control type="number" placeholder="120..." 
                    maxlength="100"
                    value={secondPercentage}
                    onKeyPress={handleKeyDown}
                    />
                    <InputGroup.Text><FontAwesomeIcon icon={icon} /></InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                     <Form.Label> {"then New Bid = CPC * "+secondPercentage+"%"} </Form.Label>
                </Col>
            </Row>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>  {"if Orders < "+orders+" and ACoS >= 100%"} </Form.Label>
            <Row>
                <Col xs={colSize}>
                    <InputGroup onChange={onChangeThirdPercentage} defaultValue='50'>
                    <Form.Control type="number" placeholder="50..." 
                    maxlength="100"
                    value={thirdPercentage}
                    onKeyPress={handleKeyDown}
                    />
                    <InputGroup.Text><FontAwesomeIcon icon={icon} /></InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                     <Form.Label> {"then New Bid = CPC * "+thirdPercentage+"%"} </Form.Label>
                </Col>
            </Row>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label> {"if Orders >= "+orders+" and ACoS > Target ACoS"} </Form.Label>
            <Row>
                <Col xs={colSize}>
                    <InputGroup onChange={onChangeFourthPercentage} defaultValue='90'>
                    <Form.Control type="number" placeholder="90..." 
                    maxlength="100"
                    value={fourthPercentage}
                    onKeyPress={handleKeyDown}
                    />
                    <InputGroup.Text><FontAwesomeIcon icon={icon} /></InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                     <Form.Label> {"then New Bid = CPC * "+fourthPercentage+"%"} </Form.Label>
                </Col>
            </Row>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label> {"if Orders < "+orders+" and ACoS > Target ACoS "} </Form.Label>
            <Row>
                <Col xs={colSize}>
                    <InputGroup onChange={onChangeFifthPercentage} defaultValue='75'>
                    <Form.Control type="number" placeholder="75..." 
                    maxlength="100"
                    value={fifthPercentage}
                    onKeyPress={handleKeyDown}
                    />
                    <InputGroup.Text><FontAwesomeIcon icon={icon} /></InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                     <Form.Label> {"then New Bid = CPC * "+fifthPercentage+"%"} </Form.Label>
                </Col>
            </Row>
        </Form.Group>

        <Accordion defaultActiveKey={""}>
        <Accordion.Item /* eventKey={eventKey} */>
        <Accordion.Button className="d-flex justify-content-between align-items-center">
            <span>
            <span className="sidebar-icon"><FontAwesomeIcon icon={faArrowCircleDown} /> </span>
            <span className="sidebar-text">{"live example"}</span>
            </span>
        </Accordion.Button>
        <Accordion.Body className="multi-level">
        <Form.Group className="mb-3">
            
            <Row>
                <Col>
                    {/* <Form.Label> {"live example:"} </Form.Label> */}
                    <Row>
                        <Col xs={colSize}>
                            <InputGroup onChange={(e) => {
                                setRef1(e.target.value);}} defaultValue='75'>
                            <Form.Control type="number" placeholder={ref1+"..."} maxlength="100" onKeyPress={handleKeyDown} />
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label> {"ACoS: "+String(ref1)+"%"} </Form.Label>
                        </Col>
                    </Row>
                    <RCSpace/>
                    <Row>
                        <Col xs={colSize}>
                            <InputGroup onChange={(e) => {setRef2(e.target.value);}} defaultValue='75'>
                            <Form.Control type="number" placeholder={ref2+"..."} maxlength="100" onKeyPress={handleKeyDown} />
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label> {"Target ACoS: "+String(ref2)+"%"} </Form.Label>
                        </Col>
                    </Row>
                    <RCSpace/>
                    <Row>
                        <Col xs={colSize}>
                            <InputGroup onChange={(e) => {setRef3(e.target.value);}} defaultValue='75'>
                            <Form.Control type="number" placeholder={ref3+"..."} maxlength="100" onKeyPress={handleKeyDown} />
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label> {"CPC: $"+String(ref3)+""} </Form.Label>
                        </Col>
                    </Row>
                    <RCSpace/>
                    <Row>
                        <Col xs={colSize}>
                            <InputGroup onChange={(e) => {setRef4(e.target.value.toFixed(0));}} defaultValue='75'>
                            <Form.Control type="number" placeholder={ref4+"..."} maxlength="100" onKeyPress={handleKeyDown} />
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label> {"Orders: "+String((ref4).toFixed(0))} </Form.Label>
                        </Col>
                    </Row>
                    <RCSpace/>
                    
                    
                    
                   
                    
                    {/* <p></p>
                    <Form.Label> {"ACoS: "} <CInput autoFocus="autoFocus" value={ref1} type='number' onChange={(e) => {if(numberFilter(e.target.value)){setRef1(e.target.value);}}}/> {"("+roundTo(ref1, 2)+"%)"} </Form.Label>
                    <Form.Label> {"Target ACoS: "} <CInput type='number' autoFocus="autoFocus" value={ref2} onChange={(e) => {if(numberFilter(e.target.value)){setRef2(e.target.value);}}}/> {"("+roundTo(ref2, 2)+"%)"} </Form.Label>
                    <Form.Label> {"CPC: "} <CInput type='number' autoFocus="autoFocus" value={ref3} onChange={(e) => {if(numberFilter(e.target.value)){setRef3(e.target.value);}}}/>  {"($"+roundTo(ref3, 2)+")"} </Form.Label>
                    <Form.Label> {"Orders: "} <CInput type='number' autoFocus="autoFocus" value={ref4} onChange={(e) => {if(numberFilter(e.target.value)){setRef4(e.target.value);}}}/> {"("+(ref4).toFixed(0)+")"} </Form.Label>
                     */}
                    <Form.Label> {"New Bid = $"+String(
                        (Math.round( 
                        calcBid(
                        ref4, ref1 / 100., ref3, ref2 / 100.0, 
                        Number(orders), Number(firstPercentage) / 100., 
                        Number(secondPercentage) / 100., Number(thirdPercentage) / 100.,
                        Number(fourthPercentage) / 100., Number(fifthPercentage) / 100.
                    )* 100) / 100).toFixed(2))} </Form.Label>
                </Col>
                {/* <Col xs={colSize}>
                    <InputGroup onChange={onChangeFifthPercentage} defaultValue='75'>
                    <Form.Control type="number" placeholder="75..." 
                    maxlength="100"
                    onKeyPress={handleKeyDown}
                    />
                    <InputGroup.Text><FontAwesomeIcon icon={icon} /></InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                     <Form.Label> {""} </Form.Label>
                </Col> */}
            </Row>
        </Form.Group>
        </Accordion.Body>
        </Accordion.Item>
    </Accordion>

        </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    </>);
    {/* <Form.Group className="mb-3">
        <Form.Label>Report Name </Form.Label>
        <InputGroup onChange={onChangeInput}>
        <Form.Control type="text" placeholder="Enter a Report Name..." 
        maxlength="100"
        onKeyPress={handleKeyDown}
        />
        <InputGroup.Text><FontAwesomeIcon icon={faFileAlt} /></InputGroup.Text>
        </InputGroup>
    </Form.Group> */}
  });
/* export const CollapsableAdvancedOptions = (props) => {

    return (
    <Accordion defaultActiveKey={""}>
        <Accordion.Item>
        <Accordion.Button className="d-flex justify-content-between align-items-center">
            <span>
            <span className="sidebar-icon"><FontAwesomeIcon icon={faArrowCircleDown} /> </span>
            <span className="sidebar-text">{"advanced options"}</span>
            </span>
        </Accordion.Button>
        <Accordion.Body className="multi-level">
            <InputFormula {...props}/>
        </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    );
}; */


export const CollapsableMergeOptions = forwardRef((props, ref) => {
    const [orders, setOrders] = useState(1);
    useImperativeHandle(ref, () => ({
  
        update (name){
            
        }
    
      }));
    const onChangeOrders = useCallback((e) => { setOrders(e); }, [orders]);
    return (
    <>
    <div style={{padding: "20px 40px 0px 40px",}}>
    <div style={{
        padding: "0px 30px 0px 30px",
    }}>
    <Accordion defaultActiveKey={""}>
        <Accordion.Item /* eventKey={eventKey} */>
        <Accordion.Button className="d-flex justify-content-between align-items-center">
            <span>
            <span className="sidebar-icon"><FontAwesomeIcon icon={faArrowCircleDown} /> </span>
            <span className="sidebar-text">{"advanced options"}</span>
            </span>
        </Accordion.Button>
        <Accordion.Body className="multi-level">
            <p> coming soon.. </p>
        </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    </div>
    </div>
    </>);
  });