
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container, NavItem } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import NotFoundImage from "../../assets/img/illustrations/lock.svg";
import { GoogleSignIn } from "../../components/Custom/GoogleSignIn";
import { TextWrapper } from "../../components/Custom/InfoSection/InfoElements";
import { NavbarContainer, NavMenu } from "../../components/Custom/NavBar/NavBarElement";
import styled from "styled-components";

const Google = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 16px;
  //height: 800px;
  position: relative;
`;
const A = styled.a`
  text-decoration:none;
  //color: #4A4F72;
  //text-decoration: none;
  &:hover {
    transition: ease-in-out;
    color: #4A4F72;
    //font-size: 17px;
  }
`;
export default () => {
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <Card.Link as={Link} to={Routes.DashboardOverview.path}>
                  <Image src={NotFoundImage} className="img-fluid w-75" />
                </Card.Link>
                <h1 className="text-primary mt-5">
                  <span className="fw-bolder">Restricted</span> access.
                </h1>
                <p className="lead my-4">
                  If you should have access, try signing in (possibly again) with the <span className="fw-bolder">Google</span> Button
                  </p>
                  <p className="lead my-4"> If your already signed in, try contacting <span className="fw-bolder">Junglr</span> (providing your Google's email) to give your account access </p>
                  <p>If none of the above, looks like your in the wrong place. <A href="https://www.junglr.com">Junglr.com</A>? maybe
                </p>
                <Google>
                  <GoogleSignIn opt={0} theme={1}/>
                </Google>
                <Button as={Link} onClick={()=>{window.location.href="/";}} variant="primary" className="animate-hover" /* to={Routes.Presentation.path} */>
                  <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                  Go back home
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
