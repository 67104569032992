import React from 'react';


export default () => {
    return (<>
    <div>
        <h1> Privacy Policy </h1>
    </div>
    <div>
        <p> We use amazon ads data within the junglr company as well as in Google SpreadSheets </p>
    </div>
    </>);
}