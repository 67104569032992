import * as React from 'react';
import STRForm from './STR/STRForm';
import STRTable from './STR/STRTable';
import { fecthJSON, isObject } from 'src/utils';
import { Button } from '@themesberg/react-bootstrap';
import { CircularProgress } from '@material-ui/core';


export default () => {
  const [loading, setLoading] = React.useState(false);
  const createNewReport = async () => {
    const url = 'https://q6aofwop5gf2pusfyb6vr7jz2m0fbeer.lambda-url.us-east-1.on.aws/';
    setLoading(true);
    fecthJSON(url, {operation: 'make-report'})
    .then(data=>{
      console.log('response', data);
      if(isObject(data)){
        if(data?.status === 'success' && 'file_id' in data){
          const file_id = data.file_id;
          const report_url = `https://docs.google.com/spreadsheets/d/${file_id}`
          window.open(report_url);
        }
      }
      setLoading(false);
    })
    .catch(err=>{
      console.log('error', err);
      setLoading(false);
    });
  }

  return (<>
      <div className='container'>
        <div className='MyForm'>
          <Button onClick={createNewReport}> Create Report </Button>
          <div style={{padding: 10, margin: 10}}>
            {!loading ? '' : <CircularProgress/>}
          </div>
          {/* <STRForm/> */}
        </div>
        <div className='MyTable'>
          {/* <STRTable checkURL='streport' tableURL='/finalreportdata' process_id='cbreport'/> */}
          {/* <STRTable checkURL='streport' tableURL='/streportdata' api='streportdata' process_id='streport'/> */}
        </div>
      </div>
    </>);
};

// import React, {useState, useEffect, useRef, Component, memo, useCallback, forwardRef, useImperativeHandle} from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faInfo, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
// import { Col, Row, Button, Dropdown, Form, InputGroup, Modal } from '@themesberg/react-bootstrap';
// import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
// import { GeneralInfoForm } from "../components/Forms";
// import axios from "axios";
// import Progress from "../components/Progress";

// import Profile3 from "../assets/img/team/profile-picture-3.jpg";

// import { STReportTable, TransactionsTable } from "../components/Tables";
// //import { pageReport } from "../data/tables";

// import { faFileAlt as faFile } from "@fortawesome/free-solid-svg-icons";
// import { CircularProgress, LinearProgress } from "@mui/material";
// import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
// import { Spreadsheet } from "react-spreadsheet";
// import styled from "styled-components";
// import Menu from "../components/Custom/MenuBar";
// import Excel from "../components/Custom/Excel";
// import SavedCheck from "../components/Custom/SavedCheckbox";
// import STRTable from "./STR/STRTable";

// const pageReport = [
//   { id: 1,  icon: faFileAlt, status: 76, name: 'Waiting for data', link: '#', link2: '#' },
//   { id: 2, icon: faFileAlt, status: 555, name: 'from server', link: '#/settings', link2: '#/settings' },
// ];

// const testData = [
//   {value: "something", name: "cbreport1.xlsx",},
//   {value: "something", name: "unique.xlsx"},
//   {value: "something", name: "cbreport3.xlsx",},
//   {value: "something", name: "dizzy_creek.xlsx",},
//   {value: "something", name: "cbreport5.xlsx",},
//   {value: "something", name: "cbreport6.xlsx",},
//   {value: "something", name: "cbreport7.xlsx",},
//   {value: "something", name: "cbreport8.xlsx",},
//   {value: "something", name: "cbreport1.xlsx",},
//   {value: "something", name: "cbreport1.xlsx",},
//   ];

// const STable = () => {
//   const [checked, setChecked] = useState(true);
//   const [tableData, setTableData] = useState(pageReport);
//   const MyTable = styled.div`
//   `;
//   const MyDiv = styled.div`

//     //font-family: Untitled Sans, sans-serif;
//     //font-size: 14px;
//     //line-height: 20px;
//     //color: #000000aa;
//     height: 110%;
//     //position: absolute;
//     //overflow: auto;
//     background-color: #262B40;
//     border-radius: 25px;
//     //font-weight: 400;
//     //text-decoration: none;
//     //overflow: scroll;
//     //width: ${ "95%"};
//     /* display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     display: grid;
//     grid-auto-columns: minmax(auto, 1fr);
//     align-items: center;
//     grid-template-areas: ${`'col1 col2 col3'`}; */
//     //min-height: 100%;
//     /* &:hover {
//       //transform: scale(1.006);
//       //transition: all .2s ease-in-out;
//     }

//     &:hover .child {
//       //transform: scale(0.994);
//       //transition: all .2s ease-in-out;
//     } */

//     .child3{
//       //position: absolute;
//       margin-left: 0;//-100%;
//       margin-top: 50px;
      
//       //width: 100%;
//       &:hover {
//       //transform: scale(1.02) !important;
//       //transition: all .2s ease-in-out;
//     }

//     .child2 {
//       //width: 100%;
//       margin-left: -100%;
//       position: absolute;
//       display: flex;
//       z-index: -12 !important;
//       pointer-events: none;
//     }
//       input{
//         border: 1px solid #ffffff00; /* Red border only if the input is empty */
//       }
//     }

//     .child4 {
//       position: absolute;
//       left:35%;
//       margin-left:-7%;
//       width:20%;
//       font-weight: 900;
//       letter-spacing: 2px;
//       z-index: 35;
//       // width: 10% !important;
//     }

//     .inputFile {
//       margin-left:5%;
//       //color: white;
//       //text-shadow: 1px 1px 2px #ababab44;
//       z-index: 36;
//     }
//   `;

// const checkTable = (_data) => {
//   try{
//     if(_data.length != tableData.length){ return true; }
//     for (let i = 0; i < _data.length; i++) {
//       const element = _data[i];
//       const element2 = tableData[i];
//       if( element.id != element2.id || element.status != element2.status || element.name != element2.name || element.link != element2.link || element.link2 != element2.link2)
//       { return true; }
//     }
//   }catch(err) {console.log(err); return true;}
//   return false;
// }
// const updateTable = async() => {
//   await fetch('/streportdata')
//   .then((response) => response.json())
//   .then((data) => {
//     if(checkTable(data.tableData)){
//       console.log("updating table");
//       try{
//       if(typeof(data) == 'string'){
//         var _data = JSON.parse(data);
//       }else{var _data = data;}
//       var new_data = [];
//       console.log(_data.tableData);console.log(data);
//       for (let i = 0; i < _data.tableData.length; i++) {
//         const el = _data.tableData[i];
//         new_data.push({ id: el.id,  icon: el.link=='false', icon2: el.link2=='false', status: el.status, name: el.name, link: el.link, link2: el.link2 },)
//       }
//       }catch(err){console.log(err); var new_data = [{id: 1,  icon: true, icon2: true, status: '<-- error', name: String(err), link: '/', link2: '/'}]}
//       setTableData(new_data);//_data.tableData);
//       console.log(new_data);//_data.tableData);
//     }
//   })
// };
// const timeout = (delay) => {
//   return new Promise( res => setTimeout(res, delay) );
// }
// const tableLoop = async() => {
//   while(true){
//     await updateTable();
//     break;
//     await timeout(5000); //for 10 sec delay
//   }
// }
// useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
//   var test = true
//   // Update the document title using the browser API
//   if (test) {
//     test = false
//     tableLoop();
//   }
// }, []);

//   return (<>
//     <MyDiv>
        
//         <Row>
//         <CSpace xs={1}/>
//         <Col>
//           <Space/>
//           <SavedCheck title="Toggle Information" setChecked={setChecked} name='streport'/>
          
//           <MyTable className='child'>
//           <STReportTable className='child' items={tableData} checked={checked} />
//           </MyTable>
//           <Space/>
//         </Col>
//         <CSpace  xs={1}/>
//         </Row>
//       </MyDiv>
//   </>);
// };

// const Style = styled.div`
//   .container_row{
//     display: flex;
//    /*  display: grid;
//     align-items: center; */
//   }

//   .layer1{
//     position: absolute;
//     margin-left: -15%;
//     margin-top: 5%;
//     width: 100%;
//   } 
// /*   .layer2{
//     grid-column: 1;
//     grid-row: 1;
//   } */
//   .layer2 {
//     width: 100%;
//     margin-left: -100%;
//     //position: absolute;
//     display: flex;
//     z-index: 2;
//     pointer-events: none;
//   }

//   .child3{
//       //position: absolute;
//       margin-left: 0;//-100%;
//       margin-top: 50px;
      
//       //width: 100%;
//       &:hover {
//       //transform: scale(1.02) !important;
//       //transition: all .2s ease-in-out;
//     }

//     .child2 {
//       //width: 100%;
//       margin-left: -100%;
//       position: absolute;
//       display: flex;
//       z-index: -12 !important;
//       pointer-events: none;
//     }
//       input{
//         border: 1px solid #ffffff00; /* Red border only if the input is empty */
//       }
//     }

//     .child4 {
//       position: absolute;
//       left:35%;
//       margin-left:-7%;
//       width:20%;
//       font-weight: 900;
//       letter-spacing: 2px;
//       z-index: 35;
//       // width: 10% !important;
//     }
// `;

// function Space(props){
//   return (<>{props.size == 'sm' ? <p>&nbsp;</p> : <br></br>}</>);
// }
// function CSpace(props){
//   return (<Col  {...props}><Space size={props.size} /></Col>);
// }
// function RCSpace(props){
//   return (<Row><CSpace size={props.size} {...props}/></Row>);
// }

// const fecthJSON = async (url) =>{
//   return await fetch(url)
//     .then((response) => response.json())
//     .then((data) => {
//         if(typeof(data) == 'string'){
//           var _data = JSON.parse(data);
//         }else{var _data = data;}
//         return _data;
//     })
// }


// function HelpInfo(props){
//   const [showDefault, setShowDefault] = useState(false);
//   const handleClose = () => setShowDefault(false);
//   return (<>
//     <React.Fragment>
//       <Button size="sm" href="#gray" bsPrefix="text" variant="primary" className="m-3" onClick={() => setShowDefault(true)}>
        
//         <p><FontAwesomeIcon icon={faInfo} /> click for help </p>
//       </Button>

//       <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
//         <Modal.Header>
//           <Modal.Title className="h6">Info</Modal.Title>
//           <Button variant="close" aria-label="Close" onClick={handleClose} />
//         </Modal.Header>
//         <Modal.Body>
//         <><p> Use: </p>
//               <p> To submit a report input a BusinessReport.csv and Bulk.xlsx file and click submit. </p>
//               <p> You may enter a custom name in the Report Name field </p>
//               <p> To use with Google Sheets click the {<FontAwesomeIcon icon={faGoogle} />} to download a xlsx file. </p>
//               <p>To use with Excel click the {<FontAwesomeIcon icon={faMicrosoft} />} to download an xlsm file. For Excel please enable macros to clean the Breakdown and data Sheet. If you are not able to enable macros please replace all '"='' with '='' and all '@' with '' (nothing) for Sheets "Breakdown" and "data" (Excel should have a find and replace feature on the top right of the {'"Home"'} tab)</p></>
//               <p></p>
//               <p> Side note. entering {"'{i}'"} into the Report Name will add an index for that specific name. {"(this was for testing but could  be helpful)"}</p>
//          {/*  <p>With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.</p>
//           <p>The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.</p> */}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="gray" onClick={handleClose}>
//             Ok
//         </Button>
//           <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
//             Close
//         </Button>
//         </Modal.Footer>
//       </Modal>
//     </React.Fragment>
//   </>);
// }

// function FileForm(props){
//   const renameFile = (originalFile, newName) => {
//     try {
//     return new File([originalFile], newName, {
//         type: originalFile.type,
//         lastModified: originalFile.lastModified,
//     });
//     } catch(err) {
//       console.log('FileForm, renameFile ERROR:');
//       console.log(err);
//       return originalFile;
//     }
//   }
//   const onFileChange = (event) => {
//     props.setFile(renameFile(event.target.files[0], props.fileName))
//   }
//   return (
//   <>
//     <Form>
//       <Form.Label
//       > {props.title} </Form.Label>
//       <Form.Control type="file" onChange={onFileChange} />
//     </Form>
//   </>);
// }


// export default () => {
  
//   const ref = useRef(null);
//   const textRef = useRef(null);

//   class Cell extends Component {
//     constructor(){
//       super();
//       [this.v, this.setV] = useState(null)
//       this.state = {
//         cell: null,
//       };
//     }
//     rangeView = ({ cell }) => {
//       var _value = 'ok';
//       const getValue = (_data) => {

//       return _data.data.value;};
//       return (<input
//         type="text"
//         value={getValue({ data: cell })}
//         disabled
//         style={{ pointerEvents: "none" }}
//       />
//       );
//     };

//     rangeEdit = ({ cell, onChange }) => {
//       var _cell = 'ok';
//       const getValue = (_data) => {
//       this.state.cell = _data.data.value
//       return _data.data.value;};
//       return (<input
//         type="text"
//         onChange={e => {
//           onChange({...cell, value: e.target.value});
//         }}
//         value={getValue({ data: cell }) || ""}
//         autoFocus
//       />);
//       };
//   }
//   var this_data = [];
//   for (let i = 0; i < 5; i++) {
//     //const element = _data[i];
//     const c = new Cell();
//     this_data.push([{ value: "" }, { value: "", DataViewer: c.rangeView, DataEditor: c.rangeEdit}])
//   }
//   const [excelData, setExcelData] = useState(this_data)
//     /* [
//     [{ value: "" }, { value: "", DataViewer: RangeView, DataEditor: RangeEdit}],
//     [{ value: "" }, { value: "" }],
//     [{ value: "" }, { value: "" }],
//     [{ value: "" }, { value: "" }],
//   ]); */

//   const [excelEditedData, setExcelEditedData] = useState([]);
//   useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
//     //console.log(excelEditedData);
//   }, [excelEditedData]);
//   const row = [
//     "1",
//     "2",
//     "3",
//     "4",
//   ];

//   const MyDiv = styled.div`

//     //font-family: Untitled Sans, sans-serif;
//     //font-size: 14px;
//     //line-height: 20px;
//     //color: #000000aa;
//     height: 110%;
//     //position: absolute;
//     //overflow: auto;
//     background-color: #262B40;
//     border-radius: 25px;
//     //font-weight: 400;
//     //text-decoration: none;
//     //overflow: scroll;
//     //width: ${ "95%"};
//     /* display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     display: grid;
//     grid-auto-columns: minmax(auto, 1fr);
//     align-items: center;
//     grid-template-areas: ${`'col1 col2 col3'`}; */
//     //min-height: 100%;
//     /* &:hover {
//       //transform: scale(1.006);
//       //transition: all .2s ease-in-out;
//     }

//     &:hover .child {
//       //transform: scale(0.994);
//       //transition: all .2s ease-in-out;
//     } */

//     .child3{
//       //position: absolute;
//       margin-left: 0;//-100%;
//       margin-top: 50px;
      
//       //width: 100%;
//       &:hover {
//       //transform: scale(1.02) !important;
//       //transition: all .2s ease-in-out;
//     }

//     .child2 {
//       //width: 100%;
//       margin-left: -100%;
//       position: absolute;
//       display: flex;
//       z-index: -12 !important;
//       pointer-events: none;
//     }
//       input{
//         border: 1px solid #ffffff00; /* Red border only if the input is empty */
//       }
//     }

//     .child4 {
//       position: absolute;
//       left:35%;
//       margin-left:-7%;
//       width:20%;
//       font-weight: 900;
//       letter-spacing: 2px;
//       z-index: 35;
//       // width: 10% !important;
//     }

//     .inputFile {
//       margin-left:5%;
//       //color: white;
//       //text-shadow: 1px 1px 2px #ababab44;
//       z-index: 36;
//     }
//   `;

// const MySS = styled(Spreadsheet)`
//     &:hover {
//       //transform: scale(1.02) !important;
//       //transition: all .2s ease-in-out;
//     }
// `;
//   const MyTable = styled.div`
//     &:hover {
//       //transform: scale(1.02) !important;
//       //transition: all .2s ease-in-out;
//     }
//   `;

//   const col = ["Asin" , "Product Group" ];

//   //const [tableData, setTableData] = useState(pageReport);
//   const [brandFile, setBrandFile] = useState(null);
//   const [productFile, setProductFile] = useState(null);
//   const [displayFile, setDisplayFile] = useState(null);
//   const [bulkInput_ref, setBulkInputRef] = useState("");
//   const [reportInput_ref, setReportInputRef] = useState("");
//   const [msgText, setMSGText] = useState("");
//   const [newNameText, setnewNameText] = useState("");

//   const [isSuccess, setIsSuccess] = useState(false);
//   const [barProgress, setProgress] = useState(0);

//   //const [checked, setChecked] = useState(true);

//   const [menuLabel, setMenuLabel] = useState("Choose a C. B. Report");
//   const [menuValue, setMenuValue] = useState(""); 

//   const [menuData, setMenuData] = useState(testData);
  
//   const [menuFetch, setMenuFetch] = useState(()=>{});

//   /* const checkTable = (_data) => {
//     try{
//       if(_data.length != tableData.length){ return true; }
//       for (let i = 0; i < _data.length; i++) {
//         const element = _data[i];
//         const element2 = tableData[i];
//         if( element.id != element2.id || element.status != element2.status || element.name != element2.name || element.link != element2.link || element.link2 != element2.link2)
//         { return true; }
//       }
//     }catch(err) {console.log(err); return true;}
//     return false;
//   } */
//   /* const updateTable = async() => {
//     await fetch('/streportdata')
//     .then((response) => response.json())
//     .then((data) => {
//       if(checkTable(data.tableData)){
//         console.log("updating table");
//         try{
//         if(typeof(data) == 'string'){
//           var _data = JSON.parse(data);
//         }else{var _data = data;}
//         var new_data = [];
//         console.log(_data.tableData);console.log(data);
//         for (let i = 0; i < _data.tableData.length; i++) {
//           const el = _data.tableData[i];
//           new_data.push({ id: el.id,  icon: el.link=='false', icon2: el.link2=='false', status: el.status, name: el.name, link: el.link, link2: el.link2 },)
//         }
//         }catch(err){console.log(err); var new_data = [{id: 1,  icon: true, icon2: true, status: '<-- error', name: String(err), link: '/', link2: '/'}]}
//         setTableData(new_data);//_data.tableData);
//         console.log(new_data);//_data.tableData);
//       }
//     })
//   }; */
//   const updateMenu = async() => {
//     var _data = await fecthJSON('/all-cbreports-for-streport');
//       console.log(_data);
//       let different = false;
//       if(menuData.length != _data.length){different = true;}
//       else{
//         for (let i = 0; i < menuData.length; i++) {
//           const element = menuData[i];
//           const element2 = _data[i];
//           if(element.name != element2.name || element.value != element2.value) { different = true; }
//         }
//       }
//       if(different){
//         setMenuData(_data);
//       }
//   };
//   const timeout = (delay) => {
//     return new Promise( res => setTimeout(res, delay) );
//   }
//   /* const tableLoop = async() => {
//     while(true){
//       await updateTable();
//       console.log('menu stuff');
//       updateMenu();
//       break;
//       await timeout(5000); //for 10 sec delay
//     }
//   } */
//   useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
//     var test = true
//     // Update the document title using the browser API
//     if (test) {
//       test = false
//       //tableLoop();
//       updateMenu();
//     }
//     setProgress(0);
//   }, []);

//   const displayText = async(text) => {
//     var s = ''
//     for (let i = 0; i < text.length; i++) {
//       await timeout(10);
//       const element = text[i];
//       s += element;
//       setMSGText(s);
//     }
//     setMSGText(text);
//     await timeout(2000);
//     while (s.length > 0) {
//       s = s.substring(0, s.length - 1);
//       setMSGText(s);
//       await timeout(5);
//     }
    
//     setMSGText("");
//   }
//   const resetProgress = async() => {
//     await timeout(2000);
//     while (barProgress > 2) {
//       setProgress(barProgress - 5);
//       await timeout(5);
//     }
//     setProgress(0);
//   }
//   const click = (value=null) => {
    
//     var some_files = [brandFile, productFile, displayFile]  // select files

//     // create FormData containing all files with a value
//     const formData = new FormData()
//     var i = 0; // to give the file and index. the for loop can skip a file
//     for (let index = 0; index < some_files.length; index++) {
//       const element = some_files[index]
//       if (!element) {
//         continue;
//       }
//       formData.append('File' + String(i + 1), element)
//       i++;
//     }
//     if(i == 0){return;}  // no files exist

//     //displayText("sent")
//     // POST files to web server
//     // using "axios" and progress bar
//     let extra = '';
//     console.log('value is');
//     console.log(value)
//     console.log(typeof(value))
//     if(typeof(value)=='string') { extra = (value=='' ? '' : ('/'+ value)); }
//     else { extra = (newNameText=='' ? '' : ('/'+ newNameText)) }
//     var url = '/streport'+extra; // if no new name is given the wbe server will create one
//     axios.post(url, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data", // declaring is FormData
//         "cbreport": String(menuValue)
//       },
//       onUploadProgress: (progressEvent) => {
//         const myprogress = Math.ceil((progressEvent.loaded / progressEvent.total) * 95);
//         setProgress(myprogress);
//       },
//       onDownloadProgress: (progressEvent) => {
//         const myprogress = Math.ceil(95 + (progressEvent.loaded / progressEvent.total) * 5);
//         setProgress(myprogress);
//       },
//     }).then(()=>{resetProgress();})
//     .catch((error)=>{resetProgress(); console.log(error);});
//   }

  

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       event.preventDefault();  // Form.Control refreshes the page on enter. this disables that feature
//       click();
//     }
//   }

//   const handleExcelData = (_data) => {

//   }

//   const MyExcel = memo(() => <Excel/>)
//   const Child = forwardRef((props, ref) => {

//     // The component instance will be extended
//     // with whatever you return from the callback passed
//     // as the second argument
//     useImperativeHandle(ref, () => ({
  
//       getAlert() {
//         alert("getAlert from Child");
//       }
  
//     }));
  
//     return <h1>Hi</h1>;
//   });
//   const MyInputText = forwardRef((props, ref) => {
//     const [textInput, setTextInput] = useState("Hallo");
//     const onChangeInput = useCallback(
//       (e) => {
//         setTextInput(e.target.value);
//       },
//       [textInput]
//     );
//     const handleKeyDown = (event) => {
//       if (event.key === 'Enter') {
//         event.preventDefault();  // Form.Control refreshes the page on enter. this disables that feature
//         doClick();
//       }
//     }
//     const doClick = () => {
//       console.log('text click')
//       click(textInput);
//     }
//     useImperativeHandle(ref, () => ({
  
//       doClick (){
//         console.log('text click')
//         click(textInput);
//       }
  
//     }));
//     return (
//       <Form.Group className="mb-3">
//         <Form.Label>Report Name </Form.Label>
//         <InputGroup onChange={onChangeInput}>
//           <Form.Control type="text" placeholder="Enter a Report Name..." 
//           maxlength="100"
//           onKeyPress={handleKeyDown}
//           />
//           <InputGroup.Text><FontAwesomeIcon icon={faFileAlt} /></InputGroup.Text>
//         </InputGroup>
//       </Form.Group>
//     );
//      {/* <Form.Group className="mb-3">
//         <Form.Label>Report Name { msgText }</Form.Label>
//         <InputGroup onChange={e => setnewNameText(e.target.value)}>
//           <Form.Control type="text" placeholder="Enter a Report Name..." 
//           maxlength="100"
//           onKeyPress={handleKeyDown}
//           />
//           <InputGroup.Text><FontAwesomeIcon icon={faFileAlt} /></InputGroup.Text>
//         </InputGroup>
//       </Form.Group>  */}
//   });
//   class MMyInputText extends React.Component{
//     constructor(props){
//       //const [textInput, setTextInput] = useState("Hallo");
//       super(props);
//       try {
//         let v = this.state.textInput;
//       } catch(err) {
//         this.state = {
//           textInput: ''
//         }
//       }
//       this.doClick = this.doClick.bind(this);
//       this.setTextInput = this.setTextInput.bind(this);
//       this.onChangeInput = (e) => {
//           this.setTextInput(e.target.value);
//         }
//       ;
//       this.handleKeyDown = (event) => {
//         if (event.key === 'Enter') {
//           event.preventDefault();  // Form.Control refreshes the page on enter. this disables that feature
//           this.doClick();
//         }
//       }
      
//     }
//     setTextInput(value) {
//       this.setState({textInput: value})
//     }
//     doClick(){
//       console.log('text click')
//       click(this.state.textInput);
//     }
//     render(){
//       return (
//         <Form.Group className="mb-3">
//         <Form.Label>Report Name </Form.Label>
//         <InputGroup onChange={this.onChangeInput}>
//           <Form.Control type="text" placeholder="Enter a Report Name..." 
//           maxlength="100"
//           onKeyPress={this.handleKeyDown}
//           />
//           <InputGroup.Text><FontAwesomeIcon icon={faFileAlt} /></InputGroup.Text>
//         </InputGroup>
//       </Form.Group>
//       );
//     }
//   }
//   /* const MyInputText = (props) => {
//     const [textInput, setTextInput] = useState("Hallo");
//     const onChangeInput = useCallback(
//       (e) => {
//         setTextInput(e.target.value);
//       },
//       [textInput]
//     );
//     const handleKeyDown = (event) => {
//       if (event.key === 'Enter') {
//         event.preventDefault();  // Form.Control refreshes the page on enter. this disables that feature
//         doClick();
//       }
//     }
//     const doClick = () => {
//       console.log('text click')
//       click(textInput);
//     }
//     return (
//       <Form.Group className="mb-3">
//         <Form.Label>Report Name { msgText }</Form.Label>
//         <InputGroup onChange={onChangeInput}>
//           <Form.Control type="text" placeholder="Enter a Report Name..." 
//           maxlength="100"
//           onKeyPress={handleKeyDown}
//           />
//           <InputGroup.Text><FontAwesomeIcon icon={faFileAlt} /></InputGroup.Text>
//         </InputGroup>
//       </Form.Group>
//     );
//      <Form.Group className="mb-3">
//         <Form.Label>Report Name { msgText }</Form.Label>
//         <InputGroup onChange={e => setnewNameText(e.target.value)}>
//           <Form.Control type="text" placeholder="Enter a Report Name..." 
//           maxlength="100"
//           onKeyPress={handleKeyDown}
//           />
//           <InputGroup.Text><FontAwesomeIcon icon={faFileAlt} /></InputGroup.Text>
//         </InputGroup>
//       </Form.Group> 
//   } */
//   const inputStyle = {
//     marginLeft:"5%",
//       color: "white",
//       textShadow: "1px 1px 2px #ababab44"
//   }
//   return (
//     <>
//     <div>
//     <Row> <Col><h1> Search Term Report &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
//         {/* <HelpInfo/>  */}</h1>
//         <p> comparing search terms and keywords </p></Col> </Row>
//         <div style={{height: "110%",
//     backgroundColor: "#262B40",
//     borderRadius: "25px"}}>
//         <div style={inputStyle}>
//       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      
//       <Row>
//       <Col xs={12} xl={18}>
        
//           <FileForm
//            title={"STR Brands (.xlsx) (required) "} fileName="brand.xlsx" setFile={setBrandFile}/>
        
//         <Space/>
//         <div className="inputFile">
//           <FileForm title={"STR Products (.xlsx) (required) "} fileName="product.xlsx" setFile={setProductFile}/>
//           </div>
//         <Space/>
//         <div className="inputFile">
//           <FileForm title={"STR Display (.xlsx) (optional) "} fileName="display.xlsx" setFile={setDisplayFile}/>
//           </div>
//         <Space/>
        
//         <Style>
//         <Row>
//           <CSpace/>
//           <Col>
//             <Menu className='child2'
//             data={menuData} 
//             select={(n, v)=>{
//               setMenuLabel(n); console.log(v);
//               setMenuValue(v);
//               (async()=>{
//                 await setTimeout(2000);
//                 ref.current.customFetchData(v);
//               })()//menuFetch();
//               }} 
//             title={menuLabel}/>
//           {/* </Col>
//           <Col> */}
//           <Excel 
//           ref={ref}
//           className='child3' 
//           /* handleData={handleExcelData}  */
//           darkMode={false}  
//           /* data={excelData} {[[{ value: "" },{ value: "" }],[{ value: "" },{ value: "" }],[{ value: "" },{ value: "" }]]}  */
//           /* name={menuValue} */
//           columnLabels={col} /* rowLabels={row} onChange={setExcelData} */ 
//           /* fetchData={click => setMenuFetch(click)} */
//           />
//           </Col>
          
//           <CSpace/>
         
//         </Row>
        
//         </Style>
//         <RCSpace/>
        
//         <Row>
//           <Col xs={12} xl={18}>
//           <div className="inputFile">
//           <Form>
//             <MyInputText
//             ref={textRef}/>
//           </Form>
//           </div>
//           </Col>
//           <Col>
//             {/*  <Progress variant="dark" label="Upload Progress" size="md" value={barProgress} /> */}
           
//             {/* <p> { msgText } </p> */}
//           </Col>
          
//         </Row>
//         <div className="inputFile">
//           <LinearProgress 
//           style={ 	  { 		color: '#white', backgroundColor: "transparent",		/* borderRadius: '50%', */ 		/* boxShadow: 'inset 0 0 0 5.5px white'  */	  } 	} 
//           variant="determinate"  value={barProgress} color="inherit" />
//           </div>
//         <RCSpace/>
//         <Row>   
        
//               <Button 
//                 className='child4'
//                 variant="white" 
//                 onClick={()=>{
//                   ref.current.customFetchData();//menuFetch();
//                   textRef.current.doClick();
//                   //click()
//                   }}
                
//                 >
//                 Submit
//               </Button>
//               <CSpace/>
//         </Row>
//         <RCSpace/><RCSpace/>
//          {/*  <GeneralInfoForm /> */}
//         </Col>

//         <div className="d-flex">
//         </div>
//         </Row>
//       </div>
//       </div></div>
      
//       <Row><Col><h1>&nbsp;</h1></Col></Row>
//       <Row><Col><h1>&nbsp;</h1></Col></Row>
//       </div>
//       <div>
//         <STRTable/>
//       </div>
//       {/* <MyDiv>
        
//         <Row>
//         <CSpace xs={1}/>
//         <Col>
//           <Space/>
//           <SavedCheck title="Toggle Information" setChecked={setChecked} name='streport'/>
          
//           <MyTable className='child'>
//           <STReportTable className='child' items={tableData} checked={checked} />
//           </MyTable>
//           <Space/>
//         </Col>
//         <CSpace  xs={1}/>
//         </Row>
//       </MyDiv> */}
//     </>
//   );
// };