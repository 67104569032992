import React, {useEffect, useState} from 'react';
import {FaBars} from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu,NavLinks, NavItem, NavBtn, NavBtnLink, MembersText } from './NavBarElement';
//import GoogleLogin from 'react-google-login';
import { IconContext } from 'react-icons/lib';
import {theme} from '../Theme.js';
import { animateScroll as scroll } from 'react-scroll';
import { GoogleSignIn } from '../GoogleSignIn';
import { Auth } from 'aws-amplify';
import { Routes } from 'src/routes';
const responseGoogle = (response) => {
  console.log(response);
}

const Navbar = ({toggle}) => {
  const [scrollNav, setScrollerNav] = useState(false);

  const changeNav = () => {
    if(window.scrollY >= 800) {
      setScrollerNav(true);
    } else {
      setScrollerNav(false);
    }
  };

  useEffect(()=> {
    window.addEventListener('scroll', changeNav);
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }


  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
    <IconContext.Provider value={{color: theme.lightColor3}}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            Junglr
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars/>
          </MobileIcon>
          <NavMenu>
            <NavItem>
                <NavLinks to="about" smooth={true} duration={500} spy={true} exact offset={-80}
                activeClass="active">About</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to="discover" smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Discover</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to="services" smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Services</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to="signup" smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Sign Up</NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn style={{background: `rgba(0.0,0.0,0.0,${hover ? '0.0' : '0.0'})`, borderStyle: 'solid', borderColor: hover ? '#fff' : `#ffffff00`}}
          onMouseEnter={onHover} onMouseLeave={onHover}>
            <MembersText> Members </MembersText>
            {/* <AwesomeButton onPress={()=>{window.location.href = '/report'}} type='primary'> Get Report </AwesomeButton> */}
            <NavBtnLink onClick={()=>{Auth.federatedSignIn({ provider:"Google" })//window.location.href="/dashboard/overview"
          }} >Dashboard</NavBtnLink>
          <NavBtnLink onClick={()=>{window.location.href=Routes.NewDashbord.path}} >New Dashboard</NavBtnLink>
            {/* <p> ok  im ok, things ok?</p>
            <div id="g_id_onload"
     data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
     data-context="signin"
     data-login_uri="https://junglr.app/login"
     data-auto_select="true"
     data-itp_support="true">
</div>
<p> ok  im ok, things ok?</p>
             */}
              {/* <script src="https://accounts.google.com/gsi/client" async defer></script> */}
              
              
            {/* <NavBtnLink onClick={()=>{window.location.href = '/login'}}>Sign In</NavBtnLink> */}
            <GoogleSignIn opt={4}/>
          {/* </NavBtn>
          <NavBtn> */}
            {/* <p>-</p>
            <AwesomeButton onPress={()=>{window.location.href = '/login'}} type='primary' > Log In/Sign Up </AwesomeButton> */}
            {/* <GoogleLogin
              clientId="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
              render={renderProps => (
                <AwesomeButton onPress={renderProps.onClick} type='primary' disabled={renderProps.disabled}> Sign In </AwesomeButton>
                //<button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
              )}
              buttonText="Login"
              onSuccess={console.log}
              onFailure={console.log}
              cookiePolicy={'single_host_origin'}
            /> */}
           
            {/* <NavBtnLink to="/signin">Sign In</NavBtnLink> */}
          </NavBtn>
        </NavbarContainer>
      </Nav>
      </IconContext.Provider>
    </>
  )
};


export default Navbar;