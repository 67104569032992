
import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";


import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

// import DataTable from '../../components/Custom/DataTable';
import styled from "styled-components";
import { DataGrid } from '@mui/x-data-grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import Spreadsheet from "react-spreadsheet";
import { fecthJSON } from "src/utils";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';

const dateToString = date => date.format('YYYY-MM-DD');

const options = {
  title: {
    text: 'My chart'
  },
  series: [{
    data: [452, 787, 835, 770, 25, 479, 784, 542, 766, 726, 44, 676, 726, 381, 609, 735, 649, 30, 64, 690, 59, 722, 392, 209, 576]
  }]
}

const StyledDataTable = styled(DataTable)`
  /* all: initial !important;
  :root {
    all: initial !important;}
*,
*::before{all: initial !important;}
*::after {
  all: initial !important;
  } */
`;

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
];

const reportColumns = [
  {
      "field": "Date",
      "headerName": "Date",
      "width": 130
  },
  {
      "field": "Ad Type",
      "headerName": "Ad Type",
      "width": 130
  },
  {
      "field": "Ad Sales",
      "headerName": "Ad Sales",
      "width": 130
  },
  {
      "field": "Ad Spend",
      "headerName": "Ad Spend",
      "width": 130
  },
  {
      "field": "Clicks",
      "headerName": "Clicks",
      "width": 130
  },
  {
      "field": "CPC",
      "headerName": "CPC",
      "width": 130
  },
  {
      "field": "ACoS",
      "headerName": "ACoS",
      "width": 130
  },
  {
      "field": "Ad CVR",
      "headerName": "Ad CVR",
      "width": 130
  },
  {
      "field": "Orders",
      "headerName": "Orders",
      "width": 130
  },
  {
      "field": "Total Sales",
      "headerName": "Total Sales",
      "width": 130
  },
  {
      "field": "TACoS",
      "headerName": "TACoS",
      "width": 130
  },
  {
      "field": "id",
      "headerName": "ID",
      "width": 90
  }
];

const totalColumns = [
  {
      "field": "Ad Sales",
      "headerName": "Ad Sales",
      "width": 130
  },
  {
      "field": "Ad Spend",
      "headerName": "Ad Spend",
      "width": 130
  },
  {
      "field": "ACoS",
      "headerName": "ACoS",
      "width": 130
  },
  {
      "field": "Clicks",
      "headerName": "Clicks",
      "width": 130
  },
  {
      "field": "Orders",
      "headerName": "Orders",
      "width": 130
  },
  {
      "field": "CPC",
      "headerName": "CPC",
      "width": 130
  },
  {
      "field": "Ad CVR",
      "headerName": "Ad CVR",
      "width": 130
  },
  {
      "field": "Total Sales",
      "headerName": "Total Sales",
      "width": 130
  },
  {
      "field": "TACoS",
      "headerName": "TACoS",
      "width": 130
  },
  {
      "field": "id",
      "headerName": "ID",
      "width": 90
  }
]

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

function DataTable() {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}

function SelectLabels() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
        <FormHelperText>With label + helper text</FormHelperText>
      </FormControl>
    </div>
  );
}


export default (props) => {
  const [loadingReport, setLoadingReport] = React.useState(false);
  let p = [];
  try{
    p = JSON.parse(localStorage.getItem('profiles'));
  }catch(err){}
  const [profiles, setProfiles] = React.useState(p);
  const [loadingProfiles, setLoadingProfiles] = React.useState(false);

  let v = '';try{
    v = JSON.parse(localStorage.getItem('age'));
  }catch(e){}
  const [age, _setAge] = React.useState(v);
  const setAge = v => {
    _setAge(v);
    try{
      localStorage.setItem('age', JSON.stringify(v));
    }catch(err){}
  }

  const [start, _setStart] = React.useState(dayjs().subtract(30, 'day'));
  const setStart = d => {
    _setStart(d);
  }

  const [end, _setEnd] = React.useState(dayjs());
  const setEnd = d => {
    _setEnd(d);
  }

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [report, setReport] = React.useState([]);
  const [total, setTotal] = React.useState([]);

  React.useEffect(()=>{
    fetchProfiles();
    fetchReports();
  }, []);

  const data = [
    [{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" },{ value: "" }, { value: "" }],
  ];

  const fetchProfiles = () => {
    setLoadingProfiles(true);
    fecthJSON('https://5xem7lfugkrh3ectboasmwfvxu0kavoq.lambda-url.us-east-1.on.aws/', {operation: 'profiles'})
    .then(data => {
      setLoadingProfiles(false);
      console.log(data);
      setProfiles(data.profiles);
      localStorage.setItem('profiles', JSON.stringify(data.profiles));
      
      // setProfiles(data);
    })
    .catch(e=>{
      setLoadingProfiles(false);
    });
  }

  const fetchReports = () => {
    setLoadingReport(true);
    const url = 'https://5xem7lfugkrh3ectboasmwfvxu0kavoq.lambda-url.us-east-1.on.aws/';
    const obj = {operation: 'account-health', 'profile-id': `${age}`, start: dateToString(start), end: dateToString(end)};
    console.log(obj);
    fecthJSON(url, obj)
    .then(data => {
      setLoadingReport(false);
      console.log(data);
      if('daily-report' in data) setReport(data['daily-report']);
      if('totals' in data) setTotal(data['totals']);
      // setProfiles(data);
    })
    .catch(e=>{
      setLoadingReport(false);
    })
  };

  return (
    <>
    <Row className="justify-content-md-center">
      <Col xs={12} className="mb-4 d-none d-sm-block">
        <h1> {"Welcome" + (props.profileName != '' ? ',' : '')} </h1>
        <h2> {props.profileName} </h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">Account Profile</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={age}
            label="Account Profile"
            onChange={handleChange}
          >
            {profiles?.map((profile, index) => <MenuItem value={profile.profileId}>{profile.id}</MenuItem>)}
          </Select>
          <FormHelperText>Select the Account to Display</FormHelperText>
        </FormControl>
        {!loadingProfiles ? '' : <CircularProgress size={25}/>}
      </Col>
      <Col>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <p>Start Date</p>
        <DatePicker defaultValue={dayjs('2022-04-17')} value={start} onChange={(newValue) => setStart(newValue)} />
        <div style={{padding: 10}}/>
        <p>End Date</p>
        <DatePicker defaultValue={dayjs('2022-04-17')} value={end} onChange={(newValue) => setEnd(newValue)}/>
      </LocalizationProvider></Col>
    </Row>
    <Row>
      <Col>
        <Button onClick={fetchReports}
        > Fetch Reports </Button>
        {!loadingReport ? '' : <CircularProgress size={25}/>}
      </Col>
    </Row>
    <Row>
      <Col>
        <h2> Totals </h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <div style={{ height: 200, width: '100%' }}>
          <DataGrid
            rows={total}
            columns={totalColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[1]}
            checkboxSelection
          />
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <h2> Daily Breakdown </h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={report}
            columns={reportColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 30, 50, 100]}
            checkboxSelection
          />
        </div>
      </Col>
    </Row>
    {/* <Row>
      <Col>
        <p> There are a million things you can do within a dashboard. Here are a few quick examples</p>
      </Col>
    </Row>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
          <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />New Task
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faTasks} className="me-2" /> New Task
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" /> Upload Files
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Preview Security
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faRocket} className="text-danger me-2" /> Upgrade to Pro
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <ButtonGroup>
          <Button variant="outline-primary" size="sm">Share</Button>
          <Button variant="outline-primary" size="sm">Export</Button>
        </ButtonGroup>
      </div>

      <Row className="justify-content-md-center">
        <Col>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
        </Col>


        <DataTable/>

        <Spreadsheet data={data} />



        <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col>
        <Col xs={12} className="mb-4 d-sm-none">
          <SalesValueWidgetPhone
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Customers"
            title="345k"
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Revenue"
            title="$43,594"
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CircleChartWidget
            title="Traffic Share"
            data={trafficShares} />
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <PageVisitsTable />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <TeamMembersWidget />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders} />
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  <RankingWidget />
                </Col>

                <Col xs={12} className="px-0">
                  <AcquisitionWidget />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row> */}
    </>
  );
};
