import SVG1 from '../../../images/svg-1.svg';
import SVG2 from '../../../images/svg-2.svg';
import SVG3 from '../../../images/svg-3.svg';
import {theme} from '../Theme';
export const homeObjOne = {
    id: 'about',
    bgColor: theme.darkColor1,//(false?'#f9f9f9' : '#010606'),
    subDesColor: theme.lightColor2,//'#f7f8fa',//true,
    descColor: theme.lightColor3,// (false ?  '#010606' : '#fff'),
    topLine: 'Grow your business',
    headLine: '',
    description: "With Junglr App, we empower you to grow your Amazon business by providing you with powerful tools and insights. With our platform, you can pinpoint what is performing well, quickly identify areas that may need improvement, and chart a course for future success—all in a matter of seconds. Whether you're an experienced Amazon Seller or just starting out, Junglr is here to help you navigate the path to growth.",//"Pinpoint what is doing well, where things went wrong and how to go forward in seconds.",
    buttonLabel: 'Get Started',
    imgStart: false,
    img: SVG1,//require('../../images/png-1.png'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'discover',
    bgColor: theme.lightColor1,// true,
    subDesColor: theme.darkColor1,// '#010606',
    descColor: theme.darkColor1,// (true ?  '#010606' : '#fff'),
    topLine: 'Discover',
    headLine: '',
    description: "We understand that in the ever-evolving world of e-commerce, staying ahead of the competition is crucial. Our platform helps you discover new facets of your business that you may have overlooked. Dive deep into market trends, customer behavior, and emerging opportunities. With Junglr's insights, you can make informed decisions and stay one step ahead in the fast-paced Amazon marketplace.",//"Get insights to new aspects of your business.",
    buttonLabel: 'Get Started',
    imgStart: true,
    img: SVG2,//require('../../images/svg-2.jpg'),
    alt: 'other',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjThree = {
    id: 'signup',
    bgColor: theme.darkColor1, // (false?'#f9f9f9' : '#010606'),
    subDesColor: theme.lightColor1, // '#f7f8fa',
    descColor: theme.lightColor3, //(false ?  '#010606' : '#fff'),
    topLine: 'About us',
    headLine: 'Our Mission',
    description: "With Junglr App, our mission is clear: to give Amazon Sellers the tools and knowledge they need to thrive in the world of e-commerce. We understand the unique challenges and opportunities that come with selling on Amazon, and we're dedicated to providing you with the support and resources you require to do business effectively. Whether it's through data-driven insights, expert guidance, or innovative solutions, Junglr is here to empower you on your journey to success.",//'To give Amazon Sellers what they need to do business.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: SVG3,//require('../../images/svg-2.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}
