import React from 'react';
import styled from 'styled-components';
import M from './index2.js'
import { MenuText as Drop } from './InputMenu.js';

//import './my.css'
///// eslint-disable-next-line import/no-webpack-loader-syntax
import _css from '!!raw-loader!./my.css';
const MyMenu = styled(M)`
    ${_css}
    /** Additional customization if necessary (e.g. positioning) */
`;
const Style =  styled.div`
all: initial !important;
${_css}
/** Additional customization if necessary (e.g. positioning) */
`;

const Menu = (props) => {
    return(
    <Style>
        <M className='menu' {...props}/>
    </Style>);
}

export const TextMenu = (props) => {
    return (
        <Style>
            <Drop className='menu' {...props}/>
        </Style>
    )
}

export default Menu;