import React from 'react';
import { Button } from '../ButtonElement';
import {InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img} from './InfoElements';
import { sendEmail } from '../HeroSection';

const InfoSection = ({id,
    bgColor,
subDesColor,
lightTextDesc,
topLine,
headLine,
description,
buttonLabel,
imgStart,
img,
alt,
dark,
dark2,
primary,
descColor}) => {
  return (
    <>
        <InfoContainer lightBg={bgColor} id={id}>
        
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={subDesColor}>{headLine}</Heading>
                            <Subtitle darkText={descColor}>{description}</Subtitle>
                            <BtnWrap>
                                <Button
                                onClick={()=>{sendEmail();//window.location.href="https://www.junglr.com/";
                            }}  
                                to="home"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                primary={primary ? 1 : 0}
                                dark={dark ? 1 : 0}
                                dark2={dark2 ? 1 : 0}
                                >{buttonLabel}</Button>
                                
                            </BtnWrap>
                            
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default InfoSection
