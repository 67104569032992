/* import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
//import { awsConfig } from '../../auth/aws-exports';
//import { useNavigate } from 'react-router';

//Amplify.configure(awsConfig);

export function Login() {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setUser(data);
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => setUser(currentUser))
      .catch(() => console.log("Not signed in"));

    return unsubscribe;
  }, []);
    return (
    <div className="App">
        <p> {process.env.REACT_APP_TEST} </p>
      <button onClick={() => Auth.federatedSignIn()}>Open Hosted UI</button>
      <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook })}>Open Facebook</button>
      <button onClick={() => {
        //window.location.href = ('https://junglr.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=3vus5itkimsu5o09ed1hd9oml&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000')
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })
        
        }}>Open Google</button>
      <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Amazon })}>Open Amazon</button>
      <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Apple })}>Open Apple</button>
      <button onClick={() => Auth.signOut()}>Sign Out</button>
      <div>{user && user.getUsername()}</div>
    </div>
  );
} */
// https://ui.docs.amplify.aws/react/guides/auth-protected
// components/Login.js
import React, { useEffect } from "react";

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';
import { Button } from "@material-ui/core";
import { isAllowed } from "src/auth/util";
import { Routes as routes } from "src/routes";
import { timeout } from "src/components/utilities";
import { GoogleSignIn } from "src/components/Custom/GoogleSignIn";

export function Login() {
  const { route, authStatus } = useAuthenticator((context) => [context.route, context.authStatus]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || routes.Reports.path;
  useEffect(() => {
    // (async()=>{
    //   await timeout(3000);
    //   let allowed = await isAllowed();
    //   if(allowed) window.location.href = !from.endsWith('/examples/sign-in') ? from : routes.Reports.path;
    // })()
    console.log('route: ', route, authStatus)
    if (route === 'authenticated') {
      
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <View className="auth-wrapper">
        <Button onClick={()=>{window.location.href = '/'}}> Home </Button>
        <GoogleSignIn opt={4}/>
      <Authenticator /* variation="modal"  */
      socialProviders={['google']}
      /* hideSignUp={true} */
      
      >

      </Authenticator>
    </View>
  );
}