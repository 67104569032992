import React from 'react';
import PropTypes from 'prop-types';
import { GooeyCircleLoader, WaveLoader, BouncyBallsLoader, SlicesLoader } from "react-loaders-kit";

const Loader = (props) => {

   const loaderProps = {
    loading: true,
    size: 100,
    duration: 2,
    colors: ["#FFFFFF", "#262B40", "#F5F8FB"],
  };
  const waveLoaderProps = {
    loading: true,
    size: 50,
    duration: 1,
    color: '#1E88E5'//'#5e22f0',
  };

  const bouncyLoaderProps = {
    loading: true,
    size: 10,
    duration: .7,
    colors: ['#262B40','#262B40','#262B40' ]//'#5e22f0',
  };

  const slicesLoaderProps = {
    loading: true,
    size: 10,
    duration: .7,
    color: '#262B40',
    colors: ['#262B40','#262B40','#262B40','#262B40' ]//'#5e22f0',
  };

  const {
    color, background, loading, promiseTracker, zIndex,
  } = props;

  const defaultLoaderStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex,
    background: 'rgba(255,255,255,.2)',
    backfaceVisibility: 'hidden',
  };

  const defaultSpinnerStyle = {
    position: 'absolute',
    left: '40%',
    top: '40%',
    transform: 'translate(-50%, -50%)',
    width: 100,
    height: 100,
  };

  const { promiseInProgress } = promiseTracker ? promiseTracker() : false;
  const loaderStyle = {
    ...defaultLoaderStyle,
    background,
  };
  /*<div style={loaderStyle}>
      <div style={defaultSpinnerStyle}>
        <GooeyCircleLoader {...loaderProps} />
      </div>
    </div>*/
  const _props = 
  props.custom ? props.custom :
  (props.gooey ?  {...loaderProps, colors: ["#FFFFFF"+props.o, "#262B40"+props.o, "#F5F8FB"+props.o]} : 
  props.wave ? waveLoaderProps : 
  props.bouncyBalls ? bouncyLoaderProps :
  props.slices ? slicesLoaderProps : 
  waveLoaderProps);
  return (
    loading || promiseInProgress
      ?  (props.gooey ? 
      <GooeyCircleLoader {..._props}
       /> : 
      props.wave ?
      <WaveLoader {..._props}/> : 
      props.bouncyBalls ? 
      <BouncyBallsLoader {..._props}/> :
      props.slices ? 
      <SlicesLoader {..._props} /> :
      <WaveLoader {..._props}/> ) : ''
  );
};

Loader.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool,
  background: PropTypes.string,
  promiseTracker: PropTypes.any,
  zIndex: PropTypes.number,
};

Loader.defaultProps = {
  color: '#000',
  loading: false,
  background: 'rgba(255,255,255,.5)',
  promiseTracker: false,
  zIndex: 999,
};

export default Loader;