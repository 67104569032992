/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_e8jPkZk04",
    "aws_user_pools_web_client_id": "5tfrurrlg80ckromqra31fonkp",
    "oauth": {
        "domain": "junglr-app.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid"
        ],
        "redirectSignIn": "http://localhost:3000/dashboard/overview,https://192.168.100.3:8000/dashboard/overview,https://dev.dsg356dch93dv.amplifyapp.com/dashboard/overview,https://junglr.app/dashboard/overview,https://orfos.website/dashboard/overview,https://www.junglr.app/dashboard/overview,https://www.orfos.website/dashboard/overview",
        "redirectSignOut": "http://localhost:3000,https://192.168.100.3:8000,https://dev.dsg356dch93dv.amplifyapp.com,https://junglr.app,https://orfos.website,https://www.junglr.app,https://www.orfos.website",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_cloud_logic_custom": [
        {
            "name": "test2",
            "endpoint": "https://xhg1i3wil0.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "junglr",
            "endpoint": "https://kgefnn8kh0.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "cbreportdata",
            "endpoint": "https://l6hsz305f3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "email",
            "endpoint": "https://u0d1vb7zu1.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "document",
            "endpoint": "https://qz12n4vj0b.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "job",
            "endpoint": "https://keyr6r88wb.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "test3",
            "endpoint": "https://8no74idu2a.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "googlepermissions",
            "endpoint": "https://8f3t67sxp8.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "googleapi",
            "endpoint": "https://udecf88qy9.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "report",
            "endpoint": "https://zt5p8euia7.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "googlepermreadonly",
            "endpoint": "https://zjrxbmg0wb.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "junglrappbucket",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
