import styled from 'styled-components';
import { Link, Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { theme } from "../Theme";
export const Nav = styled.nav`
    background: ${({scrollNav})=>(scrollNav ? theme.darkColor4 : 'transparent')};//#000;
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px; z-index: 1;
    width: 100%;
    padding: 0 24px; max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
    color: ${theme.lightColor3};//#fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5 rem;
    display: flex;
    align-items: center; margin-left: 24px; font-weight: bold;
    text-decoration: none;
`;

export const MobileIcon = styled.div`
display: none;
@media screen and (max-width: 768px) {
display: block;
position: absolute;
top: 0;
right: 0;
transform: translate(-100%, 60%);
font-size: 1.8rem; 
cursor: pointer;
color: ${theme.lightColor3};//#fff
}
`;
export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center; margin-right: -22px;
@media screen and (max-width: 768px) {
display: none;
}
`;
export const NavItem = styled.li`
height: 80px;
`;
export const NavLinks = styled(LinkS)`
    color: ${theme.lightColor3};//#fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid ${theme.color1};//#01bf71;
    }
`;

export const NavBtn = styled.nav`
display: flex;
align-items: center;
justify-items: center;
/* background: rgba(0.0,0.0,0.0,0.15);//#01bf71;  */
transition: all 0.2s ease-in-out;
border-radius: 50px; 
padding: 10px 20px 10px 20px;
margin: 5px 0px 5px 0px;
@media screen and (max-width: 768px) {
display: none;
}
`;

export const MembersText = styled.h6`
    color: white;//#01bf71; 
    margin: 20;
    padding-right: 20px;
    align-self: center;
    justify-self: center;
    font-weight: 800;
    font-size: 18px;
`;

export const NavBtnLink = styled(LinkR)`
    border-radius: 50px; 
    background: ${theme.color1};//#01bf71; 
    white-space: nowrap;
    padding: 10px 22px;
    //margin-left: 16px;
    margin-right: 16px;
    color: ${theme.darkColor1};//#010606; 
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
    transition: all 0.2s ease-in-out;
    background: ${theme.lightColor3};//#fff;
    color: ${theme.darkColor1};//#010606;
}
`;

