import { useState, useEffect, forwardRef, Children } from "react";
import { Col, Row, Button, Form, InputGroup, Modal, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { Storage } from "aws-amplify";
import { faApple, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { RCSpace } from "../STR/STRFormElements";
import { faArrowRight, faInfo, faList } from "@fortawesome/free-solid-svg-icons";

const Download  = forwardRef((props, ref) => {
    const {icon, s3key} = props;
    const [link, setLink] = useState('');

    useEffect(()=>{
        (async()=>{
            const signedURL = await Storage.get(s3key);
            console.log(signedURL)
            setLink(signedURL);
        })()
    }, []);

    const handleClick = () => {
        window.open(link);
    }

    const styles = {
        
        
    }

    return <div style={{padding: 20}}>
        <div style={{padding: 20, borderRadius: '15px', backgroundColor: 'white', cursor: 'pointer'}}
         onClick={handleClick}>
            {link === '' ? <CircularProgress size={20}/> : <FontAwesomeIcon size='3x' style={styles} icon={icon} />}
        </div>
    </div>
});

const YoYMacDowload = forwardRef((props, ref) => {
    return <Download icon={faApple} s3key='tools/downloads/junglr_yoy_mac.zip'/>
});

const YoYWindowsDowload = forwardRef((props, ref) => {
    return <Download icon={faMicrosoft} s3key='tools/downloads/junglr_yoy_windows.zip'/>
});

const NewYoYMacDowload = forwardRef((props, ref) => {
    return <Download icon={faApple} s3key='tools/downloads/new_junglr_yoy_mac.zip'/>
});

const NewYoYWindowsDowload = forwardRef((props, ref) => {
    return <Download icon={faMicrosoft} s3key='tools/downloads/new_junglr_yoy_windows.zip'/>
});

const DownloadSection = forwardRef((props, ref) => {
    const {title, usageTitle, usageLink, children} = props;

    return <>
        <RCSpace/>
        <div style={{borderRadius: '15px', backgroundColor: '#262B40'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <h5 style={{color: "white", padding: 20}}> {title} </h5>
                {!usageTitle || !usageLink ? <></> : <h5 style={{color: "white", padding: 20, cursor: 'pointer'}} onClick={()=>{window.open(usageLink)}}> {usageTitle} </h5>}
            </div>
            <div style={{display: 'flex', justifyContent: 'left', alignItems: 'left'}}
            >
               {children}
            </div>
        </div>
    </>
})
const [p1, p2] = ['(', ')']
const DownloadPage = forwardRef((props, ref) => {
    return <>
        <Row> <Col> <h1> Download Tools</h1> </Col> </Row>
       
        <DownloadSection title='Campaign Manager Data Scrapper (original)' usageTitle={<><FontAwesomeIcon size='sm' icon={faArrowRight} /> Click Up </>} usageLink="https://app.clickup.com/t/8678wb3f0">
            <YoYMacDowload/>
            <YoYWindowsDowload/>
        </DownloadSection>
        <DownloadSection title='Campaign Manager Data Scrapper (new)' usageTitle={<><FontAwesomeIcon size='sm' icon={faArrowRight} /> Click Up </>} usageLink="https://app.clickup.com/t/8678wb3f0">
            <NewYoYMacDowload/>
            <NewYoYWindowsDowload/>
        </DownloadSection>
    </>
});

export default DownloadPage;