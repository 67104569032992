import React, {useState, useEffect, useRef, Component, memo, useCallback, forwardRef, useImperativeHandle} from "react";
import { Accordion, Button, Col, Dropdown, Modal, OverlayTrigger, Row, Tooltip } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { STReportTable } from "../../components/Tables";

import styled from "styled-components";
import SavedCheck from "../../components/Custom/SavedCheckbox";
import { faFileAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Input } from "@material-ui/core";
import { CollapsableMergeOptions } from "./STRFormElements";
import { QuickToastMessage } from "../Custom/GetMessage";
import { Api } from "../../auth/APIHandler";
import { Storage } from "aws-amplify";
import { getAllowedUsers, getEmail, isAdmin } from "src/auth/util";
const pageReport = [
  // { id: 1,  icon: faFileAlt, status: 76, name: 'Waiting for data', link: '#', link2: '#' },
  // { id: 2, icon: faFileAlt, status: 555, name: 'from server', link: '#/settings', link2: '#/settings' },
];

function print(...obj){
  console.log(...obj);
}

function Space(props){
    return (<>{props.size == 'sm' ? <p>&nbsp;</p> : <br></br>}</>);
}
function CSpace(props){
    return (<Col  {...props}><Space size={props.size} /></Col>);
}
function RCSpace(props){
    return (<Row><CSpace size={props.size} {...props}/></Row>);
}
  
const timeout = (delay) => {
  return new Promise( res => setTimeout(res, delay) );
}

const PersonTable = forwardRef((props, ref)=>{
  const {email, process_id, toastRef} = props;


  useImperativeHandle(ref, ()=>({
      async update() {
          console.log('updating');
      }
  }));

  useEffect(()=>{
      // isAdmin().then(answer=>setAdmin(answer)).catch(e=>e);
  }, [])

  return <Accordion defaultActiveKey={""}>
      <Accordion.Item /* eventKey={eventKey} */>
      <Accordion.Button className="d-flex justify-content-between align-items-center">
          <span>
          <span className="sidebar-icon"><FontAwesomeIcon icon={faUser} /> </span>
          <span className="sidebar-text">{email}</span>
          </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <STReportTable className='child' overrideEmail={email} toastRef={toastRef} items={[]} checked={true} process_id={process_id} func={_=>{}} />
          </Accordion.Body>
      </Accordion.Item>
  </Accordion>
});

const MergeButton = forwardRef((props, ref) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const [on, setOn] = useState(false);
  
  const updateStatus = useCallback( async(d) => { setOn(d); },[on]);
  const [canSubmit, setCanSubmit] = useState(true);
  const initialText = "doc {i}";
  const [documentName, setDocumentName] = useState(initialText);
  const updateName = useCallback(
    async(d) => {
      if(typeof(d) == 'string' && d != ''){
        setDocumentName(d);
        setCanSubmit(true)
      }else{setCanSubmit(false)}
    },[documentName, canSubmit]);
    let data = {};
    const [objects, setObjects] = useState([]);
    const setObj = useCallback( async(o) => { setObjects(o); },[objects]);
  useImperativeHandle(ref, () => ({
  
    doClick (v, _data){
      data = _data;
      updateStatus(v);
      if(data != null){
        let temp = [];
        for (const property in data) {
          temp.push([property, data[property][1]])
        }
        setObj(temp);//Object.keys(data));
      }
    }

  }));
  useEffect(() => {
  }, []);
  let values = {}
  class Some extends Component{
    constructor(items){
      super(items);
      this.k = items.k[0];
      this.val = items.k[1];
    }

    render(){
      return (<>
      <p style={{fontWeight: "bold", marginBottom: "0px", marginTop: "20px"}}> Rename for Merge</p>
      <Input  defaultValue={this.val} 
      onChange={e=>{props.setData(this.k, e.target.value);}}/></>);
    }
  }
  return (<React.Fragment>
    
    <OverlayTrigger
      placement="top"
      trigger={['hover', 'focus']}
      overlay={
        <Tooltip  style={on ? {width: "0% !important", height: "80%"} : {}}> {on ? '' : "Select at least two reports"} </Tooltip>
      }>
        <div>
        <Button disabled={!on} onClick={() => setShowDefault(true)} variant="white"> merge </Button>
        </div>
    </OverlayTrigger>
    <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Info</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
      <div style={{padding: "10px"}}>
        <p style={{fontWeight: "bold"}}> Document Name</p>
        <Input style={{width: "100%"}}
        defaultValue={initialText} onChange={(e)=>{updateName(e.target.value)}}/>
        <Space/>
        <div style={{marginBottom: "25px", marginTop: "50px"}}/>
        <body><hr style={{width: "80%", float: "center"}} class="solid"/></body>
        {Object.values(objects)?.map(r => <Some key={`report-${r.id}`} k={r}/>)}
        <CollapsableMergeOptions/>
      </div>
      </Modal.Body>
      <Modal.Footer>
        {canSubmit ? <Button variant="gray" 
                        onClick={()=>{handleClose(); props.onClick(documentName)}}>
          Submit
      </Button> : ''}
        <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
          Close
      </Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>);/* (<>{on ? <Button {...props} variant="white"> merge </Button> : ''}</>); */
});

export default (props) => {
    const { overrideEmail } = props;
    const btnRef = useRef(0);
    const toastRef = useRef(1);
    const [checked, setChecked] = useState(true);
    const [tableData, setTableData] = useState(pageReport);
    const MyTable = styled.div`
    `;
    const MyDiv = styled.div`
  
      //font-family: Untitled Sans, sans-serif;
      //font-size: 14px;
      //line-height: 20px;
      //color: #000000aa;
      height: 110%;
      //position: absolute;
      //overflow: auto;
      background-color: #262B40;
      border-radius: 25px;
      //width: fit-content;
      padding-left: 5%;
      padding-right: 5%;
      //font-weight: 400;
      //text-decoration: none;
      //overflow: scroll;
      //width: ${ "95%"};
      /* display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      display: grid;
      grid-auto-columns: minmax(auto, 1fr);
      align-items: center;
      grid-template-areas: ${`'col1 col2 col3'`}; */
      //min-height: 100%;
      /* &:hover {
        //transform: scale(1.006);
        //transition: all .2s ease-in-out;
      }
  
      &:hover .child {
        //transform: scale(0.994);
        //transition: all .2s ease-in-out;
      } */
  
      .child3{
        //position: absolute;
        margin-left: 0;//-100%;
        margin-top: 50px;
        
        //width: 100%;
        &:hover {
        //transform: scale(1.02) !important;
        //transition: all .2s ease-in-out;
      }
  
      .child2 {
        //width: 100%;
        margin-left: -100%;
        position: absolute;
        display: flex;
        z-index: -12 !important;
        pointer-events: none;
      }
        input{
          border: 1px solid #ffffff00; /* Red border only if the input is empty */
        }
      }
  
      .child4 {
        position: absolute;
        left:35%;
        margin-left:-7%;
        width:20%;
        font-weight: 900;
        letter-spacing: 2px;
        z-index: 35;
        // width: 10% !important;
      }
  
      .inputFile {
        margin-left:5%;
        //color: white;
        //text-shadow: 1px 1px 2px #ababab44;
        z-index: 36;
      }
    `;
  const isReady = (_status) => {
    return _status == 'done' || _status == 'timed out' || _status == 'error_logged'
  }
  const checkTable = (_data, lastData, length) => {
    try{
      // return _data.length != length;
      if(_data.length != lastData.length){ return true; }
      let val = false;
      for (let i = 0; i < _data.length; i++) {
        if(_data.length > i && lastData.length > i){
          val = val || isReady(_data[i].status) != isReady(lastData[i].status);
        }
      }
      return val;
      // for (let i = 0; i < _data.length; i++) {
      //   const element = _data[i];
      //   const element2 = tableData[i];
      //   if( element.id != element2.id || element.status != element2.status || element.name != element2.name || element.link != element2.link || element.link2 != element2.link2)
      //   { return true; }
      // }
    }catch(err) {console.log(err); return true;}
    return false;
  }
  const updateTable = async(__data, length) => {
    let l = length;
    let lastData = __data;
    const actData = (data) => {
      if(checkTable(data.tableData, lastData, length)){
        l = data.tableData.length;
        lastData = data.tableData;
        try{
        if(typeof(data) == 'string'){
          var _data = JSON.parse(data);
        }else{var _data = data;}
        var new_data = [];
        for (let i = 0; i < _data.tableData.length; i++) {
          let el = _data.tableData[i];//JSON.parse(JSON.stringify(_data.tableData[i]));
          el.icon = el.link=='false'; 
          el.icon2 = el.link2=='false';
          new_data.push(el);//({ id: el.id,  icon: el.link=='false', icon2: el.link2=='false', status: el.status, name: el.name, link: el.link, link2: el.link2, status_link: el.status_link, og_name: el.og_name, og_name2: el.og_name2 },)
        }
        }catch(err){console.log(err); var new_data = [{id: 1,  icon: true, icon2: true, status: '<-- error', name: String(err), link: '/', link2: '/'}]}
        setTableData(new_data);//_data.tableData);
      }
    }

    /* let _url = props.tableURL;
     {
      'cbreport': 'https://4ccyzktpqantb3mucdxvhq4rm40ynvhc.lambda-url.us-east-1.on.aws/',
      'streport': 'https://q3fbz6ds526qu7pck4y7nvfew40tbtkw.lambda-url.us-east-1.on.aws/',
    }[props.process_id] */
    
    /* let key = 'supersecretpassword';  //user_info.key
    let heads = { 'key': key, 'email': 'danielwillolson@gmail.com' }
    const user_info = await (
      await fetch('/sapi/userinfo')
    ).json();
    console.log(user_info.userData);
    heads.key = user_info.userData.key;
    heads.email = user_info.userData.email;
    console.log(_url) */
    //let data = await Storage.get()
    let response = await Api(props.api, props.tableURL)//fetch(_url, {headers: heads, redirect: 'follow'});

    console.log('cbreportdata', response); 
    try{console.log(JSON.stringify(response))}catch(e){console.log(e)}
    
    response = await response.json();
    actData(response);


    
    /* await fetch(props.tableURL)
    .then((response) => response.json())
    .then(actData) */
    return lastData;
  };
  const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
  }
  const tableLoop = async(v) => {
    let length = tableData.length;
    let _data = tableData;
    while(true){
      if(!v.stillGoing){
        break;
      }
      _data = await updateTable(_data, length);
      length = _data.length;
      //break;
      await timeout(5000); //for 10 sec delay
    }
  }
  
  let name1s = [];
  let name2s = [];
  let names = {}
  const setData = (k, v) => {names[k][1] = v;}
  const checkMergeOption = (checked, _name, new_name, name1, name2) => {
    let i = names[`${name1}${name2}`];
    if(i && !checked){
      delete names[`${name1}${name2}`];
    }else if(!i && checked){
      names[`${name1}${name2}`] = [_name, new_name, name1, name2]
    }
    // let i = name1s.indexOf(name1);
    // if(i > -1 && !checked){
    //   name1s.splice(i, 1)
    // }else if(i < 0 && checked){
    //   name1s.push(name1)
    // }
    // i = name2s.indexOf(name2);
    // if(i > -1 && !checked){
    //   name2s.splice(i, 1)
    // }else if(i < 0 && checked){
    //   name2s.push(name2)
    // }
    try{
    if(Object.keys(names).length >= 2){//name1s.length >= 2 || name2s.length >= 2){
      btnRef.current.doClick(true, names);
    }else{
      btnRef.current.doClick(false, names);
    }
    }catch(err){}
  }

  const removeAllCheckedMerge = () => {
    name1s = []; name2s = []; btnRef.current.doClick(false, null);
  }
  
  const mergeClick = (name) => {
    let some1 = {}; let some2 = {};
    for (const [key, value] of Object.entries(names)) {
      some1[value[1]] = value[2];
      some2[value[1]] = value[3];
    }

    // for (let i = 0; i < name1s.length; i++) {
    //   some1[String(i)] = name1s[i];
    // }
    // for (let i = 0; i < name2s.length; i++) {
    //   some2[String(i+name1s.length)] = name2s[i];
    // }
    fetch('/merge-reports', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'key': 'super secret key',
      },
      body: JSON.stringify(
        { name: name, type: props.process_id, xlsx: some1, xlsm: some2})
    })
    toastRef.current.showMessage('request sent', 2000);
    removeAllCheckedMerge();
  }

    const [admin, setAdmin] = useState(false);
    const [userEmails, setUserEmails] = useState([]);
    const [myEmail, setMyEmail] = useState('none');

    useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}

      var test = {stillGoing: true}
      // Update the document title using the browser API
      isAdmin().then(answer=>setAdmin(answer)).catch(e=>e);
      getEmail().then(email=>setMyEmail(email)).catch(e=>e);
      //tableLoop(test);
      return () => test.stillGoing = false;
    }, []);

    useEffect(()=>{
        getAllowedUsers().then(allowedUsers=>setUserEmails(allowedUsers)).catch(e=>e);
    }, [myEmail])

    
    return (<>
        <QuickToastMessage ref={toastRef}/>
      <MyDiv>
          <Row>
          <CSpace xs={1}/>
          <Col>
          <Space/>
            <div>
              <div style={{display: "flex", float:'right', padding: "10px"}}>
                <div style={{paddingLeft: '10px'}}>
                  {/* <Button variant="white"> merge </Button> */}
                  <MergeButton onClick={mergeClick} setData={setData} ref={btnRef}/>
                </div>
                <Space/><Space/>
                <div style={{paddingLeft: '10px'}}>
                {/* <Button variant="white"> merge </Button> */}
                </div>
              </div>
            
            <SavedCheck title="Toggle Information" setChecked={setChecked} name={props.checkURL}/* 'streport' *//>
            <Space/><Space/>
            </div>
            <MyTable className='child'>
            {/* <STReportTable className='child' overrideEmail={overrideEmail} toastRef={toastRef} items={tableData} checked={checked} process_id={props.process_id} func={checkMergeOption} /> */}
            {
                        !admin ? <></> :
            <Accordion defaultActiveKey={""}>
              <Accordion.Item /* eventKey={eventKey} */>
              <Accordion.Button className="d-flex justify-content-between align-items-center">
                  <span>
                  <span className="sidebar-icon"><FontAwesomeIcon icon={faUser} /> </span>
                  <span className="sidebar-text">Admin Access</span>
                  </span>
                  </Accordion.Button>
                  <Accordion.Body className="multi-level" style={{backgroundColor: 'white'}}>
                    
                        {userEmails.filter(email=>email!==myEmail).map(email=><PersonTable email={email} toastRef={toastRef} process_id={props.process_id}/>)}
                    
                  </Accordion.Body>
              </Accordion.Item>
          </Accordion>}
          <CSpace/>
            <STReportTable className='child' overrideEmail={overrideEmail} toastRef={toastRef} items={tableData} checked={checked} process_id={props.process_id} func={checkMergeOption} />
            
            </MyTable>
            <Space/>
          </Col>
          <CSpace  xs={1}/>
          <CSpace  xs={1}/>
          </Row>
        </MyDiv>
    </>);
  };