import styled from 'styled-components';
import {Link} from 'react-scroll';
import { theme } from './Theme';



export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? theme.color1 : theme.darkColor1)};//'#01BF71' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? theme.darkColor1 : theme.lightColor3)};
    font-size: ${({fontbig}) => (fontbig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? theme.lightColor3 : theme.color1)};
    }
`;


export const Button2 = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#dddddd' : theme.darkColor1)};//'#01BF71' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? theme.darkColor1 : theme.lightColor3)};
    font-size: ${({fontbig}) => (fontbig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? theme.color1 : theme.color1)};
    }
`;