import { isObject } from "lodash";
import { useRef, useState, useEffect, useCallback, useImperativeHandle, forwardRef } from "react";
import { S3Excel, getAllowedUsers, getEmail, getS3Json, isAdmin, isAllowed, isDev, putS3Json, savedIindex } from "src/auth/util";
import { CollapsableAdvancedOptions, MyInputText } from "../STR/STRFormElements";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import Menu from "src/components/Custom/MenuBar";
import { Drop, MenuText } from "src/components/Custom/MenuBar/InputMenu";
import useWindowDimensions from "src/components/Custom/useWindowDimensions";
import { Accordion, Button, Card, Col, Form, InputGroup, Modal, Row, Table as BootTable, Tab } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { QuickToastMessage } from "../Custom/GetMessage";
import { tryStringifyJSON } from "src/utils";


export const OutputContainer = forwardRef((props, ref)=>{
    const {style, children} = props;
    
    return <div style={{
        ...(isObject(style) ? style : {}),
        borderRadius: '10px',
        backgroundColor: '#262B40',
        color: '#000000',
        paddingTop: 25, paddingBottom: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        
        rowGap: '10px',
        columnGap: '2em',
    }}>
        {children}
    </div>
})

export const OutputBox = forwardRef((props, ref)=>{
    const {style, children} = props;
    
    return <div style={{
        ...(isObject(style) ? style : {}),
        borderRadius: '10px',
        backgroundColor: '#202436',
        color: '#ffffff',
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
    }}>
        {children}
    </div>
})

export const Table = forwardRef((props, ref)=>{
    const {headers, rows} = props;
    
    return <Card border="light" className="shadow-sm" style={{width: '100%'}}>
    <Card.Body className="pb-0">
      <BootTable responsive className="table-centered table-nowrap rounded mb-0">
        {/* <table> */}
            <thead className="thead-light">
                <tr>
                    {headers.map(h=><th className="border-0"> {h} </th>)}
                    {/* <th> Report </th><th> Name </th><th> Status </th> */}
                </tr>
            </thead>
            <tbody>
                {rows.map(r=><tr>{r.map(c=><td>{c}</td>)}</tr>)}
                {/* <tr>
                    <td>1</td><td>2</td><td>3</td>
                </tr> */}
            </tbody>
        {/* </table> */}
        </BootTable>
      </Card.Body>
    </Card>
})

const PersonTable = forwardRef((props, ref)=>{
    const {email, output} = props;

    const [rows, setRows] = useState([]);

    const getReportStatus = (email) =>{ return async obj => {
            const sheetKey = `reports/${email}/${output.query_id}/${obj['spreadsheetId']}.json`
            let vals = await getS3Json(sheetKey)
            if(!isObject(vals)){
                vals = getS3Json(`reports/${email}/spreadSheetStatus/${output.query_id}/${obj['spreadsheetId']}.json`)
            } else if(!vals.hasOwnProperty('spreadsheetId')){
                vals = getS3Json(`reports/${email}/spreadSheetStatus/${output.query_id}/${obj['spreadsheetId']}.json`)
            }
            // console.log('vals', vals);
            return vals;//Object.keys(vals).map(k=>vals[k]);
        }
    }

    const CardLink = props => {
        const {spreadsheetId} = props; 
        return <FontAwesomeIcon style={{cursor: 'pointer'}} color='#121212' onClick={()=>window.open(`https://docs.google.com/spreadsheets/d/${spreadsheetId}`, '_blank').focus()} icon={faGoogle}></FontAwesomeIcon>
    }

    const getTable = async () => {
        return _getTable(email);
    }

    const _getTable = async (email) => {
        // const email = await getEmail();
        const key = `tables/${email}/${output.query_id}.json`;
        let table = await getS3Json(key);
        if(!Array.isArray(table)) {table = [];}
        // await Promise.all(Object.keys(allRefs).flatMap(k1=>Object.keys(allRefs[k1]).map(async k=>({value: await allRefs[k1][k][fn](), query: k, key: k1}))))
        let _rows = await Promise.all(table.map(getReportStatus(email)));
        // console.log('rows', _rows)
        setRows(//table.map(v=>Object.keys(v).map(k=>v[k]))
            _rows.map(row=>[<CardLink {...row}/>, <p onClick={()=>{if(isDev()){toastRef.current.showMessage(row)}}}
            >{row.name}</p>, row.status])  //row.map(cell=><h1>{cell}</h1>))
        );
    }

    useImperativeHandle(ref, ()=>({
        async update() {
            console.log('updating', output);
            getTable();
        }
    }));

    useEffect(()=>{
        getTable();
        // isAdmin().then(answer=>setAdmin(answer)).catch(e=>e);
    }, [])
    const dev = isDev();
    const toastRef = useRef(0);
    return <div><QuickToastMessage  ref={toastRef}/>
    <Accordion defaultActiveKey={""}>
        <Accordion.Item /* eventKey={eventKey} */>
        <Accordion.Button className="d-flex justify-content-between align-items-center">
            <span>
            <span className="sidebar-icon"><FontAwesomeIcon icon={faUser} /> </span>
            <span className="sidebar-text">{email} {dev ? rows.length : ''} </span>
            </span>
            </Accordion.Button>
            <Accordion.Body className="multi-level">
                <Table headers={['Report', 'Name', 'Status']} rows={rows}/>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion></div>
});


export const ToGoogleSpreadsheet = forwardRef((props, ref)=>{
    const {output, s3} = props;
    const [rows, setRows] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [userEmails, setUserEmails] = useState([]);
    const [myEmail, setMyEmail] = useState('none');

    const other = async (email, array) => {
        const report_key = `public/reports/${email}/${s3.split("/").pop()}`
        let v = await getS3Json(report_key).then(data=>data).catch(e=>e);
        console.log('v...', v);
        return v;
        getS3Json(report_key).then(j=>{
            if((Array.isArray(j))){
                j.forEach(row1=>{
                    array.forEach(row2=>{
                        if(row1.tables['3769ce66-5413-11ee-ae70-685b35c5d36b-284f6499-b22a-49b9-8322-3a4a1fcc0dd2'].spreadsheet_id == row2.spreadsheetId){
                            row2['job'] = row1.job;
                            // "id": "ToGoogleSpreadSheetInput",
                            // "query_id": "3769ce66-5413-11ee-ae70-685b35c5d36b-284f6499-b22a-49b9-8322-3a4a1fcc0dd2",
                
                        }
                    })
                })
            }
        }).catch(e=>console.log('report error:', e))
        // j = [] if not s3.exists(report_key) else s3.load_s3_json(report_key)
        // j.append({'job': r, 'tables': request.json['tables'], 'id': report_id, 'env': env_vars})
        // s3.dump_s3_json(j, report_key)
    }

    const getReportStatus = (email) =>{ return async obj => {
            const sheetKey = `reports/${email}/${output.query_id}/${obj['spreadsheetId']}.json`
            let vals = await getS3Json(sheetKey)
            if(!isObject(vals)){
                vals = getS3Json(`reports/${email}/spreadSheetStatus/${output.query_id}/${obj['spreadsheetId']}.json`)
            } else if(!vals.hasOwnProperty('spreadsheetId')){
                vals = getS3Json(`reports/${email}/spreadSheetStatus/${output.query_id}/${obj['spreadsheetId']}.json`)
            }
            // console.log('vals', vals);
            return vals;//Object.keys(vals).map(k=>vals[k]);
        }
    }

    const CardLink = props => {
        const {spreadsheetId} = props; 
        return <FontAwesomeIcon style={{cursor: 'pointer'}} color='#121212' onClick={()=>window.open(`https://docs.google.com/spreadsheets/d/${spreadsheetId}`, '_blank').focus()} icon={faGoogle}></FontAwesomeIcon>
    }

    const getTable = async () => {
        return _getTable(await getEmail());
    }

    const _getTable = async (email) => {
        // const _other = await other(email);
        // const email = await getEmail();
        const key = `tables/${email}/${output.query_id}.json`;
        let table = await getS3Json(key);
        if(!Array.isArray(table)) {table = [];}
        console.log('table..', table);
        // await Promise.all(Object.keys(allRefs).flatMap(k1=>Object.keys(allRefs[k1]).map(async k=>({value: await allRefs[k1][k][fn](), query: k, key: k1}))))
        let _rows = await Promise.all(table.map(getReportStatus(email)));
        // console.log('rows', _rows)
        setRows(//table.map(v=>Object.keys(v).map(k=>v[k]))
            _rows.map(row=>[<CardLink {...row}/>, <p onClick={()=>{if(isDev()){toastRef.current.showMessage([s3, row])}}}
            >{row.name}</p>, row.status])  //row.map(cell=><h1>{cell}</h1>))
        );
    }

    useImperativeHandle(ref, ()=>({
        async update() {
            console.log('updating', output);
            getTable();
        }
    }));

    useEffect(()=>{
        getTable();
        isAdmin().then(answer=>setAdmin(answer)).catch(e=>e);
        getEmail().then(email=>setMyEmail(email)).catch(e=>e);
    }, [])

    useEffect(()=>{
        getAllowedUsers().then(allowedUsers=>setUserEmails(allowedUsers)).catch(e=>e);
    }, [myEmail])

    const toastRef = useRef(0);
    return <div><QuickToastMessage  ref={toastRef}/>
    <div style={{margin: 10, padding: '5px 15px 5px 15px', backgroundColor: 'white', color: 'rgb(75, 81, 112)', borderRadius: '5px', fontSize: '6px'}}>
        {`Please note. 
These Google Spreadsheets can only be copied and moved within Google Drive.
Downloading them will lose necessary features.`.split('\n').map(txt=><p
    className="mb-0" style={{fontSize: '14px'}}>{txt}</p>)}
    </div>
    {
                        !admin ? <></> :<Accordion defaultActiveKey={""}>
              <Accordion.Item /* eventKey={eventKey} */>
              <Accordion.Button className="d-flex justify-content-between align-items-center">
                  <span>
                  <span className="sidebar-icon"><FontAwesomeIcon icon={faUser} /> </span>
                  <span className="sidebar-text">Admin Access</span>
                  </span>
                  </Accordion.Button>
                  <Accordion.Body className="multi-level" style={{backgroundColor: 'white'}}>
                  
                        {userEmails.filter(email=>email!==myEmail).map(email=><PersonTable email={email} output={output}/>)}
                    
                  </Accordion.Body>
              </Accordion.Item>
          </Accordion>}
    <div style={{width: '100%', display: 'flex', justifyContent: 'center', }}>
        <Table headers={['Report', 'Name', 'Status']} rows={rows}/>
        
    </div>
       
        
    </div>
});

const AllOutputs = {
    ToGoogleSpreadSheet: ToGoogleSpreadsheet,
}

export default AllOutputs;

