import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Card, Toast, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBootstrap, faFonticons, faJs, faMailchimp } from '@fortawesome/free-brands-svg-icons';
import {faJunglr} from '../../assets/img/favicon/safari-pinned-tab copy.svg'
import { faAddressCard, faBookmark, faCircle, faCircleNotch, faMailBulk, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { tryStringifyJSON } from 'src/utils';
function print(...obj){
    console.log(...obj);
}
const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
}
const fecthJSON = async (url, data) =>{
    return await fetch(url, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'key': 'super secret key',
    },
    body: JSON.stringify(data)  // { process_id: p, file_name: f }
    })
    .then((response) => response.json())
    .then((data) => {
        if(typeof(data) == 'string'){
        var _data = JSON.parse(data);
        }else{var _data = data;}
        //captureData(_data);
        return _data;
    })
}

export const ToastServerMessage = forwardRef((props, ref) => {
    const [showDefault, setShowDefault] = useState(false); 
    const toggleDefaultToast = useCallback((v) => { setShowDefault(v); }, [showDefault]);
    const [msg, setMsg] = useState("");
    const setMessage = useCallback((text) => { setMsg(text); }, [msg]);
    const [index, setIndex] = useState(0);
    const setID = useCallback((v) => { setIndex(v); }, [index]);
    // const toggleDefaultToast = () => setShowDefault(!showDefault);
    useImperativeHandle(ref, () => ({
        publicFunc (){}
      }));
    
    
    const handleCloseDefault = () => {
        // fecthJSON("/dashboard-received-message", {type: props.type, id: index});
        toggleDefaultToast(!showDefault);
    }
    useEffect(()=>{
        // (async() => {
        //     await fecthJSON("/dashboard-messages", {type: props.type})
        //     .then((d) => {
        //         if(typeof(d.msg) == 'string' && (d.msg != "none" || d.msg != "none")){
        //             setMessage(d.msg);
        //             setID(d.id)
        //             setShowDefault(true);
        //         }
        //     })
        // })();
    }, [])
    return (
    <>
    <div style={{float: "right"}}>
        <Toast show={showDefault} onClose={handleCloseDefault} className="my-3">
            <Toast.Header className="text-primary" closeButton={false}>
                <FontAwesomeIcon icon={faMailBulk} />
                <strong className="me-auto ms-2">Junglr</strong>
                <small>notification</small>
                <Button variant="close" size="xs" onClick={handleCloseDefault} />
            </Toast.Header>
            <Toast.Body>
                {msg}
            </Toast.Body>
        </Toast>
    </div>
    </>);
  });


export const QuickToastMessage = forwardRef((props, ref) => {
    const [showDefault, setShowDefault] = useState(false); 
    const toggleDefaultToast = useCallback((v) => { setShowDefault(v); }, [showDefault]);
    const [msg, setMsg] = useState("");
    const setMessage = useCallback((text) => { setMsg(text); }, [msg]);
    // const toggleDefaultToast = () => setShowDefault(!showDefault);
    useImperativeHandle(ref, () => ({
        showMessage(_msg, time){
            let message = _msg;
            if(!(typeof message === 'string')) message = tryStringifyJSON(message, String, true);
            setMessage(message);
            toggleDefaultToast(true);
            if(time){
                (async()=>{
                    try{
                        // setMessage(_msg);
                        // toggleDefaultToast(true);
                        await timeout(time);
                    }catch(err){print('err -> ');print(err);}
                    toggleDefaultToast(false);
                })()
            }
        }
        }));


    const handleCloseDefault = () => {
        // return
        toggleDefaultToast(!showDefault);
    }
    useEffect(()=>{
        
    }, [])

    return (
    <>
    <div style={{position: "fixed", top: "0", right: "0", zIndex: 999}}>
        <Toast show={showDefault} onClose={handleCloseDefault} className="my-3">
            <Toast.Header className="text-primary" closeButton={false}>
                <FontAwesomeIcon icon={faMailBulk} />
                <strong className="me-auto ms-2">Junglr</strong>
                <small>notification</small>
                <Button variant="close" size="xs" onClick={handleCloseDefault} />
            </Toast.Header>
            <Toast.Body>
                <div style={{backgroundColor: 'white', borderRadius: '5px', overflow: 'auto', height: `${Math.min(22*(msg.split('\n').length), window.innerHeight*.9)}px`}}>
                    <pre style={{fontSize: '14px', color: 'grey'}}>{msg}</pre>
                </div>
            </Toast.Body>
        </Toast>
    </div>
    </>);
});