
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBoxOpen, faChartPie, faCog, faFileAlt, faHandHoldingUsd, faSignOutAlt, faTable, faTimes, faCalendarAlt, faMapPin, faInbox, faRocket, faUserAlt, faDownload, faToolbox, faTools, faBusinessTime } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as AmplifyLink } from "@aws-amplify/ui-react";

import { Routes } from "../routes";
import ThemesbergLogo from "../assets/img/themesberg.svg";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import ProfilePicture2 from "../assets/img/team/profile-picture-3.jpg";
import ProfilePicture from '../assets/img/undraw_female_avatar_efig.svg'
import { faDashcube, faYarn } from "@fortawesome/free-brands-svg-icons";

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { Routes as routes } from "../routes";
import { deleteEmailCache, getS3Json } from "src/auth/util";
import { isObject } from "src/utils";


export default forwardRef((props, ref) => {
  const {display, components} = props;
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    deleteEmailCache();
    signOut();
    window.open("https://mail.google.com/mail/u/0/?logout&hl=en");
    navigate(routes.Signin.path);
  }

  const location = useLocation();
  const { pathname: _pathname } = location;
  const [pathname, setPathname] = useState(_pathname);
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const [reports, setReports] = useState([]);

  useEffect(()=>{
    getS3Json('reports/all.json').then(_reports=>{
      if(isObject(_reports)){
        setReports(Object.keys(_reports).map(k=>({title: _reports[k].display_name, link: k})))
      }
    }).catch(e=>console.log(  `error fetching reports: ${e}`));
  }, []);

  const onCollapse = () => setShow(!show);

  useImperativeHandle(ref, () => ({
    async handle (){
      
    }
  }));

  const getCachedBool = (key, defaultValue=false) => {
    const response = localStorage.getItem(key);
    const saveDefault = () => { localStorage.setItem(key, defaultValue ? '1' : '0'); return defaultValue; }
    return response == '0' ? false : response === '1' ? true : saveDefault();
  }

  const setCachedBool = (key, value) => {
    localStorage.setItem(key, value ? '1' : '0')
  }

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const [defaultKey, setDefaultKey] = useState(getCachedBool(eventKey));  // pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    // console.log('default=', eventKey, defaultKey)
    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey ? eventKey : ""}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center"
          onClick={()=>{
            setCachedBool(eventKey, !defaultKey);setDefaultKey(!defaultKey);
          }}>
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, onClick, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "nav-link";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { to: link, component: AmplifyLink };
    const LinkComponent = external ? Nav.Link : Link;
    return (
      <Nav.Item className={navItemClassName} onClick={() => {
        setShow(false); if(props.onClick) {props.onClick();}}}>
        <div {...linkProps} target={target} className={classNames}
        style={{cursor: 'pointer'}}
        onClick={()=>{
          if(typeof onClick === 'function') return onClick();
          if(!external){
            if(components){
              if(link in components){
                if(display){
                  display(link);
                  setPathname(link);
                }else console.log('no display');
              }else console.log('link not in components', link);
            }else console.log('not componrnts');
          }else console.log('not external');
        }}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </div>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={props.img} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, {props.name}</h6>
                  <Button variant="secondary" size="xs" onClick={logOut} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Junglr" onClick={()=>{window.location.href='/';}} /* link={Routes.Presentation.path} */ icon={faTimes} />

              <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
              <NavItem title="New Dash" onClick={()=>{window.location.href = Routes.NewDashbord.path;}} link='/dash' icon={faToolbox} />
              {/* <NavItem external title="Messages" link="https://demo.themesberg.com/volt-pro-react/#/messages" target="_blank" badgeText="Pro" icon={faInbox} />
              <NavItem title="Transactions" icon={faHandHoldingUsd} link={Routes.Transactions.path} />
              <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} /> */}
              <CollapsableNavItem eventKey="reports/" title="My Reports" icon={faFileAlt}>
                <NavItem title="C. B. Report" icon={faFileAlt} link={Routes.Reports.path} />
                {reports.map(_props=><NavItem icon={faFileAlt} {..._props}/>)}
                {/* <NavItem title="S. T. Report" icon={faFileAlt} link={Routes.STR.path} /> */}
                {/* <NavItem title="Test Report" icon={faFileAlt} link={Routes.TestReport.path} /> */}
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="tools/" title="Tools" icon={faToolbox}>
                <NavItem title="Downloads" icon={faDownload} link={Routes.ToolDownlods.path} />
                <NavItem title="Year-to-Year Data" icon={faTools} link={Routes.Yoy.path} />
              </CollapsableNavItem> 
              {props.admin ? 
              <CollapsableNavItem eventKey="permissions/" title="Admin" icon={faUserAlt}>
                <NavItem title="User Permissions" icon={faUserAlt} link={Routes.AdminUserAccess.path} />
              </CollapsableNavItem> 
              : ''}
              {/* <NavItem external title="Calendar" link="https://demo.themesberg.com/volt-pro-react/#/calendar" target="_blank" badgeText="Pro" icon={faCalendarAlt} />
              <NavItem external title="Map" link="https://demo.themesberg.com/volt-pro-react/#/map" target="_blank" badgeText="Pro" icon={faMapPin} />

              <CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>
                <NavItem title="Bootstrap Table" link={Routes.BootstrapTables.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>
                <NavItem title="Sign In" link={Routes.Signin.path} />
                <NavItem title="Sign Up" link={Routes.Signup.path} />
                <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />
                <NavItem title="Reset password" link={Routes.ResetPassword.path} />
                <NavItem title="Lock" link={Routes.Lock.path} />
                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
              </CollapsableNavItem>

              <NavItem external title="Plugins" link="https://demo.themesberg.com/volt-pro-react/#/plugins/datatable" target="_blank" badgeText="Pro" icon={faChartPie} />

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>
                <NavItem title="Overview" link={Routes.DocsOverview.path} />
                <NavItem title="Download" link={Routes.DocsDownload.path} />
                <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />
                <NavItem title="License" link={Routes.DocsLicense.path} />
                <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />
                <NavItem title="Build Tools" link={Routes.DocsBuild.path} />
                <NavItem title="Changelog" link={Routes.DocsChangelog.path} />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>
                <NavItem title="Accordion" link={Routes.Accordions.path} />
                <NavItem title="Alerts" link={Routes.Alerts.path} />
                <NavItem title="Badges" link={Routes.Badges.path} />
                <NavItem external title="Widgets" link="https://demo.themesberg.com/volt-pro-react/#/components/widgets" target="_blank" badgeText="Pro" />
                <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
                <NavItem title="Buttons" link={Routes.Buttons.path} />
                <NavItem title="Forms" link={Routes.Forms.path} />
                <NavItem title="Modals" link={Routes.Modals.path} />
                <NavItem title="Navbars" link={Routes.Navbars.path} />
                <NavItem title="Navs" link={Routes.Navs.path} />
                <NavItem title="Pagination" link={Routes.Pagination.path} />
                <NavItem title="Popovers" link={Routes.Popovers.path} />
                <NavItem title="Progress" link={Routes.Progress.path} />
                <NavItem title="Tables" link={Routes.Tables.path} />
                <NavItem title="Tabs" link={Routes.Tabs.path} />
                <NavItem title="Toasts" link={Routes.Toasts.path} />
                <NavItem title="Tooltips" link={Routes.Tooltips.path} />
              </CollapsableNavItem>
              <NavItem external title="Themesberg" link="https://themesberg.com" target="_blank" image={ThemesbergLogo} />
              <Button as={Link} to={Routes.Upgrade.path} variant="secondary" className="upgrade-to-pro"><FontAwesomeIcon icon={faRocket} className="me-1" /> Upgrade to Pro</Button> */}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
});
