export class theme {
    static color1 = '#9DD3E1';//'#01bf71';
    static color2 = '#fff';
    static color3=  '#fff';
    static darkColor1 = '#010606';
    static darkColor2 = '#0c0c0c';
    static darkColor3 = '#232a34';
    static darkColor4 = '#000'
    static lightColor1 = '#f9f9f9';
    static lightColor2 = '#f7f8fa';
    static lightColor3 = '#fff';
};