




export const fecthJSON = async (url, obj) =>{
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'key': 'super secret key',
    },
    body: JSON.stringify(obj)
  })
  .then((response) => response.json())
  .then((data) => {
    if(typeof(data) == 'string'){
      var _data = JSON.parse(data);
    }else{var _data = data;}
    //captureData(_data);
    return _data;
  })
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const isObject = (val) => (typeof val === 'object' && !Array.isArray(val) && val !== null)

export function tryStringifyJSON(json, onFail=null, pretty=false){
  try{
      if(pretty) return JSON.stringify(json, undefined, 4);
      return JSON.stringify(json);
  }catch(e){}
  return onFail != null ? onFail(json) : json;
}


export function tryParseJSON(json, onFail=null){
  try{
      return JSON.parse(json);
  }catch(e){}
  return onFail != null ? onFail(json) : json;
}
