import {useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import { Api } from 'src/auth/APIHandler';
// import { Button } from '@themesberg/react-bootstrap';
import { Button as MuiButton, CircularProgress, Input } from "@mui/material";
import { Button } from '@themesberg/react-bootstrap';
import { isObject } from 'src/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';

export const RequireGoogleDriveLoggin = forwardRef((props, ref)=>{
    const {children, variant='primary'} = props;
    const loginKeys = {loggedIn: '', notLoggedIn: 'not-logged-in', loading: 'loading'};
    const [loggedIn, setLoggedIn] = useState(loginKeys.loading)

    const get = async() => {
        const logged_in = await isLoggedInToGoogleDrive();
        console.log('is logged in ', logged_in)
        setLoggedIn(logged_in ? 'logged-in' : 'not-logged-in')
    }
    const googleHandle = () => {
        console.log('gool hit');
        (async ()=>{
          let name = 'googlepermissions'; // 'test3'
          let endpoint = '/google-permissions';  // '/test3'
          let obj = await Api(name, endpoint, undefined, {uri: ''});
          if(obj.hasOwnProperty('redirect')){
            // https://www.w3schools.com/jsref/met_win_open.asp
            window.open(obj.redirect, '_blank', 'height=500,width=400');//,menubar=no,titlebar=no
          }
        })();
      }

    const setToNotLoggedIn = () => {
        setLoggedIn(loginKeys.notLoggedIn);
    }

    useImperativeHandle(ref, ()=>({
        logOut(){setToNotLoggedIn();}
    }), []);

    useEffect(()=>{
        get();
    }, [])
    const GoogleButton = props => {
        const {children, variant='primary'} = props;
    const containerSyle = {backgroundColor: variant == 'primary' ? 'white' : '#262B40', width: '95%', height: '95%', padding: 20, margin: 20, borderRadius: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}
    const textStyle = {color: variant == 'primary' ? '#262B40' : 'white'}
    const buttonStyle = {backgroundColor: variant == 'primary' ? '#262B40' : 'white', color: variant == 'primary' ? 'white' : '#262B40'};
    
    return <div style={containerSyle}>
        <p style={textStyle}>please</p>
        <Button style={buttonStyle} onClick={googleHandle}> <FontAwesomeIcon icon={faFolder} /> Sign In to Google Drive </Button> 
        <div style={{marginTop: 30}}><p style={{...textStyle, fontSize: 12}}> (if you just did, maybe refresh the page or try it a second time) </p></div>
    </div>}
    return <>
        {loggedIn === loginKeys.loading ? <CircularProgress size={20}/> : 
        loggedIn === loginKeys.notLoggedIn ? <GoogleButton variant={variant}/> : 
        children
        }
    </>
})

export const isLoggedInToGoogleDrive = async() => {
    let v = await Api('googleapi', '/googleapi', undefined, {api: 'logged_in'});
    console.log('logged in', v);
    if(isObject(v)) {
        if(isObject(v.logged_in)) {
            return !!v.logged_in.result;
        }
    }
    return false;
}

export const getGoogleDrives = async(onFail=()=>{}) => {
    console.log('fetching drives');
    let v = await Api('googleapi', '/googleapi', undefined, {api: 'folder_heirarchy', folder_id: 'base', drive_id: ''});
    console.log('fetched drives', v);
    return v;
}

export const getGoogleChildrenFolder = async(drvie_id, folder_id) => {
    console.log('entering', drvie_id, folder_id)
    let v = await Api('googleapi', '/googleapi', undefined, {api: 'folder_heirarchy', folder_id: folder_id, drive_id: drvie_id});
    console.log('drive folders', v)
    return v;
}