import React, {useState, useEffect, useRef, forwardRef, useCallback, useImperativeHandle} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faInfo, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, Form, InputGroup, Modal, OverlayTrigger } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";
import { GeneralInfoForm } from "../../components/Forms";
import axios from "axios";
import Progress from "../../components/Progress";

import Profile3 from "../../assets/img/team/profile-picture-3.jpg";

import { ReportTable, TransactionsTable } from "../../components/Tables";
import Table from '../STR/STRTable';
//import { pageReport } from "../../data/tables";

import { faFileAlt as faFile } from "@fortawesome/free-solid-svg-icons";
import { Box, Checkbox, CircularProgress, FormControlLabel, LinearProgress, Tooltip } from "@mui/material";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import SavedCheck from "../../components/Custom/SavedCheckbox";
import { ToastServerMessage } from "../Custom/GetMessage";
import { Space } from "../STR/STRFormElements";
import { makeTextFile, print, timeout, fecthJSON, files_seporator } from "../../components/utilities";
import { grey, orange } from "@mui/material/colors";
import styled from "styled-components";
import { Api } from "../../auth/APIHandler";
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { UPLOAD_FOLDER, getEmail, savedIindex } from "../../auth/util";
import { Storage } from "aws-amplify";
import { submitJob, uploadS3UUID } from "../../documents/submit";



const pageReport = [
  { id: 1,  icon: faFileAlt, icon2: faFileAlt, status: 76, name: '', link: '#', link2: 'false' },
  { id: 2, icon: faFileAlt, icon2: faFileAlt, status: 555, name: '', link: '#/settings', link2: '#/settings' },
];

  const MyTextDisplay = forwardRef((props, ref) => {
    const [text, setText] = useState("");
    const onChangeText = useCallback(txt=> { setText(txt); }, [text]);
    const _displayText = async(_text) => {
      var s = ''
      for (let i = 0; i < _text.length; i++) {
        await timeout(10);
        const element = _text[i];
        s += element;
        setText(s);
      }
      setText(_text);
      // await timeout(2000);
      // while (s.length > 0) {
      //   s = s.substring(0, s.length - 1);
      //   setText(s);
      //   await timeout(5);
      // }
      
      // setText("");
    }
    const _removeText = async() => {
      var s = text
      while (s.length > 0) {
        s = s.substring(0, s.length - 1);
        setText(s);
        await timeout(5);
      }
      setText("");
    }
    useImperativeHandle(ref, () => ({
  
      async displayText (txt){
        await _displayText(txt);
      },
      async removeText(){
        await _removeText();
      }
  
    }));
    return (
      <p> &nbsp; {text} &nbsp; </p>
    );
  });

/* function SavedCheck(props){
  const name = 'cbreport'
  const [checked, setThisChecked] = useState(true);
  const updateTable = async() => {
    await fetch('/api/saved-check/'+name)
    .then((response) => response.json())
    .then((data) => {
      props.setChecked(data.checked);
      setThisChecked(data.checked);
    })
  };
  useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
    updateTable();
  }, []);
  return (<>
            <Form>
              <Form.Check label={props.title} id="checkbox1" htmlFor="checkbox1" 
              defaultChecked={checked}
              checked={checked}
              onChange={(e) => {
                setThisChecked(e.target.checked);
                props.setChecked(e.target.checked);
                fetch('/api/set-check/'+name, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: JSON.stringify({ checked: e.target.checked })
                });
                }}/>
            </Form>
          </>);
} */

export const uploadFile = async (file, use_id_in_name=false, headers={"Content-Type": "multipart/form-data"},
onUploadProgress=(e) => {}, 
onDownloadProgress=(e) => {}) => {
  function get_download_location(name){ return [UPLOAD_FOLDER, getEmail(), name].join('/') }
  
  const uid = uuidv4(); 
  let fileName = (use_id_in_name ? uid : '') + file.name;
  const path = get_download_location(fileName)
  Storage.put(path, file, {progressCallback: onUploadProgress});
  return {'id': uid, 'fileName': fileName};
  if(file == null) return;
  uid = '';
  await fecthJSON('/unique-id')
  .then(
    data => {
      uid = data.i
    }
  ) 
  // https://www.stechguide.com/how-to-upload-large-files-as-chunks-using-reactjs/
   let chunkSize = .2 * 1024 * 1024;  // .2mb  // old -> 5 * 1024 * 1024; // 5 MB chunk size
   let chunks = [];
   let fileSize = file.size;
   let start = 0;
   let end = chunkSize;
   fileName = (use_id_in_name ? uid : '') + file.name;
  
   while (start < fileSize) {
     chunks.push(file.slice(start, end));
     start = end;
     end = start + chunkSize;
   }
   let uploadPromises = [];
  //  for await (const results of array) {
  //   await longRunningTask()
  // }
   if(chunks.length > 1){
    let len = chunks.length
    let total = len
    let _headers = { ...headers, 'uid': uid, 'i': '0', 'len': String(len) }
    let formData = new FormData();
    formData.append(fileName, chunks.shift());
    let index = 0
    print(``)
    const f = e => {onUploadProgress({loaded: e.loaded * ((index+1.0) / total), total: e.total});
    print(`${e.loaded * ((index+1.0) / total)} -> ${e.loaded} * ${((index+1.0) / total)} -> ${e.loaded} * ((${index}+1.0) / ${total})`)}
    await axios.post('/upload-file', formData, { headers: _headers,
      onUploadProgress: f } );
    _headers = { ...headers, 'uid': uid, 'i': String(len-1), 'len': String(len) }
    formData = new FormData();
    formData.append(fileName, chunks.pop());
    index = 1
    for await (const chunk of chunks) {
      let _formData = new FormData();
      _formData.append(fileName, chunk);
      let __headers = { ...headers,
        'uid': uid, 'i': String(index), 'len': len
      }
      await axios.post('/upload-file', _formData, { headers: __headers,
      onUploadProgress: f });
      index++;
    }
    // chunks.forEach(async(chunk, index) => {
    //   let _formData = new FormData();
    //   _formData.append(fileName, chunk);
    //   let _headers = {
    //       'uid': uid, 'i': String(index + 1), 'len': len
    //     }

    //   let uploadPromise  = await axios.post('/upload-file', _formData, { headers: _headers });
    //   uploadPromises.push(uploadPromise);
    // });
    // await Promise.all(uploadPromises)
    //   .then(responses => {
    //     console.log('All chunks uploaded successfully!');
    //   })
    //   .catch(error => {
    //     console.error('Error uploading chunks:', error);
    //   });
      await axios.post('/upload-file', formData, { headers: _headers,
        onUploadProgress: f,
        onDownloadProgress: onDownloadProgress });
    }else {
      let _headers = { ...headers, 'uid': uid }
      let formData = new FormData();
      formData.append(fileName, chunks.pop());
      await axios.post('/upload-file', formData, { headers: _headers,
        onUploadProgress: onUploadProgress,
        onDownloadProgress: onDownloadProgress });
    }
    print(' done sending ')
    return {'id': uid, 'fileName': fileName};
}

function TestStreamUpload(){
  const [file, setFile] = useState(null);
  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

  
  return (
    <>
      <Form>
        <Form.Label>Test</Form.Label>
        <Form.Control type="file" onChange={e=>setFile(e.target.files[0])} />
      </Form>
      <Button onClick={async()=>{await uploadFile(file, true)}}> send file </Button>
    </>
  );
}


function HelpInfo(props){
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  return (<>
    <React.Fragment>
      <Button size="sm" href="#gray" bsPrefix="text" variant="primary" className="m-3" onClick={() => setShowDefault(true)}>
        
        <p><FontAwesomeIcon icon={faInfo} /> click for help </p>
      </Button>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Info</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
        <><p> Use: </p>
              <p> To submit a report input a BusinessReport.csv and Bulk.xlsx file and click submit. </p>
              <p> You may enter a custom name in the Report Name field </p>
              <p> To use with Google Sheets click the {<FontAwesomeIcon icon={faGoogle} />} to download a xlsx file. </p>
              <p>To use with Excel click the {<FontAwesomeIcon icon={faMicrosoft} />} to download an xlsm file. For Excel please enable macros to clean the Breakdown and data Sheet. If you are not able to enable macros please replace all '"='' with '='' and all '@' with '' (nothing) for Sheets "Breakdown" and "data" (Excel should have a find and replace feature on the top right of the {'"Home"'} tab)</p></>
              <p> the "Toggle Information" checkbox adds information tags to some items (currently only the download icons)</p>
              <p></p>
              <p> Side note. entering {"'{i}'"} into the Report Name will add an index for that specific name. {"(this was for testing but could  be helpful)"}</p>
         {/*  <p>With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.</p>
          <p>The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.</p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gray" onClick={handleClose}>
            Ok
        </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  </>);
}

const MyDivv = styled.div`
  width: fit-content;  // height: fit-content; 
  padding-left: 15px; padding-right: 15px;
  //overflow: auto;
  background-color: #262B40;//${grey[500]} !important;
  border-radius: 7px;

  color: gray !important;
  text-align: center;
`
const MyPP = styled.p`
  color: #fff;
  font-weight: 800;
`

const SelectSheets = forwardRef((props, ref) => {
  const [message, setMessage] = useState('');
  const onChangeMessage = useCallback((v) => { setMessage(v); }, [message]);
  const [showDefault, setShowDefault] = useState(false);
  const onChangeShowDefault = useCallback((v) => { if(!v){onChangeMessage('');}
    setShowDefault(v); }, [showDefault]);

  useImperativeHandle(ref, () => ({
    stillOpen (){
      return showDefault;
    },
    open(msg=null){
      if(typeof(msg) == 'string'){ onChangeMessage(msg); }
      onChangeShowDefault(true);
    },
    getValues(turn_all_on=true){
      let val = Object.assign({}, checked);  // structuredClone(checked);  // copy
      if(turn_all_on) {updateChecks('__on__');}
      return val;
    }
  }));

  const handleClose = () => {onChangeShowDefault(false);}

  const sheets = {
    "Sponsored Products Campaigns": true, 
    "Sponsored Brands Campaigns": true, 
    "Sponsored Display Campaigns": true,
    'SP Table': true,
    'SB Table': true,
    'SD Table': true
  };


  let _checks = [];
    for (var k in sheets) {
      if (sheets.hasOwnProperty(k)) {
        _checks.push(sheets[k]);
      }
    }
  

  const [checked, setChecked] = React.useState(sheets);
  const onChangeChecked = useCallback((v) => { setChecked(v); }, [checked]);
  const updateChecks = (key, i) => {
    let checks = checked;
    let allEqual = Object.keys(checks).filter(function(k){return checks[k]}).length == Object.keys(checks).length;
    for (var k in checks) {
      if (checks.hasOwnProperty(k)) {
        if(key == '__off__'){
          checks[k] = false;
        }
        else if(key == '__on__'){
          checks[k] = true;
        }
        else if(key == '__all__'){
          checks[k] = !allEqual;
        }
        else if(key == k || key == '__all__'){
          // checks[index] = !checks[index]
          checks[k] = !checks[k];
          print(key, k, i, checks[k])
        }
        // checks.push(sheets[k]);
      }
    }
    onChangeChecked({...checks});
  }
  
  const handleChange1 = (event) => {
    updateChecks('__all__')
  };

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {Object.keys(sheets).map((k, i)=>{
        return <FormControlLabel
        label={k + (checked[k] ? ' (remove)' : '')}
        control={<Checkbox
        sx={{
          color: orange[600],//orange[800],
          '&.Mui-checked': {
            color: orange[600],
          },
        }}
          checked={checked[k]} onChange={()=>{updateChecks(k, i)}} />}
      />
      })}
    </Box>
  );
      
  return (<>
    <React.Fragment>
    
      <Button size="sm" href="#gray" bsPrefix="text" variant="primary" className="m-3" onClick={() => onChangeShowDefault(true)}>
        
      <MyDivv className='Nun'> <MyPP>{/* <FontAwesomeIcon icon={faInfo} />  */}Manage sheets 
      </MyPP></MyDivv>
      </Button>
      

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Info</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
        <><p> {message} </p>
              <p> Removing unnecessary sheets can help file size and slightly with process time </p>
              <FormControlLabel
                label="All (remove)"
                control={
                  <Checkbox color='default'
                  sx={{
                    color: 'default',//orange[800],
                    '&.Mui-checked': {
                      color: orange[600],
                    },
                  }}
                    checked={Object.keys(checked).filter(function(k){return checked[k]}).length == Object.keys(checked).length}
                    indeterminate={Object.keys(checked).filter(function(k){return checked[k]}).length != Object.keys(checked).length && 
                    Object.keys(checked).filter(function(k){return checked[k]}).length != 0}
                    onChange={handleChange1}
                  />
                }
              />{children}
        </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gray" onClick={handleClose}>
            Ok
        </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  </>);
});


// forwardRef((props, ref) => {
//   const [_barProgress, set_BarProgress] = useState(0.0);
//   const onChangeInput = useCallback(
//     (v) => {
//       set_BarProgress(v);
//     },
//     [_barProgress]
//   );
//   const [text, setText] = useState("");
//   const changeText = useCallback(v=>{ setText(v); }, [text]);
//   const doClick = () => {
   
//   }
//   const reset_Progress = async() => {
//     await timeout(2000);
//     while (_barProgress > 2) {
//       onChangeInput(_barProgress - 5); //set_BarProgress(_barProgress - 5);
//       await timeout(5);
//     }
//     onChangeInput(0); //set_BarProgress(0);
//   }
//   useImperativeHandle(ref, () => ({
    
//     async submitForm (url, files, _menuValue){
//       const files_seporator = '[(----)]';
//       let n1 = 1.0; let n2 = 1.0;
//       const f1 = (progressEvent) => { const myprogress = Math.ceil((((progressEvent.loaded / progressEvent.total) * 95.) * n1) + n2); onChangeInput(myprogress); }
//       const f2 = (progressEvent) => { const myprogress = Math.ceil(((95. + (progressEvent.loaded / progressEvent.total) * 5.) * n1) + n2); onChangeInput(myprogress); }
//       let fileNames = [];
//       let wait = true;
//       let i = 1;
//       for (const file of files) {
//         n1 = i / files.length; n2 = (i - 1) / files.length;
//         changeText(`uploading file - ${i} of ${files.length} - ${file.name}`);
//         i++;
//         wait = true;
//         await uploadFile(file, true, ...new Array(1), f1, f2)
//         .then(info=>{fileNames.push(info.fileName)
//         wait = false}).catch(wait = false)
//         // while(wait){}
//       }
//       changeText(`Requesting report..`);
//       axios.post(url, new FormData(), {
//         headers: {
//           "Content-Type": "multipart/form-data", // declaring is FormData
//           "cbreport": String(_menuValue),
//           "myfiles": fileNames.join(files_seporator),
//           "fileIDs": files.map(f=>f.name).join(files_seporator)
//         },
//         onUploadProgress: (progressEvent) => {
//           const myprogress = Math.ceil((progressEvent.loaded / progressEvent.total) * 95);
//           onChangeInput(myprogress); //set_BarProgress(myprogress);
//         },
//         onDownloadProgress: (progressEvent) => {
//           const myprogress = Math.ceil(95 + (progressEvent.loaded / progressEvent.total) * 5);
//           onChangeInput(myprogress); //set_BarProgress(myprogress);
//         },
//       }).then(async()=>{
//         reset_Progress();
//         changeText(`done`);
//         await timeout(2000);
//         changeText('');
//       })
//       .catch((error)=>{reset_Progress(); console.log(error);});
//     }

//   }));
// return (<><p> {text} </p>
//   <LinearProgress 
//   style={ 	  { 		color: '#white', backgroundColor: "transparent",		/* borderRadius: '50%', */ 		/* boxShadow: 'inset 0 0 0 5.5px white'  */	  } 	} 
//   variant="determinate"  value={_barProgress} color="inherit" />
// </>);
// });

export default () => {
  const textRef = useRef(0);
  const sheetsRef = useRef(1);
  const [tableData, setTableData] = useState(pageReport);
  const [lastTableData, setLastTableData] = useState(pageReport);
  const [bulkFile, setBulkFiles] = useState(null);
  const [reportFile, setReportFiles] = useState(null);
  const [bulkInput_ref, setBulkInputRef] = useState("");
  const [reportInput_ref, setReportInputRef] = useState("");
  const [msgText, setMSGText] = useState("");
  const [newNameText, setnewNameText] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [barProgress, setProgress] = useState(0);

  const [checked, setChecked] = useState(true);
  const waitUpdateTable = async() => {
    await timeout(5000); //for 10 sec delay
    updateTable();
  };
  const checkTable = (_data) => {
    try{
      if(_data.length != lastTableData.length){ return true; }
      for (let i = 0; i < _data.length; i++) {
        const element = _data[i];
        const element2 = lastTableData[i];
        if( element.id != element2.id || element.status != element2.status || element.name != element2.name || element.link != element2.link || element.link2 != element2.link2)
        { return true; }
      }
    }catch(err) {console.log('err -*-', err); return true;}
    return false;
  }
  const updateTable = async() => {
    console.log('table.........')
    const actData = (data) => {
      console.log('data received')
      console.log(data)
      if(typeof(data) == 'string'){
        var _data = JSON.parse(data);
      }else{var _data = data;}
      if(checkTable(_data.tableData)){
        try{
        
        console.log(_data);
        var new_data = [];
        for (let i = 0; i < _data.tableData.length; i++) {
          const el = _data.tableData[i];
          new_data.push({ id: el.id,  icon: el.link=='false', icon2: el.link2=='false', status: el.status, name: el.name, link: el.link, link2: el.link2 },)
        }
        }catch(err){console.log('err -*-', err); var new_data = [{id: 1,  icon: true, icon2: true, status: '<-- error', name: String(err), link: '/', link2: '/'}]}
        setTableData(new_data);//_data.tableData);
        setLastTableData(new_data);//_data.tableData);
      }
      else{console.log('table data nope')}
    }
    let _url = 'https://4ccyzktpqantb3mucdxvhq4rm40ynvhc.lambda-url.us-east-1.on.aws/'//'https://99m2j026qa.execute-api.us-east-1.amazonaws.com';//'https://4ccyzktpqantb3mucdxvhq4rm40ynvhc.lambda-url.us-east-1.on.aws';
    let key = 'supersecretpassword';  //user_info.key
    let heads = {
      'key': key,
      'email': 'danielwillolson@gmail.com',
      /* 'Content-Type': 'application/json', 
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      "Access-Control-Allow-Headers": "*",//"Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, key, Access-Control-Allow-Origin",
     */}
    const tryjson = v => {try{return JSON.stringify(v)}catch(e){}return ''}
    try {
      /* const user_info = await (
        await fetch('/sapi/userinfo')
      ).json();
      console.log(user_info.userData);
      heads.key = user_info.userData.key;
      heads.email = user_info.userData.email; */
      let response = await Api('cbreportdata', '/cbreportdata')//fetch(_url, {headers: heads, });

      console.log('cbreportdata', response); 
      try{console.log(JSON.stringify(response))}catch(e){console.log(e)}

      response = await response.json();
      actData(response);
    }catch(eee){console.log(eee)}
    
    /* await axios({method: 'get', url: _url, headers: heads, 
    //data: JSON.stringify({'email': 'danielwillolson@gmail.com', 'key': key})
  })
    .then(r => {console.log('res', r); try{console.log('res j: ', JSON.stringify(r))}catch(er){console.log('my er: ', er)}
      return r.data}).then(actData)
    .catch((error)=>{console.log('error -***- ', error);}); */
    /* try {
      const response = await (
        await fetch(_url)
      ).json();
      actData(response);
    }catch(err) {
      await fetch(_url,  // '/finalreportdata', 
      { method: 'POST', redirect: 'follow' })
      .then((response) => {console.log('response: ', response);try{console.log('j response: ', JSON.stringify(response));}catch(err){console.log('r err: ', err)}
        try{return response.json()}
        catch(err){
          (async () => { 
            let r = await response.json();
            actData(r);
          })();
        }
      })
      .then(actData)
      .catch(err=>{console.log('errrr: ', err)})
    }*/
  }; 
  
  const tableLoop = async() => {
    while(true){
      await updateTable();
      await timeout(5000); //for 10 sec delay
    }
  }
  useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
    var test = true
    // Update the document title using the browser API
    if (test) {
      test = false
      //tableLoop();
      //updateTable();
      
    }
    setProgress(0);
  }, []);

  const displayText = async(text) => {
    var s = ''
    for (let i = 0; i < text.length; i++) {
      await timeout(10);
      const element = text[i];
      s += element;
      setMSGText(s);
    }
    setMSGText(text);
    await timeout(2000);
    while (s.length > 0) {
      s = s.substring(0, s.length - 1);
      setMSGText(s);
      await timeout(5);
    }
    
    setMSGText("");
  }
  const resetProgress = async() => {
    await timeout(2000);
    while (barProgress > 2) {
      setProgress(barProgress - 5);
      await timeout(5);
    }
    setProgress(0);
  }
  const click = async() => {
    
    // var some_files = [bulkFile, reportFile]
    // const formData = new FormData()
    // formData.append('test', 'this is a  total test')
    // for (let index = 0; index < some_files.length; index++) {
    //   const element = some_files[index]
    //   if (!element) {
    //     return
    //   }
    //   formData.append('File' + String(index + 1), element)
    // }
     (async ()=>{
      let vals = sheetsRef.current.getValues(false);
      if(bulkFile.size > 1024**2 * 15 && vals.length-1 > Object.entries(vals).reduce((a,b)=>a+b, 0)){
        sheetsRef.current.open('Your file\'s are over 15 MB. You may want to consider removing unnecessary sheets to reduce the size so that it can fit in Google\'s SpreadSheets. "Sponsored Products Campaigns", "Sponsored Brands Campaigns", and "Sponsored Display Campaigns" are recommended');
        await timeout(5000);
        while(sheetsRef.current.stillOpen()) await timeout(1000)
      }
      return sheetsRef.current.getValues();
    })().then(async (obj)=>{ //console.log(Object.keys(obj).filter(k=>obj[k]).join('(--)'))
    let bulk = renameFile(bulkFile, "bulk.xlsx")
    var _name = reportFile.name
    var ext = _name[_name.length-3]+_name[_name.length-2]+_name[_name.length-1]
    _name = ext.toLowerCase()==="csv" ? "businessreport.csv" : "businessreport.xlsx";
    let report = renameFile(reportFile, _name)
    let newName = newNameText;
    if(newName.includes('{i}') || newName.includes('[i]')){
      let ind = await savedIindex(newName.replace('[i]', '').replace('{i}', ''))
      newName = newName.replace('[i]', String(ind)).replace('{i}', String(ind))
    }
    let f11 = (progressEvent) => { const myprogress = Math.ceil((progressEvent.loaded / progressEvent.total) * 50.0 * 1. + 0.); setProgress(myprogress); }
    let f22 = (progressEvent) => { const myprogress = Math.ceil(((progressEvent.loaded / progressEvent.total) * 50.0) * 1. + 50.); setProgress(myprogress); }
    let r = await submitJob('cbreport', newName, 
      [
        {id: 'bulk_location', name: await uploadS3UUID(bulk, undefined, f11)},
        {id: 'report_location', name: await uploadS3UUID(report, undefined, f22)},
      ],
      {'drop': Object.keys(obj).filter(k=>obj[k])}//obj
      )
    console.log(r);
    resetProgress();

    return
      setBulkInputRef("");
    setReportInputRef("");
    let bulkFileName = '';
    let reportFileName = '';
    let n = 0.5;
    let n1 = 0.0
    let f1 = (progressEvent) => { const myprogress = Math.ceil((progressEvent.loaded / progressEvent.total) * 95.0 * n + n1); setProgress(myprogress); }
    let f2 = (progressEvent) => { const myprogress = Math.ceil((95.0 + (progressEvent.loaded / progressEvent.total) * 5.0) * n + n1); setProgress(myprogress); }
    let h = { "Content-Type": "multipart/form-data", }
    textRef.current.displayText('Sending bulk file');
    const removeThenAdd = async (txt) => {await textRef.current.removeText().then(async()=>{ await textRef.current.displayText(txt);})}
    
    await uploadFile(renameFile(bulkFile, "bulk.xlsx"), true, {...h, 'og_name': bulkFile.name}, f1, f2 )
    .then( info=>{  // {'id': uid, 'fileName': fileName}
      bulkFileName = info.fileName;
    })
    removeThenAdd('Sending report file')
    n = 1.0; n1 = 0.5;
    f1 = (progressEvent) => { const myprogress = Math.ceil((progressEvent.loaded / progressEvent.total) * 95.0 * n + n1); setProgress(myprogress); }
    f2 = (progressEvent) => { const myprogress = Math.ceil((95.0 + (progressEvent.loaded / progressEvent.total) * 5.0) * n + n1); setProgress(myprogress); }
    var _name = reportFile.name
    var ext = _name[_name.length-3]+_name[_name.length-2]+_name[_name.length-1]
    _name = ext.toLowerCase()==="csv" ? "businessreport.csv" : "businessreport.xlsx";
    await uploadFile(renameFile(reportFile, _name), 
    true, {...h, 'og_name': reportFile.name}, f1, f2 )
    .then( info=>{
      reportFileName = info.fileName;
    })
    //displayText('Your files are being sent.');
    //displayText(" sent.")
    //const promise = new Promise((resolve, reject) => {
      //resolve(
        
        removeThenAdd('Requesting report');
        const formData = new FormData()
        formData.append('drop', makeTextFile('drop', Object.keys(obj).filter(k=>obj[k]).join(files_seporator)));//new File([new Blob(['testing, testing 1,2,3'], { type: 'text/plain' })], ['test.txt']))
        var url = 'https://tzhqatc6ujr2vn4clcdwylgnjq0ekzfj.lambda-url.us-east-1.on.aws'
        url = url + '/cbreport'+(newNameText=='' ? '' : ('/'+ newNameText));
        let headers = {
          "Content-Type": "multipart/form-data",
          'bulk': bulkFileName, 
          'report': reportFileName,
        };

        axios({method: 'post', url: url, data: formData, headers: headers
        /* axios.post(url, formData, {
          headers: headers, */


          // onUploadProgress: (progressEvent) => {
          //   const myprogress = Math.ceil((progressEvent.loaded / progressEvent.total) * 95);
          //   setProgress(myprogress);
          // },
          // onDownloadProgress: (progressEvent) => {
          //   const myprogress = Math.ceil(95 + (progressEvent.loaded / progressEvent.total) * 5);
          //   setProgress(myprogress);
          // },
        }).then(()=>{waitUpdateTable();
          resetProgress();
          textRef.current.removeText()
        })
        .catch((error)=>{waitUpdateTable();
          resetProgress(); console.log('error -*- ', error);
          textRef.current.removeText()
        });
      })
        /* fetch(
          '/cbreport', //?key=somekey',//'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
          {
            method: 'POST',
            body: formData, //new FormData(objectToFormData({ File: selectedFile})),
            Authorization: 'sometoken', //Bearer
          },
        ) */
          /* .then(
            //response => setLoaded(false,
            (response) => {
              if (response.redirected) {
                window.location.href = response.url
              }
            },

            //)
          )
          .then() //(response)=>{if(response.redirected){ setLoaded(false);window.location.href = response.url; }})
          .catch(function (e) {
            new Notification(
              'Looks like there was an error processing your requests. It has been logged.',
            )
            fetch('/error_report', { method: 'POST', body: String(e), Authorization: 'sometoken' })
          }) */
      //)
    //})
    /* trackPromise(promise) */
  }

  const renameFile = (originalFile, newName) => {
      return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
      });
  }
  const bulkChangeHandler = (event) => {
    setBulkFiles(renameFile(event.target.files[0], "bulk.xlsx"))
  }

  const reportChangeHandler = (event) => {
    if(event.target.files.length > 0){
      var _name = event.target.files[0].name
      var ext = _name[_name.length-3]+_name[_name.length-2]+_name[_name.length-1]
      setReportFiles(renameFile(event.target.files[0], ext.toLowerCase() =="csv" ? "businessreport.csv" : "businessreport.xlsx"));
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      click();
    }
  }
  
  return (
    <>
      <Row> <Col><ToastServerMessage type="cbreport"/>
      <h1> Campaign Business Report &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 

        <HelpInfo/> </h1></Col> </Row>
        
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">

      <Row>
      <Col xs={12} xl={18}>
        <Form>
          <Form.Label>Bulk file (.xlsx) {/* { msgText } */}</Form.Label>
          <Form.Control type="file" onChange={bulkChangeHandler} key={bulkInput_ref || '' } /* ref={(ref) => { setBulkInputRef(ref) }} */ />
        </Form>
        <p></p>
        <Form>
          <Form.Label>Business Report (.csv) {/* { msgText } */}</Form.Label>
          <Form.Control type="file"  onChange={reportChangeHandler} key={reportInput_ref || '' } /* ref={(ref) => { setReportInputRef(ref) }} *//>
        </Form>
        <p></p>
        <SelectSheets ref={sheetsRef}/>
        <div>
          <CircularProgress variant="determinate"  value={barProgress} color="inherit" />
          <div style={{ position: 'static'}}>
            <MyTextDisplay 
          ref={textRef}/>
          </div>
          </div>
          <p></p>
        <Row>
          <Col xs={12} xl={18}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Report Name { msgText }</Form.Label>
              <InputGroup onChange={e => setnewNameText(e.target.value)}>
                <Form.Control type="text" placeholder="Enter Report Name..." 
                maxlength="100"
                onKeyPress={handleKeyDown}
                />
                <InputGroup.Text><FontAwesomeIcon icon={faFileAlt} /></InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Form>
          
          <Button onClick={()=>{click()}}>Submit</Button>
          </Col>
          <Col>
            {/*  <Progress variant="dark" label="Upload Progress" size="md" value={barProgress} /> */}
           
            {/* <p> { msgText } </p> */}
          </Col>
          
        </Row>
         {/*  <GeneralInfoForm /> */}
        </Col>

        <div className="d-flex">
        </div>
        </Row>
      </div>
      <Row><Col><h1>&nbsp;</h1></Col></Row>
      <Row><Col><h1>&nbsp;</h1></Col></Row>
      <Row>
        <Col>
          {/* <Table checkURL='cbreport' tableURL='/finalreportdata' process_id='cbreport'/> */}
          {/* <SavedCheck title="Toggle Information" setChecked={setChecked} name='cbreport'/>
          <ReportTable items={tableData} checked={checked} /> */}
        </Col>
      </Row>
    </>
  );
};

