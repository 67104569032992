import React, {useState} from 'react'
import HeroSection from '../../components/Custom/HeroSection'
import InfoSection from '../../components/Custom/InfoSection'
import Navbar from '../../components/Custom/NavBar'
import Sidebar from '../../components/Custom/Sidebar'
import {homeObjOne, homeObjTwo, homeObjThree} from '../../components/Custom/InfoSection/Data.js'
import Services from '../../components/Custom/Services'
import Footer from '../../components/Custom/Footer'
//import Autho from '../../config/autho'

const Home = () => {
    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <>
        <Sidebar isOpen = {isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        {/* <Autho/> */}
        <HeroSection />
        <InfoSection {...homeObjOne}/>
        <InfoSection {...homeObjTwo}/>
        <Services/>
        <InfoSection {...homeObjThree}/>
        <Footer/>
    </>
  )
}

export default Home