import React, {useEffect, useState} from 'react';
import Cookies from 'universal-cookie';

import { Auth } from 'aws-amplify';
import btn from '../../../_test/web/2x/btn_google_signin_light_normal_web@2x.png';
//import GoogleSignIn from '../../../_test/GoogleSignIn';
import { Image } from '@themesberg/react-bootstrap';

import AWSGoogleSignIn from '../../../_test/GoogleSignIn';

const GoogleBigger = ({theme = 0}) => {
  return (<>
              <>
                <div id="g_id_onload"
                  data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
                  data-context="signin"
                  data-ux_mode="popup"
                  data-login_uri="/login/callback"
                  data-nonce=""
                  data-auto_prompt="false">
                </div>

                <div class="g_id_signin"
                  data-type="standard"
                  data-shape="rectangular"
                  data-theme={theme==0?"outline":theme==1?"filled_black":"filled_blue"}
                  data-text="signin"
                  data-size="large"
                  data-logo_alignment="left">
                </div>
              </>
  </>);
};
const GoogleSmaller = ({theme = 0}) => {
  return (<>
              <>
                <div id="g_id_onload"
                data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
                data-context="signin"
                data-ux_mode="popup"
                data-login_uri="/login/callback"
                data-nonce=""
                data-auto_prompt="false">
                </div>

                <div class="g_id_signin"
                data-type="icon"
                data-shape="square"
                data-theme={theme==0?"outline":theme==1?"filled_black":"filled_blue"}
                data-text="signin"
                data-size="large">
                </div>
              </>
  </>);
};
const GoogleOneTap = () => {
  return (<>
            <div id="g_id_onload"
              data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
              data-context="signin"
              data-login_uri="/login/callback/auto"
              data-auto_select="false"
              data-itp_support="true"
              data-skip_prompt_cookie="logged-in"
              data-nonce="biaqbm70g23">
            </div>
          </>);
};


export const GoogleSignIn = ({opt = 0, theme = 0}) => {
    return (<AWSGoogleSignIn/>)
    /* const [option, setOption] = useState(1);
    //const [isAllowed, setIsAllowed] = useState(false);
    useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
      //setIsAllowed(true);
      setOption(opt);
      fetch('/im/allowed')
      .then((response) => response.json())
      .then((data) => {
        if(data.allowed=='true'){
          //setIsAllowed(true);
          const cookies = new Cookies();
          cookies.set('logged-in', 'true');
        }});
    }, []);
    return (<>
    { option==0 ?
                <>
                  <GoogleBigger theme={theme}/>
                </>
  : option==1 ? 
                <>
                  <GoogleSmaller theme={theme}/>
                </>
  : option==2 ? 
                <>
                  <GoogleOneTap/>
                </>
  : option==3 ? 
                <>
                  <GoogleOneTap/>

                  <GoogleBigger theme={theme}/>
                </>
  :
                <>
                  <GoogleOneTap/>

                  <GoogleSmaller theme={theme}/>
                </>
  } 
    </>); */
  };

  /* { option==0 ?
                <>
                  <div id="g_id_onload"
                    data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
                    data-context="signin"
                    data-ux_mode="popup"
                    data-login_uri="/login/callback"
                    data-nonce=""
                    data-auto_prompt="false">
                  </div>
  
                  <div class="g_id_signin"
                    data-type="standard"
                    data-shape="rectangular"
                    data-theme={theme==0?"outline":theme==1?"filled_black":"filled_blue"}
                    data-text="signin"
                    data-size="large"
                    data-logo_alignment="left">
                  </div>
                </>
  : option==1 ? 
                <>
                  <div id="g_id_onload"
                  data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
                  data-context="signin"
                  data-ux_mode="popup"
                  data-login_uri="/login/callback"
                  data-nonce=""
                  data-auto_prompt="false">
                  </div>
  
                  <div class="g_id_signin"
                  data-type="icon"
                  data-shape="square"
                  data-theme={theme==0?"outline":theme==1?"filled_black":"filled_blue"}
                  data-text="signin"
                  data-size="large">
                  </div>
                </>
  : option==2 ? 
                <>
                  <div id="g_id_onload"
                    data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
                    data-context="signin"
                    data-login_uri="/login/callback/auto"
                    data-auto_select="false"
                    data-itp_support="true"
                    data-skip_prompt_cookie="SID"
                    data-nonce="biaqbm70g23">
                  </div>
                </>
  : option==3 ? 
                <>
                  <div id="g_id_onload"
                  data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
                  data-context="signin"
                  data-login_uri="/login/callback/auto"
                  data-auto_select="false"
                  data-itp_support="true"
                  data-skip_prompt_cookie="SID"
                  data-nonce="biaqbm70g23">
                  </div>

                  <div id="g_id_onload"
                    data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
                    data-context="signin"
                    data-ux_mode="popup"
                    data-login_uri="/login/callback"
                    data-nonce=""
                    data-auto_prompt="false">
                  </div>
  
                  <div class="g_id_signin"
                    data-type="standard"
                    data-shape="rectangular"
                    data-theme={theme==0?"outline":theme==1?"filled_black":"filled_blue"}
                    data-text="signin"
                    data-size="large"
                    data-logo_alignment="left">
                  </div>
                </>
  :
                <>
                  <div id="g_id_onload"
                  data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
                  data-context="signin"
                  data-login_uri="/login/callback/auto"
                  data-auto_select="false"
                  data-itp_support="true"
                  data-skip_prompt_cookie="SID"
                  data-nonce="biaqbm70g23">
                  </div>

                  <div id="g_id_onload"
                  data-client_id="236314190935-7t5e1v1inflpkvvsm5v72h7bsq38t8c1.apps.googleusercontent.com"
                  data-context="signin"
                  data-ux_mode="popup"
                  data-login_uri="/login/callback"
                  data-nonce=""
                  data-auto_prompt="false">
                  </div>
  
                  <div class="g_id_signin"
                  data-type="icon"
                  data-shape="square"
                  data-theme={theme==0?"outline":theme==1?"filled_black":"filled_blue"}
                  data-text="signin"
                  data-size="large">
                  </div>
                </>
  }  */