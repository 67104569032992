import {useRef} from 'react';
import { Button } from '@themesberg/react-bootstrap';
import {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import AllInputs, { InputBox, InputContainer } from './Inputs';
import AllOutputs, { OutputBox, OutputContainer } from './Outputs';
import { delay } from 'src/utils';
import { Api } from 'src/auth/APIHandler';
import { Button as MuiButton, CircularProgress } from "@mui/material";

const getQueryFileName = (qId, config, defaultValue=null) => {
    try{
        return config['files'][config['queries'][qId].file_id].name;
    }catch(e){
        return defaultValue;
    }
}

const Input = forwardRef((props, ref)=>{
    const {document, input, allRefs, s3} = props;
    if(!(input.id in AllInputs)) return <></>

    // const AllInputs = {
    //     ToGoogleSpreadsheet: ToGoogleSpreadsheet,
    //     CBReport: CBReport,
    //     API: API,
    //     AsinTable: AsinTable,
    //     BidCalculation: BidCalculation,
    // }

    const uniqueKey = (key, keys) => {
        return key;
        let k = key;
        if(!keys.includes(key)) return key;
        let i = 1;
        while(keys.includes(`${key}${i}`)){i++;}
        return `${key}${i}`;
    }
    const C = AllInputs[input.id];
    
    return <InputBox>
            {/* <Button onClick={async()=>{
                const r = allRefs[uniqueKey(input.id, Object.keys(allRefs))];
                if(await r.ready()) console.log(await r.table())
                else console.log('not ready')
            }}/> */}
            <C ref={r=>{if(!(input.id in allRefs)) allRefs[input.id] = {}; allRefs[input.id][input.query_id] = r}} 
            s3={s3}
            input={input}
            allTables={allRefs}
            {...(input.props || {})}/>
        </InputBox>
});


const Output = forwardRef((props, ref)=>{
    const {output, allRefs, s3} = props;
    if(!(output.id in AllOutputs)) return <></>

    const C = AllOutputs[output.id];
    return <OutputBox> 
        <C output={output} s3={s3} ref={r=>{if(!(output.id in allRefs)) allRefs[output.id] = {}; allRefs[output.id][output.query_id] = r}} /> 
    </OutputBox>
});


const Inputs = forwardRef((props, ref)=>{
    const {document, inputs, s3} = props;
    const allRefs = useRef({}).current;
    const cachedTables = useRef({tables: {}}).current;
    const getAll = async fn => (await Promise.all(Object.keys(allRefs).flatMap(k1=>Object.keys(allRefs[k1]).map(async k=>({value: await allRefs[k1][k][fn](), query: k, key: k1})))))

    useImperativeHandle(ref, ()=>({

        
        async ready(handleFail){
           const result = await getAll('ready');  // (await Promise.all(Object.keys(allRefs).flatMap(k1=>Object.keys(allRefs[k1]).map(async k=>({[k1]: await allRefs[k1][k]['ready'](), query: k, key: k1})))));
           if(result.every(v=>v.value)){return true}
            else {
                console.log('fail', handleFail)
                if(handleFail) handleFail(result);
                return false;
            }
            return (await Promise.all(Object.keys(allRefs).map(async k=>await allRefs[k].ready()))).every(_=>_)},
        async tables(){
            return (await getAll('table')).map(v=>({[v.query]: v.value}));
            
        //     (await Promise.all(Object.keys(allRefs).map(async k=>({key: k, value: await allRefs[k].table()}))))
        // .reduce((obj, el, i)=>{obj[el.key] = el.value; return obj}, {})
    }
    }))
    
    return <InputContainer>
        {inputs?.map(input=><Input dcoument={document} input={input} allRefs={allRefs} s3={s3}/>)}
    </InputContainer>
});


const Outputs = forwardRef((props, ref)=>{
    const {outputs, s3} = props;
    const allRefs = useRef({}).current;
    const getAll = async fn => (await Promise.all(Object.keys(allRefs).flatMap(k1=>Object.keys(allRefs[k1]).map(async k=>({value: await allRefs[k1][k][fn](), query: k, key: k1})))))

    useImperativeHandle(ref, ()=>({
        async update(){
            getAll('update');
        }
    }), [])
    
    return <OutputContainer>
        {outputs?.map(output=><Output allRefs={allRefs} output={output} s3={s3}/>)}
    </OutputContainer>
});


const Submit = forwardRef((props, ref)=>{
    const {s3, config, report_id, ready, tables, update} = props;
    const [hint, setHint] = useState('');
    const [buttonOff, setButtonOff] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClick = async() => {
        setLoading(true);
        setButtonOff(true);
        const handleFail = v => {
            setHint('\nplease fill in:\n'+v.filter(val=>!val.value).map(val=>getQueryFileName(val.query, config, val.key)).join(',\n'))
        }
        if(await ready(handleFail)){
            setHint('');
            const _tables = await tables();
            console.log('tables', JSON.stringify(_tables));
            Api('report', '/report', undefined, {
                'reportKey': s3,
                'tables': _tables,
            })
            .then(v=>console.log('report response', v)).catch(e=>console.log('report error', e));

            setButtonOff(true);
            await delay(2000)
            setButtonOff(false);
            update();
        }
        setLoading(false);
        setButtonOff(false);
    }

    return <div style={{marginTop: 10, marginBottom: 100, borderRadius: 10,
    display: 'flex', justifyContent: 'center', backgroundColor: '#262B40', padding: 30}}> 
        <Button style={{width: '100%', height: '100%', backgroundColor: '#fff', color: '#262B40'}}
        onClick={handleClick} disabled={buttonOff}> 
            <div style={{display: 'flex', justifyContent: 'center'}}>submit {loading ? <div style={{paddingLeft: 10}}><CircularProgress thickness={6.} style={{color: '#262B40'}} size={20}/></div> : ""} </div>
            {hint === '' ? <></> : <pre>{hint}</pre>}  
        </Button> 
    </div>
});


const QueryReport = forwardRef((props, ref) => {
    const {s3, config} = props;
    if(!config) return <h1> there is no configuration for this report. please contact the developer if you expect a report to be here. </h1>
    const {external_inputs, external_outputs, queries, files, id} = config;

    const document = useRef({}).current;

    const inputsRef = useRef();
    const allReady = async(v) => {return await inputsRef.current.ready(v)}
    const allTables = async() => {return await inputsRef.current.tables()}

    const outputsRef = useRef();
    const allUpdate = async() => {return await outputsRef.current.update()}

    return <div>
        <Inputs ref={inputsRef} document={document} inputs={external_inputs} s3={s3}/>
        <Submit s3={`public/${s3}`} config={config} ready={allReady} tables={allTables} update={allUpdate} report_id={id}/>
        <Outputs ref={outputsRef} outputs={external_outputs} s3={s3}/>
    </div>
});


export default QueryReport;


