//import "./my.css";
import React, { useState } from "react";
import { AnimatePresence, motion, Variants } from "framer-motion"//"framer-motion/dist/framer-motion";
import styled from 'styled-components';

const v = styled(motion.div)`

/** Additional customization if necessary (e.g. positioning) */
`;
const LI = styled(motion.li)`

`;
//import _css from '!!raw-loader!./my.css';

const itemVariants = {//: Variants
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};

export default function M(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="menu"
    >
      <motion.button
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {props.title}
        <motion.div
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 }
          }}
          transition={{ duration: 0.2 }}
          style={{ originY: 0.55 }}
        >
          <svg width="15" height="15" viewBox="0 0 20 20">
            <path d="M0 7 L 20 7 L 10 16" />
          </svg>
        </motion.div>
      </motion.button>
      <v /* style={{ scaleX: scrollYProgress }} */>
      <motion.ul
        variants={{
          open: {
            clipPath: "inset(0% 0% 0% 0% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05
            }
          },
          closed: {
            clipPath: "inset(10% 50% 90% 50% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3
            }
          }
        }}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        <v>
        {!isOpen ? '' : props.data?.map(function(item, i){
          return <motion.li 
          key={i} 
          onClick={()=>{
            props.select(item.name, item.value);
            setIsOpen(false);
          }} 
          variants={itemVariants}
          > 
          {item.name} 
          </motion.li>
        })}
        {/* <motion.li onClick={()=>{}} variants={itemVariants}>dizzy_creek.xlsx </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx </motion.li>
        <motion.li variants={itemVariants}>unique.xlsx   </motion.li>
        <motion.li variants={itemVariants}>cbreport3.xlsx  </motion.li>
        <motion.li variants={itemVariants}>dizzy_creek.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport5.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport6.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport7.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport8.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li>
        <motion.li variants={itemVariants}>cbreport1.xlsx  </motion.li> */}
        </v>
      </motion.ul>
      </v>
    </motion.nav>
    </div>
  );
}
