import React, {useState, useEffect, useRef, Component, memo, useCallback, forwardRef, useImperativeHandle} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faInfo, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Form, InputGroup, Modal, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";
import { GeneralInfoForm } from "../../components/Forms";
import axios from "axios";
import Progress from "../../components/Progress";

import Profile3 from "../../assets/img/team/profile-picture-3.jpg";

import { STReportTable, TransactionsTable } from "../../components/Tables";
//import { pageReport } from "../../data/tables";

import { faFileAlt as faFile } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress, LinearProgress } from "@mui/material";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { Spreadsheet } from "react-spreadsheet";
import styled from "styled-components";
import Menu, { TextMenu } from "../../components/Custom/MenuBar";
import Excel from "../../components/Custom/Excel";
import SavedCheck from "../../components/Custom/SavedCheckbox";
import { Style, Space, CSpace, RCSpace, CollapsableAdvancedOptions, MyInputText } from './STRFormElements';
import { ToastServerMessage } from "../Custom/GetMessage";
import { uploadFile } from "../CBR";
import { files_seporator } from "../../components/utilities";
import { S3Excel, getEmail, getS3Json, putS3Json, savedIindex } from "src/auth/util";
import { isObject } from "lodash";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import { Drop, MenuText } from "src/components/Custom/MenuBar/InputMenu";
import useWindowDimensions from "src/components/Custom/useWindowDimensions";
import { submitJob } from "src/documents/submit";
import { tryStringifyJSON } from "src/utils";
import { Api } from "src/auth/APIHandler";

const twoChar = c => `${c}`.length == 1 ? `0${c}` : c
const dateToString = date => date.format('YYYY-MM-DD');  // `${date.year()}-${twoChar(date.month())}-${twoChar(date.day())}`

//import STRTable from "./STR/STRTable";

const pageReport = [
  { id: 1,  icon: faFileAlt, status: 76, name: 'Waiting for data', link: '#', link2: '#' },
  { id: 2, icon: faFileAlt, status: 555, name: 'from server', link: '#/settings', link2: '#/settings' },
];

const MicroIcon = () => {return (<OverlayTrigger
  placement="left"
  trigger={['hover', 'focus']}
  overlay={
    <Tooltip>Microsoft Excel</Tooltip>
  }>
<FontAwesomeIcon icon={faMicrosoft}></FontAwesomeIcon></OverlayTrigger>);};

const GoogleIcon = () => {return (<OverlayTrigger
  placement="left"
  trigger={['hover', 'focus']}
  overlay={
    <Tooltip>Google Sheets</Tooltip>
  }><FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon></OverlayTrigger>);};

const testData = [
  {value: "something", name: "Just a moment..",},
  // {value: "something", name: "unique.xlsx"},
  // {value: "something", name: "cbreport3.xlsx",},
  // {value: "something", name: "dizzy_creek.xlsx",},
  // {value: "something", name: "cbreport5.xlsx",},
  // {value: "something", name: "cbreport6.xlsx",},
  // {value: "something", name: "cbreport7.xlsx",},
  // {value: "something", name: "cbreport8.xlsx",},
  // {value: "something", name: "cbreport1.xlsx",},
  // {value: "something", name: "cbreport1.xlsx",},
  ];

const Date = forwardRef((props, ref) => {
  const {onChange, defaultValue, ...rest} = props;

  const [date, __setDate] = useState(null);  // defaultValue || dayjs());
  const _setDate = useCallback(e => { __setDate(e); }, [date]);

  useImperativeHandle(ref, () => ({
    getDate(){ return date; },
    setDate(_date){ _setDate(_date); }
  }));

  return (<DatePicker {...rest} value={date} onChange={(d) =>  {_setDate(d);onChange(d);}} />)
})

const CDiv = styled.div`
  width: 95%;  
  @media screen and (max-width: 950px) {
      //transform: scaleX(0.85);
      width: 80%;
      transition: all .2s ease-in-out;
    }
    @media screen and (max-width: 790px) {
        width: 70%;
        transition: all .2s ease-in-out;
    }

    @media screen and (max-width: 480px) {
        width: 60%;
        transition: all .2s ease-in-out;
    }
`;

/* const STable = () => {
  const [checked, setChecked] = useState(true);
  const [tableData, setTableData] = useState(pageReport);
  const MyTable = styled.div`
  `;
  const MyDiv = styled.div`

    //font-family: Untitled Sans, sans-serif;
    //font-size: 14px;
    //line-height: 20px;
    //color: #000000aa;
    height: 110%;
    //position: absolute;
    //overflow: auto;
    background-color: #262B40;
    border-radius: 25px;

    .child3{
      //position: absolute;
      margin-left: 0;//-100%;
      margin-top: 50px;
      
      //width: 100%;
      &:hover {
      //transform: scale(1.02) !important;
      //transition: all .2s ease-in-out;
    }

    .child2 {
      //width: 100%;
      margin-left: -100%;
      position: absolute;
      display: flex;
      z-index: -12 !important;
      pointer-events: none;
    }
      input{
        border: 1px solid #ffffff00; 
      }
    }

    .child4 {
      position: absolute;
      left:35%;
      margin-left:-7%;
      width:20%;
      font-weight: 900;
      letter-spacing: 2px;
      z-index: 35;
      // width: 10% !important;
    }

    .inputFile {
      margin-left:5%;
      //color: white;
      //text-shadow: 1px 1px 2px #ababab44;
      z-index: 36;
    }
  `;

  const checkTable = (_data) => {
    try{
      if(_data.length != tableData.length){ return true; }
      for (let i = 0; i < _data.length; i++) {
        const element = _data[i];
        const element2 = tableData[i];
        if( element.id != element2.id || element.status != element2.status || element.name != element2.name || element.link != element2.link || element.link2 != element2.link2)
        { return true; }
      }
    }catch(err) {}
    return false;
  }
  const updateTable = async() => {
    await fetch('/streportdata')
    .then((response) => response.json())
    .then((data) => {
      if(checkTable(data.tableData)){
        try{
        if(typeof(data) == 'string'){
          var _data = JSON.parse(data);
        }else{var _data = data;}
        var new_data = [];
        for (let i = 0; i < _data.tableData.length; i++) {
          const el = _data.tableData[i];
          new_data.push({ id: el.id,  icon: el.link=='false', icon2: el.link2=='false', status: el.status, name: el.name, link: el.link, link2: el.link2 },)
        }
        }catch(err){var new_data = [{id: 1,  icon: true, icon2: true, status: '<-- error', name: String(err), link: '/', link2: '/'}]}
        setTableData(new_data);//_data.tableData);
      }
    })
  };
  const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
  }
  const tableLoop = async() => {
    while(true){
      await updateTable();
      break;
      await timeout(5000); //for 10 sec delay
    }
  }
  useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
      var test = true
      // Update the document title using the browser API
      if (test) {
        test = false
        tableLoop();
      }
    }, []);

  return (<>
    <MyDiv>
        
        <Row>
        <CSpace xs={1}/>
        <Col>
          <Space/>
          <SavedCheck title="Toggle Information" setChecked={setChecked} name='streport'/>
          
          <MyTable className='child'>
          <STReportTable className='child' items={tableData} checked={checked} />
          </MyTable>
          <Space/>
        </Col>
        <CSpace  xs={1}/>
        </Row>
      </MyDiv>
  </>);
}; */
/* 
const Style = styled.div`
  .container_row{
    display: flex;
   //  display: grid;
    //align-items: center; 
  }

  .layer1{
    position: absolute;
    margin-left: -15%;
    margin-top: 5%;
    width: 100%;
  } 
  //   .layer2{
  //  grid-column: 1;
  //  grid-row: 1;
  //} 
  .layer2 {
    width: 100%;
    margin-left: -100%;
    //position: absolute;
    display: flex;
    z-index: 2;
    pointer-events: none;
  }

  .child3{
      //position: absolute;
      margin-left: 0;//-100%;
      margin-top: 50px;
      
      //width: 100%;
      &:hover {
      //transform: scale(1.02) !important;
      //transition: all .2s ease-in-out;
    }

    .child2 {
      //width: 100%;
      margin-left: -100%;
      position: absolute;
      display: flex;
      z-index: -12 !important;
      pointer-events: none;
    }
      input{
        border: 1px solid #ffffff00; // Red border only if the input is empty 
      }
    }

    .child4 {
      position: absolute;
      left:35%;
      margin-left:-7%;
      width:20%;
      font-weight: 900;
      letter-spacing: 2px;
      z-index: 35;
      // width: 10% !important;
    }
`; */


const fecthJSON = async (url) =>{
  return await fetch(url)
    .then((response) => response.json())
    .then((data) => {
        if(typeof(data) == 'string'){
          var _data = JSON.parse(data);
        }else{var _data = data;}
        return _data;
    })
}


function HelpInfo(props){
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  return (<>
    <React.Fragment>
      <Button size="sm" href="#gray" bsPrefix="text" variant="primary" className="m-3" onClick={() => setShowDefault(true)}>
        
        <p><FontAwesomeIcon icon={faInfo} /> click for help </p>
      </Button>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Info</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
        <><p> Use: </p>
              <p> To submit a report input a BusinessReport.csv and Bulk.xlsx file and click submit. </p>
              <p> You may enter a custom name in the Report Name field </p>
              <p> To use with Google Sheets click the {<FontAwesomeIcon icon={faGoogle} />} to download a xlsx file. </p>
              <p>To use with Excel click the {<FontAwesomeIcon icon={faMicrosoft} />} to download an xlsm file. For Excel please enable macros to clean the Breakdown and data Sheet. If you are not able to enable macros please replace all '"='' with '='' and all '@' with '' (nothing) for Sheets "Breakdown" and "data" (Excel should have a find and replace feature on the top right of the {'"Home"'} tab)</p></>
              <p></p>
              <p> Side note. entering {"'{i}'"} into the Report Name will add an index for that specific name. {"(this was for testing but could  be helpful)"}</p>
         {/*  <p>With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.</p>
          <p>The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.</p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gray" onClick={handleClose}>
            Ok
        </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  </>);
}

function FileForm(props){
  const renameFile = (originalFile, newName) => {
    try {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
    } catch(err) {
      console.log('FileForm, renameFile ERROR:');
      console.log(err);
      return originalFile;
    }
  }
  const onFileChange = (event) => {
    props.setFile(renameFile(event.target.files[0], props.fileName))
  }
  return (
  <>
    <Col >
    <Form>
      <Form.Label
      > {props.title} </Form.Label>
      <Form.Control type="file" onChange={onFileChange} />
    </Form>
    </Col>
  </>);
}


export default () => {
  const { height, width } = useWindowDimensions();

  const [doc, _setDoc] = useState(null);
  const setDoc = useCallback((e) => { _setDoc(e); }, [doc]);

  const [profileInfo, _setProfileInfo] = useState({profile: null, start: null, end: null});
  const setProfileInfo = useCallback((e) => { console.log('profile set', e, JSON.stringify(e));
    _setProfileInfo(e); }, [profileInfo]);
  
  const ref = useRef(null);
  const advanceOptions = useRef(null);
  const textRef = useRef(null);
  const progressBarRef = useRef(null);
  const inputMenuRef = useRef(null);
  const excelRef = useRef(null);

  const col = ["Asin" , "Product Group", "Target ACoS" ];

  const [brandFile, setBrandFile] = useState(null);
  const [productFile, setProductFile] = useState(null);
  const [displayFile, setDisplayFile] = useState(null);
  /* const [msgText, setMSGText] = useState("");
  const displayText = async(text) => {
    var s = ''
    for (let i = 0; i < text.length; i++) {
      await timeout(10);
      const element = text[i];
      s += element;
      setMSGText(s);
    }
    setMSGText(text);
    await timeout(2000);
    while (s.length > 0) {
      s = s.substring(0, s.length - 1);
      setMSGText(s);
      await timeout(5);
    }
    
    setMSGText("");
  } */
  const [newNameText, setnewNameText] = useState("");

  const [barProgress, setProgress] = useState(0);

  const [menuLabel, setMenuLabel] = useState("Choose a C. B. Report");
  const [menuValue, setMenuValue] = useState(""); 

  const [menuData, setMenuData] = useState(testData);
  
  const [profileLabel, setProfileLabel] = useState("Choose Account Profile");
  const [profiles, setProfiles] = useState([]);

  const objCheck = (obj) => {
    try{ return isObject(obj) && 'this_id' in obj && 'display_name' in obj && 'asins' in obj.extra && obj.this_file.name.endsWith('x'); } catch (e){}
    return false;
    //old  --> // if(!isObject(obj)){return false;} if(!('extra' in obj && 'this_file' in obj)){return false;}  if(!isObject(obj.this_file)){return false;} if(typeof obj.this_file.name != 'string'){return false;} return 'this_id' in obj && 'display_name' in obj && 'asins' in obj.extra && obj.this_file.name.endsWith('x');
  }
  const updateMenu = async() => {
    console.log('menuu start')
    const email = await getEmail();
    await getS3Json(`jobs/${email}/cbreport.json`)
    .then(async d=>{

      let j = await Promise.all(d.map(async v=> await getS3Json(`docs/${v.id}.json`).then(jj=>jj).catch(e=>{console.log(e); return 'error';})));
      j = j.filter(o=>objCheck(o));
      j = j.map(v=>{return {value: JSON.stringify(v), name: v.display_name};});
      console.log('manuu data', j)
      if(Array.isArray(j)){setMenuData(j)} })
    .catch(e=>{console.log(e);})
  };
  const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
  }

  const updateProfiles = async() => {
    console.log('getting profiles')
    const _profiles = await getS3Json('AMAZON_ADS_API/amazon_api_profiles.json');
    console.log('profiles ', _profiles)
    let ps = [];
    Object.keys(_profiles).forEach(k=>{
      ps = ps.concat(_profiles[k]);
    })
    console.log('profiles ', ps)
    let pss = [];
    ps.forEach(v=>{
      pss.push({value: v, name: `${v.countryCode} - ${v.accountInfo.name}`})
    })
    console.log('profiles ', pss)
    inputMenuRef.current.setLocalData(pss);
    setProfiles(pss);
  }

  useEffect(() => {
    updateMenu();
    updateProfiles();
    setProgress(0);
  }, []);

  
  const resetProgress = async() => {
    await timeout(2000);
    while (barProgress > 2) {
      setProgress(barProgress - 5);
      await timeout(5);
    }
    setProgress(0);
  }
  const click = (value=null) => {
    ref.current.sendAll();
    var some_files = [brandFile, productFile, displayFile]  // select files

    // // create FormData containing all files with a value
    // const formData = new FormData()
    // var i = 0; // to give the file and index. the for loop can skip a file
    // for (let index = 0; index < some_files.length; index++) {
    //   const element = some_files[index]
    //   if (!element) {
    //     continue;
    //   }
    //   formData.append('File' + String(i + 1), element)
    //   i++;
    // }
    // if(i == 0){return;}  // no files exist

    some_files = some_files.filter(v=>!!v)  // !! possibly unecessary
    let extra = '';
    if(typeof(value)=='string') { extra = (value=='' ? '' : ('/'+ value)); }
    else { extra = (newNameText=='' ? '' : ('/'+ newNameText)) }
    var url = '/streport'+extra; // if no new name is given the wbe server will create one
    try{
      progressBarRef.current.doClick();
    }catch(err){console.log(err);}
    progressBarRef.current.submitForm(url, some_files, menuValue)
    
  }

  

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();  // Form.Control refreshes the page on enter. this disables that feature
      click();
    }
  }

  const handleExcelData = (_data) => {

  }

  /* const MyExcel = memo(() => <Excel/>) */
  /* const Child = forwardRef((props, ref) => {

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
  
      getAlert() {
        alert("getAlert from Child");
      }
  
    }));
  
    return <h1>Hi</h1>;
  }); */
  
    const MyLoadingBar = forwardRef((props, ref) => {
      const [_barProgress, set_BarProgress] = useState(0.0);
      const onChangeInput = useCallback(
        (v) => {
          set_BarProgress(v);
        },
        [_barProgress]
      );
      const [text, setText] = useState("");
      const changeText = useCallback(v=>{ setText(v); }, [text]);
      const doClick = () => {
       
      }
      const reset_Progress = async() => {
        await timeout(2000);
        while (_barProgress > 2) {
          onChangeInput(_barProgress - 5); //set_BarProgress(_barProgress - 5);
          await timeout(5);
        }
        onChangeInput(0); //set_BarProgress(0);
      }
      useImperativeHandle(ref, () => ({
        
        async submitForm (url, files, _menuValue){
          
          let n1 = 1.0; let n2 = 1.0;
          const f1 = (progressEvent) => { const myprogress = Math.ceil((((progressEvent.loaded / progressEvent.total) * 95.) * n1) + n2); onChangeInput(myprogress); }
          const f2 = (progressEvent) => { const myprogress = Math.ceil(((95. + (progressEvent.loaded / progressEvent.total) * 5.) * n1) + n2); onChangeInput(myprogress); }
          let fileNames = [];
          let wait = true;
          let i = 1;
          for (const file of files) {
            n1 = i / files.length; n2 = (i - 1) / files.length;
            changeText(`uploading file - ${i} of ${files.length} - ${file.name}`);
            i++;
            wait = true;
            await uploadFile(file, true, ...new Array(1), f1, f2)
            .then(info=>{fileNames.push(info.fileName)
            wait = false}).catch(wait = false)
            // while(wait){}
          }
          changeText(`Requesting report..`);
          axios.post(url, new FormData(), {
            headers: {
              "Content-Type": "multipart/form-data", // declaring is FormData
              "cbreport": String(_menuValue),
              "myfiles": fileNames.join(files_seporator),
              "fileIDs": files.map(f=>f.name).join(files_seporator)
            },
            onUploadProgress: (progressEvent) => {
              const myprogress = Math.ceil((progressEvent.loaded / progressEvent.total) * 95);
              onChangeInput(myprogress); //set_BarProgress(myprogress);
            },
            onDownloadProgress: (progressEvent) => {
              const myprogress = Math.ceil(95 + (progressEvent.loaded / progressEvent.total) * 5);
              onChangeInput(myprogress); //set_BarProgress(myprogress);
            },
          }).then(async()=>{
            reset_Progress();
            changeText(`done`);
            await timeout(2000);
            changeText('');
          })
          .catch((error)=>{reset_Progress(); console.log(error);});
        }
    
      }));
    return (<><p> {text} </p>
      <LinearProgress 
      style={ 	  { 		color: '#white', backgroundColor: "transparent",		/* borderRadius: '50%', */ 		/* boxShadow: 'inset 0 0 0 5.5px white'  */	  } 	} 
      variant="determinate"  value={_barProgress} color="inherit" />
    </>);
  });

  const inputStyle = {
    marginLeft:"5%",
      color: "white",
      textShadow: "1px 1px 2px #ababab44"
  }
  const today = dayjs().add(1, 'day');
  const tomorrow = dayjs().subtract(120, 'day');

  const submitForm = async() => {
    let fileName = textRef.current.getText();
    if(fileName.includes('{i}') || fileName.includes('[i]')){
      let ind = await savedIindex(fileName.replace('[i]', '').replace('{i}', ''))
      fileName = fileName.replace('[i]', String(ind)).replace('{i}', String(ind))
    }
    let tableData = await excelRef.current.getData();
    let bidData = await advanceOptions.current.getData();
    if(Array.isArray(tableData) && Array.isArray(bidData) && 
    !!doc && !!profileInfo.profile && !!profileInfo.start && !!profileInfo.end){
      console.log('submitJob', JSON.stringify(['streport', fileName, [], 
      {...profileInfo, 'acosTable': tableData, 'newBidData': bidData, 'doc': doc}, true]))
      let r = await submitJob('streport', fileName, [], 
      {...profileInfo, 'acosTable': tableData, 'newBidData': bidData, 'doc': doc}, true);
      console.log(r);
      textRef.current.setText('');
    }
  }

  const googleHandle = () => {
    console.log('gool hit');
    (async ()=>{
      let name = 'googlepermissions'; // 'test3'
      let endpoint = '/google-permissions';  // '/test3'
      let obj = await Api(name, endpoint, undefined, {uri: ''});
      console.log('gool obj', obj);
      if(obj.hasOwnProperty('redirect')){
        console.log('gool sent');
        // https://www.w3schools.com/jsref/met_win_open.asp
        window.open(obj.redirect, '_blank', 'height=500,width=400');//,menubar=no,titlebar=no
      }
    })();
  }

  return (
    <>
    <div>
    <Row> <Col><ToastServerMessage type="streport"/>
    <Button onClick={googleHandle}> google button </Button>
    <h1> Search Term Report &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        {/* <HelpInfo/>  */}</h1>
        <p> comparing search terms and keywords </p></Col> </Row>
        <div style={{height: "110%",
    backgroundColor: "#262B40",
    borderRadius: "25px"}}>
        <div style={inputStyle}>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      
      <Row>
        <Style>
        <Row>
          <CSpace/>
          <Col>
            <Menu className='child2'
            data={menuData} 
            select={(n, v)=>{
              setMenuLabel(n); 
              setMenuValue(v);

              console.log(JSON.stringify(v))
              const putTable = async __data => {
                const tryJson = j => {try{return JSON.parse(j);}catch(e){}return j;}
                let _data = tryJson(__data);
                const email = await getEmail();
                let d = await getS3Json(`${email}/allAsins.json`);
                let allAsins = isObject(d) ? d : {};
                const asins = _data.extra.asins;
                let table = [[{ value: "default", readOnly: true }, { value: "N / A", readOnly: true }, { value: (allAsins.hasOwnProperty("default") ? allAsins.default[2].value : "40.0"), number: true }]];
                asins.forEach(a=>{
                  let adGroup = ''; let acos = '';
                  if(allAsins.hasOwnProperty(a)){
                    adGroup = allAsins[a][1].value;
                    acos = allAsins[a][2].value;
                  }
                  table.push(
                    [{ value: String(a), readOnly: true }, { value: adGroup }, { value: acos, number: true }]
                  )
                })
                // console.log('asin table ', table);
                if(_data.hasOwnProperty('this_id')) {
                  setDoc(_data);
                  advanceOptions.current.update(String(_data.this_id));
                  excelRef.current.setData(`asinTable-${_data.this_id}`, table);
                }
              }
              putTable(v);
              // getEmail().then(email=>{
              //   getS3Json(`${email}/allAsins.json`).then(d=>{
                  
              //     //excelRef.current.setData(table)
              //   }).catch(e=>{console.log('nope', e)})
              // })
              (async()=>{
                // ref.current.customFetchData(v, n);
                // advanceOptions.current.update(v);
              })()//menuFetch();
              }} 
            title={menuLabel}/>
          {/* </Col>
          <Col> */}
          
          {/* <Excel 
          ref={ref}
          className='child3' 
          // handleData={handleExcelData}  
          darkMode={false}  
          // data={excelData} {[[{ value: "" },{ value: "" }],[{ value: "" },{ value: "" }],[{ value: "" },{ value: "" }]]} 
          // name={menuValue} 
          columnLabels={col} // rowLabels={row} onChange={setExcelData} 
            // fetchData={click => setMenuFetch(click)} 
          /> */}
          <RCSpace/><RCSpace/><RCSpace/>
          <S3Excel 
            ref={excelRef} 
            tableName={`emptyAsinsTest`} 
            columnLabels={["Asin" , "Product Group", "Target ACoS" ]}
            initData={[
              [{ value: "default", readOnly: true }, { value: "N / A", readOnly: true }, { value: "40.0", number: true }],
            ]}
            onChange={async _tableData=>{
              let email = await getEmail();
              let d = await getS3Json(`${email}/allAsins.json`);
              let allAsins = isObject(d) ? d : {};
              _tableData.forEach(row=>{allAsins[row[0].value] = row;});
              putS3Json(`${email}/allAsins.json`, allAsins);
            }}
          />
          
          </Col>
          
          <CSpace/>
         
        </Row>
        <Row>
        <Col>
        <CDiv>
          {/* <FileForm
           title={"STR Brands (.xlsx) (required) "} fileName="brand.xlsx" setFile={setBrandFile}/>
        <Space/>
        <div className="inputFile">
          <FileForm title={"STR Products (.xlsx) (required) "} fileName="product.xlsx" setFile={setProductFile}/>
          
          </div>
        <Space/>
        <div className="inputFile">
          <FileForm title={"STR Display (.xlsx) (optional) "} fileName="display.xlsx" setFile={setDisplayFile}/>
          </div>
        <Space/>  */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{...(width > 1203 ? {display: 'flex'} : {gridAutoColumns: 'auto'}),
            // justifyContent: 'space-between', 
            backgroundColor: '#fff', padding: '10px 10px 10px 10px', margin: '10px 10px 10px 10px', borderRadius: '5px'}}>
              <div style={{width: '50%', height: '50px'}}>
              <MenuText ref={inputMenuRef}
              className='child2' data={profiles} 
              select={(n, v)=>{ 
                setProfileLabel(n);
                setProfileInfo({...profileInfo, profile: v}); //setProfiles(v);  
              }} 
                title={profileLabel}/>
              </div>
              <div style={{display: 'flex'}}>
                <Date label="Start Date" defaultValue={today} minDate={tomorrow} 
                // value={value}
                onChange={(date) => setProfileInfo({...profileInfo, start: dateToString(date)})}//setValue(newValue)} 
                disableFuture/> 
                
                <h1 style={{color: '#ababab'}}> - </h1>
                <Date label="End Date" defaultValue={today} minDate={tomorrow} 
                // value={value}
                onChange={(date) => setProfileInfo({...profileInfo, end: dateToString(date)}) }//setValue(newValue)} 
                disableFuture/> 
              </div>
            </div>
          </LocalizationProvider>
        </CDiv>
        </Col>
        </Row>
        <Row>
          <Col>
          <CDiv>
            <CollapsableAdvancedOptions ref={advanceOptions} name={'default'} />
          </CDiv>
          </Col>
        </Row>
        
        </Style>
        <RCSpace/>
        
        <Row>
          <Col>
          <CDiv>
            <MyInputText ref={textRef} onEnter={()=>{if(!!doc && !!profileInfo.profile && !!profileInfo.start && !!profileInfo.end) submitForm()}}/>
          </CDiv>
          </Col>
          
        </Row>
        <Row>
          <Col>
        <CDiv>
          <MyLoadingBar ref={progressBarRef}/>
          {/* <LinearProgress 
          style={ 	  { 		color: '#white', backgroundColor: "transparent" } 	} 
          variant="determinate"  value={barProgress} color="inherit" /> */}
          </CDiv>
          </Col>
          </Row>
        <RCSpace/>
        <Row>   
            <Col>
            <CDiv>
              <Button 
              style={{width: '100%'}}
                className='child4'
                variant="white" 
                onClick={()=>{
                  submitForm();
                  }}
                disabled={!(!!doc && !!profileInfo.profile && !!profileInfo.start && !!profileInfo.end)}
                >
                {!(!!doc && !!profileInfo.profile && !!profileInfo.start && !!profileInfo.end )
                ? <div style={{display:'flex', flexDirection:"column"}}><span>{'Please fill the following fields: '}</span> <span>{(!doc ? 'C.B. Report, ':'') + (!profileInfo.profile ? 'Account Profile, ':'') + (!profileInfo.start ? 'Start Date, ':'') + (!profileInfo.end ? 'Start Date, ':'')}</span></div>
                : 'Submit' }
              </Button>
              </CDiv>
              </Col>
        </Row>
        <RCSpace/><RCSpace/>
        

        <div className="d-flex">
        </div>
        </Row>
      </div>
      </div>
      </div>
      
      <Row><Col><h1>&nbsp;</h1></Col></Row>
      <Row><Col><h1>&nbsp;</h1></Col></Row>
      </div>
    </>
  );
};