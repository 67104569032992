import React, {useState, useEffect, useRef} from "react";
import { Col, Row, Button, Dropdown, Form, InputGroup, ToggleButton } from '@themesberg/react-bootstrap';

import axios from "axios";



import { AllowedUserTable } from "../../components/Tables";
//import { pageReport } from "../data/tables";

import { CircularProgress, LinearProgress } from "@mui/material";
import { faFileAlt, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useId } from "react";
import { getS3AllowedUsers, isDev, setDev, setS3AllowedUsers } from "src/auth/util";
import { FaUserAlt } from "react-icons/fa";
import { QuickToastMessage } from "../Custom/GetMessage";
import { Checkbox } from "@material-ui/core";
import { Api } from "src/auth/APIHandler";
const tempAllowedUsers = [
  { id: 1,  icon: faFileAlt, email: '...', name: '', link: '<name>' },
];

const googleHandle = () => {
  console.log('gool hit');
  (async ()=>{
    let name = 'googlepermreadonly'; // 'test3'
    let endpoint = '/googlepermreadonly';  // '/test3'
    let obj = await Api(name, endpoint, undefined, {uri: ''}, 'POST');
    console.log('gool obj', obj);
    if(obj.hasOwnProperty('redirect')){
      console.log('gool sent');
      // https://www.w3schools.com/jsref/met_win_open.asp
      window.open(obj.redirect, '_blank', 'height=500,width=400');//,menubar=no,titlebar=no
    }
  })();
}

export default () => { 
  const toastRef = useRef(0);

  const [tableData, setTableData] = useState([]);
  const getUsers = async() => {
    let users = await getS3AllowedUsers(); 
    if(!Array.isArray(users)){throw new Error('users must be list')}
    return users;
  }
  const [bulkFile, setBulkFiles] = useState(null);
  const [reportFile, setReportFiles] = useState(null);
  const [bulkInput_ref, setBulkInputRef] = useState("");
  const [reportInput_ref, setReportInputRef] = useState("");
  const [msgText, setMSGText] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [barProgress, setProgress] = useState(0);
  
  const updateTable = async(i=0, users=null) => {
    let allowedUsers = !!users ? users : await getUsers();
    if(!Array.isArray(allowedUsers)) { if(i > 4) {return;} else {return updateTable(i+1);}}
    let allowed = [];
    let index = 0;
    allowedUsers.forEach(email=>{
      allowed.push({id: index, email: email, icon: faUserAlt})
      index++;
    });
    setTableData(allowed)
  };

  const removeUser = async email => {
    let users = await getUsers();
    users = users.filter(u=>u!==email);
    await setS3AllowedUsers(users);
    updateTable(0, users);
    toastRef.current.showMessage(`${email} was removed`, 4500);
  }

  const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
  }
  const tableLoop = async() => {
    return await updateTable();
    while(true){
      await updateTable();
      await timeout(5000); //for 10 sec delay
    }
  }
  useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
    var test = true
    // Update the document title using the browser API
    if (test) {
      test = false
      tableLoop();
      //updateTable();
    }
    setTableData(tempAllowedUsers);
    setProgress(0);
  }, []);

  const displayText = async(text) => {
    var s = ''
    for (let i = 0; i < text.length; i++) {
      await timeout(50);
      const element = text[i];
      s += element;
      setMSGText(s);
    }
    setMSGText(text);
    await timeout(2000);
    while (s.length > 0) {
      s = s.substring(0, s.length - 1);
      setMSGText(s);
      await timeout(25);
    }
    
    setMSGText("");
  }
  const resetProgress = async() => {
    await timeout(2000);
    while (barProgress > 2) {
      setProgress(barProgress - 5);
      await timeout(5);
    }
    setProgress(0);
  }

  const click = async() => {
    if(emailText.length > 0){
      let users = await getUsers();
      users.push(emailText);
      displayText('sent.');setEmailText('');setInput('');
      await setS3AllowedUsers(users);
      updateTable(0, users);
    }
  }

  const bulkChangeHandler = (event) => {

    setBulkFiles(event.target.files[0])
  }

  const reportChangeHandler = (event) => {

    setReportFiles(event.target.files[0])
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      click();
    }
  }
  const id = useId();
  const [input, setInput] = useState('');
  const [emailText, setEmailText] = useState('');
  const [checked, setChecked] = useState(isDev());
  return (
    <>
    <QuickToastMessage  ref={toastRef}/>
      <Row> <Col><h1> User Access </h1></Col>
        </Row>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      
      <Row>
        <Col xs={12} xl={38}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>Email address { msgText }</Form.Label>
              <InputGroup id={id} onChange={e => {setInput(e.target.value);setEmailText(e.target.value)}}>
                <Form.Control type="text" placeholder="name@example.com" value={input} onKeyDown={handleKeyDown} />
                <InputGroup.Text ><FontAwesomeIcon icon={faUserAlt} /></InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Form>
          <Button onClick={click}>Add</Button>
        <Row>
          <Col xl={3}>
            <p>  </p>
          </Col>
          
        </Row>
         {/*  <GeneralInfoForm /> */}
        </Col>
        <div className="d-flex">
        </div>
        </Row>
      </div>

      <Row>
        <Col>
          <AllowedUserTable items={tableData} update={updateTable} remove={removeUser} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={googleHandle}> Google Read-only Permissions </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{float: 'right'}}>
            <Checkbox color="blue" defaultChecked={isDev()} onChange={e=>setDev(e.target.checked)}/>
          </div>
        </Col>
      </Row>
    </>
  );
};

