
import React, { useEffect, useState, forwardRef, useCallback, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faFileAlt, faInfo, faPenAlt, faTools, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup, OverlayTrigger, Popover, Form, ToggleButton } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import { pageVisits, pageTraffic, pageRanking, pageReport } from "../data/tables";
import transactions from "../data/transactions";
import commands from "../data/commands";
import { Button as MuiButton, CircularProgress, Input } from "@mui/material";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";
import { Space } from "../pages/STR/STRFormElements";
import { QuickToastMessage } from "../pages/Custom/GetMessage";
import { cacheItem, getEmail, getRenamedS3Link, getS3Json, getS3Link, isDev } from "src/auth/util";
import { delay, isObject, tryParseJSON, tryStringifyJSON } from "src/utils";
import { jobStatus } from "src/utils/JobManager";
import { getDownloadLocation } from "src/documents/submit";
import { Api } from "src/auth/APIHandler";
import faRotateRight from 'src/images/rotate-right-solid.svg'


const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

export const PageVisitsTable = () => {
  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>${returnValue}</td>
        <td>
          <FontAwesomeIcon icon={bounceIcon} className={`${bounceTxtColor} me-3`} />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className="text-end">
            <Button variant="secondary" size="sm">See all</Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Page name</th>
            <th scope="col">Page Views</th>
            <th scope="col">Page Value</th>
            <th scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map(pv => <TableRow key={`page-visit-${pv.id}`} {...pv} />)}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const { id, source, sourceIcon, sourceIconColor, sourceType, category, rank, trafficShare, change } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">{id}</Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon icon={sourceIcon} className={`icon icon-xs text-${sourceIconColor} w-30`} />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar variant="primary" className="progress-lg mb-0" now={trafficShare} min={0} max={100} />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map(pt => <TableRow key={`page-traffic-${pt.id}`} {...pt} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const { country, countryImage, overallRank, overallRankChange, travelRank, travelRankChange, widgetsRank, widgetsRankChange } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image src={countryImage} className="image-small rounded-circle me-2" />
            <div><span className="h6">{country}</span></div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {overallRank ? overallRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">
          {travelRank ? travelRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">
          {widgetsRank ? widgetsRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map(r => <TableRow key={`ranking-${r.id}`} {...r} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const TransactionsTable = () => {
  const totalTransactions = transactions.length;

  const TableRow = (props) => {
    const { invoiceNumber, subscription, price, issueDate, dueDate, status } = props;
    const statusVariant = status === "Paid" ? "success"
      : status === "Due" ? "warning"
        : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
            {invoiceNumber}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">
            {subscription}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {issueDate}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {dueDate}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            ${parseFloat(price).toFixed(2)}
          </span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>
            {status}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Bill For</th>
              <th className="border-bottom">Issue Date</th>
              <th className="border-bottom">Due Date</th>
              <th className="border-bottom">Total</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(t => <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: '5%' }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: '5%' }}>
          <ul className="ps-0">
            {usage.map(u => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: '50%' }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: '40%' }}>
          <pre><Card.Link href={link} target="_blank">Read More <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" /></Card.Link></pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table responsive className="table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: '5%' }}>Name</th>
              <th className="border-0" style={{ width: '5%' }}>Usage</th>
              <th className="border-0" style={{ width: '50%' }}>Description</th>
              <th className="border-0" style={{ width: '40%' }}>Extra</th>
            </tr>
          </thead>
          <tbody>
            {commands.map(c => <TableRow key={`command-${c.id}`} {...c} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const ReportTable = (props) => {
  const TableRow = (props) => {
    const { icon,icon2, name, status, link, link2 } = props;
    const [_link, setLink] = useState(false);
    const [_link2, setLink2] = useState(false);
    useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
      setLink(link == 'false');
      setLink2(link2 == 'false')
    }, []);
    const MicroIcon = (props) => {return (<>{!props.mchecked ? <FontAwesomeIcon {...{...props, icon: faMicrosoft}} ></FontAwesomeIcon> : 
    <OverlayTrigger
      placement="left"
      trigger={['hover', 'focus']}
      overlay={
        <Tooltip>Download for Excel</Tooltip>
      }>
    <FontAwesomeIcon icon={faMicrosoft}></FontAwesomeIcon></OverlayTrigger>}</>);};
    const GoogleIcon = (props) => {return (<>{!props.mchecked ? <FontAwesomeIcon {...{...props, icon: faGoogle}}></FontAwesomeIcon>
     : <OverlayTrigger
      placement="left"
      trigger={['hover', 'focus']}
      overlay={
        <Tooltip>Download for Google Sheets</Tooltip>
      }><FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon></OverlayTrigger>}</>);};
    return (
      <tr >
        <td className="border-0">
        {icon ?  <GoogleIcon color='white'/>:
          <td className="border-0">
            <Card.Link href={link} className="d-flex align-items-center">
              <GoogleIcon mchecked={props.checked}/>
            </Card.Link>
            </td>}
            {icon2 ? <MicroIcon color='white'/>:
          <td className="border-0">
            <Card.Link href={link2} className="d-flex align-items-center">
              <MicroIcon mchecked={props.checked}/>
            </Card.Link>
          </td>}
        </td>
        <td className="fw-bold border-0">
          {name}
        </td>
        <td className="border-0">
          {status}
        </td>
      </tr>
    );
  };
  const MicroIcon = () => {return (<OverlayTrigger
    placement="left"
    trigger={['hover', 'focus']}
    overlay={
      <Tooltip>Microsoft Excel</Tooltip>
    }>
  <FontAwesomeIcon icon={faMicrosoft}></FontAwesomeIcon></OverlayTrigger>);};
  const GoogleIcon = () => {return (<OverlayTrigger
    placement="left"
    trigger={['hover', 'focus']}
    overlay={
      <Tooltip>Google Sheets</Tooltip>
    }><FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon></OverlayTrigger>);};
  
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
            {!props.checked ? <th className="border-0">Downloads</th> :
            <OverlayTrigger
                  /* trigger="click" */
                  placement="left"
                  overlay={
                    <Popover>
                      <Popover.Title> Google Sheets / Excel</Popover.Title>
                      <Popover.Content> There is now the option to download for Google Sheets {<GoogleIcon/>} or for Excel {<MicroIcon/>}  For Excel please enable macros upon opening. </Popover.Content>
                    </Popover>
                  }><th className="border-0">
              <>{"Downloads"}&nbsp;&nbsp;&nbsp;</>
                
                {/* <p className="text-muted font-small m-0">
                  {"for Google"} &nbsp;
                  <GoogleIcon/>
                  {" or Excel"} &nbsp;
                  <MicroIcon/>
                  {""}
                </p> */}
              </th></OverlayTrigger>}
              <th className="border-0">Name</th>
              <th className="border-0">Status</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map(r => <TableRow checked={props.checked} key={`report-${r.id}`} {...r} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const AllowedUserTable = (props) => {
  const TableRow = (props) => {
    const { icon, email } = props;
    const [inProgress, setInProgress] = useState(false);
    const toastRef = useRef(0);
    return (
      <> <QuickToastMessage  ref={toastRef}/>
      <tr>
        {/* <td className="border-0">
          <Card.Link 
          onClick={async()=>{
            toastRef.current.showMessage('Signing into ' + email + '. To sign out click on the top right after sign in.', 4500);
            await timeout(5000);
            // window.location.href = '/sign-in/' + email;
          }}
          className="d-flex align-items-center">
            <FontAwesomeIcon icon={icon} className="image-small rounded-circle me-2" />
          </Card.Link>
        </td> */}
        <td className="border-0">
          {email}
        </td>
        <td className="border-0">
          <Card.Link onClick={()=>{//setInProgress(true); 
          props.remove(email)}} 
          className="d-flex align-items-center">
            <FontAwesomeIcon icon={faTrashAlt} className="image-small rounded-circle me-2" />
            <div>{inProgress ? <CircularProgress size={20}/> : ''}</div>
          </Card.Link>
        </td>
      </tr>
      </>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              {/* <th className="border-0">Icon</th> */}
              <th className="border-0">Email</th>
              <th className="border-0">Remove</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map(r => <TableRow key={`report-${r.id}`} update={props.update} {...{...r, remove: props.remove}} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const fecthJSON = async (url, f, p) =>{
  return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'key': 'super secret key',
      },
      body: JSON.stringify({ process_id: p, file_name: f })
    })
    .then((response) => response.json())
    .then((data) => {
        if(typeof(data) == 'string'){
          var _data = JSON.parse(data);
        }else{var _data = data;}
        //captureData(_data);
        return _data;
    })
}

const afecthJSON = async (url, f) =>{
  return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'key': 'super secret key',
      },
      body: JSON.stringify({ process_id: 'streport', file_name: f })
    })
    .then((response) => response.json())
    .then((data) => {
        if(typeof(data) == 'string'){
          var _data = JSON.parse(data);
        }else{var _data = data;}
        return _data;
    })
}
const timeout = (delay) => {
  return new Promise( res => setTimeout(res, delay) );
}
const P = styled.p`
  min-width: 30px;
`;
const GetStatus = forwardRef((props, ref) => {
  const { status } = props;
  
  return (
    // <OverlayTrigger
    // placement="top"
    // trigger={['hover', 'focus']}
    // overlay={
    //   <Tooltip>
    //     {textInput!=''?'google: '+textInput:''}
    //     {textInput!=''&&text2!=''?', ':''}
    //     {text2!=''?'excel: '+text2:''}</Tooltip>
    // }>
  <P>{!!status ? (String(status) !== 'undefined' ? status: 'waiting') : 'waiting'}</P>
  // </OverlayTrigger>
  );
});

function Name(props){
  const {name} = props;
  // const canEdit = typeof(og_name) == 'string' || typeof(og_name2) == 'string';
  // const [editing, setEditing] = useState(false);
  // const [newName, setNewName] = useState(name.slice(0, -5));
  // const [ext, setExt] = useState(name.slice(-5));
  // const click = () => {
  //   if(editing){
  //     if(typeof(og_name) == 'string'){
  //       edit(og_name);
  //       toastRef.current.showMessage('Name change requested.', 3000);

  //     }
  //     // if(typeof(og_name2) == 'string'){
  //     //   edit(og_name2);
  //     // }
  //   }
  //   setEditing(!editing);
  // }

  // const edit = (og) => {
  //   fetch('/add-translation', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'key': 'super secret key',
  //     },
  //     body: JSON.stringify({ name: og, translation: newName+og.slice(-5)})
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //       if(typeof(data) == 'string'){
  //         var _data = JSON.parse(data);
  //       }else{var _data = data;}
  //       return _data;
  //   })
  // }

  // const onCHange = (e) => {
  //   if(typeof(e.target.value) == 'string' && e.target.value != ''){
  //     setNewName(e.target.value);
  //   }
  // }

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     event.preventDefault();  // Form.Control refreshes the page on enter. this disables that feature
  //     click();
  //   }
  // }

  return <p> {name} </p>
  // (<>
  // {editing ? 
  // <Input defaultValue={newName} onChange={onCHange} onKeyDown={handleKeyDown} autoFocus onBlur={click}/> 
  // : <Card.Link onClick={click}>{newName}</Card.Link>} 
  
  // {canEdit ? 
  // <>
  // <div style={{display: "flex", float:"right"}}>
  // {!editing && props.available ? <Card.Link onClick={click} style={{paddingLeft: '10px'}}>
  //   <FontAwesomeIcon icon={faPenAlt} /* onClick={click} style={{"&:hover": { cursor: "pointer" }}} *//> 
  // </Card.Link> : ''}
  // {props.available ? <Form style={{paddingLeft: '10px'}}>
  //   <Form.Check onChange={(e) => {props.func(e.target.checked, name.slice(0, -5), newName, og_name, og_name2)}}/>
  // </Form> : ''}
  
  // </div>
  // </>
  // : ''}
  // </>);
}

const checkData = (key, baseValue) => {
  let v = localStorage.getItem(key);
  let init = tryParseJSON(v, _v=>null);
    init = isObject(init) || Array.isArray(init) ? init : baseValue
    return init;
}

const setStroage = (key, value) => {
  localStorage.setItem(key, tryStringifyJSON(value, _v=>String(_v)))
}

export const STReportTable = (props) => {
  const {overrideEmail} = props;
  let dataKey = `${overrideEmail}.${props.process_id}TableData`;
  let initData = checkData(dataKey, []);
  const [data, _setData] = useState(initData);

  const [dataCompleted, _setDataCompleted] = useState([]);
  const setDataCompleted = useCallback((e) => { _setDataCompleted(e); }, [dataCompleted]);
  const updateUser = useCallback(async () => {
    let i = 0;
    while(i < dataCompleted.length) {
      while(!dataCompleted[i]) timeout(1000);
    }
  }, []);

  const [autoRefresh, _setAutoRefresh] = useState(false);
  const setAutoRefresh = useCallback((e) => { _setAutoRefresh(e); }, [autoRefresh]);

  const setData = (obj) => {_setData(obj);
    localStorage.setItem(dataKey, JSON.stringify(obj));
  }

  const refresh = async (v, force=true)=>{
    let a = v;
    if(v === undefined || a === null) a = {status: true}
    const __email = overrideEmail ? overrideEmail : (await getEmail());
    if(`${__email}` == '[object Promise]'){
        await __email.then(d=>{console.log(d)})
    }
    if(!a.status) return;
    let j = ''
    try{
      await getS3Json(`jobs/${__email}/${props.process_id}.json`)
      .then(d=>{
        j = d;
      })
      if(!a.status) return;
      // console.log('jdata', typeof(j), j);
      if(Array.isArray(j)){
        setDataCompleted([]);
        setData(j);
      }
    }catch(e){console.log(e)}

  }

  const continualRefresh = async(alive) => {
    while(alive.status){
      await refresh(alive);
      await timeout(10000);
      // await updateUser();
    }
  }

  useEffect(()=>{
    let alive = {status: true};
    continualRefresh(alive);
    // if(autoRefresh) continualRefresh(alive);
    // else 
    refresh(alive);
    let a = localStorage.getItem(`${overrideEmail}.autoRefreshTables`);
    
    if(typeof a !== 'boolean') {a = false; localStorage.setItem(`${overrideEmail}.autoRefreshTables`, a);}
    setAutoRefresh(a);
    return () => alive.status = false;
  }, [])

  const TableRow = (props) => {
    const { job, id, name, status } = props;
    const [doc, setDoc] = useState(null);
    let key = `${overrideEmail}${job}${id}values`
    let init = checkData(key, null);//{status: 'waiting', display_name: '...'});
    let nowSeconds = (parseFloat(Date.now()) / 1000.);
    const timeToSkip = 60*60*24;

    if(init != null) {if(nowSeconds - init.start_time > timeToSkip && !!init.status){ init = {...init, 'skip': true} }}
    init = init === null ? {status: 'waiting', display_name: '...'} : init;
    if(!!status) init = {...init, status: status}
    const [values, _setValues] = useState(init)
    const setValues = (obj) => {_setValues(obj);localStorage.setItem(key, JSON.stringify(obj));}
    const [link, setLink] = useState(window.location.href);
    const [loading, setLoading] = useState(false);
    const [working, setWorking] = useState(true);
    

    const objectCheck = (obj) => {
      if(isObject(obj)){
        if('display_name' in obj && 'status' in obj){
          return true;
        }//else{console.log('not keys')}
      }//else{console.log('not ob')}
      return false;
    }

    const rowRefresh = async() => {
      // let pos = 0;
      // _setDataCompleted(
      //   prev=>{ pos = prev.length; return prev.concat([false]);}
      // )
      if(isObject(values)){if('skip' in values){if(values.skip){ console.log('skipped', values)
          return;}}}
        const email = overrideEmail ? overrideEmail : (await getEmail());
        if(`${email}` == '[object Promise]'){ await email.then(d=>{console.log(d)}); }
        let tries = 3;
        let loadedDoc = false;
        for (let i = 0; i < tries; i++) {try{
          let v = null;
          await getS3Json(`docs/${id}.json`)//`${email}/docs/${id}.json`)
          .then(val=>v=val)
          .catch(e=>console.log('doc error', e))

          console.log('doc', v);
          if(objectCheck(v)) {
            if(v.status == 'done') {
              //setLink(await getRenamedS3Link(`UploadFiles/${email}/${v.this_file.name}`, v.display_name))
              let location = `UploadFiles/${email}/${v.this_file.document_id}/${v.this_file.name}`;
              location = await getDownloadLocation(`${v.this_file.document_id}/${v.this_file.name}`);
              setLink(await getS3Link(location))
            }

            setValues(v);
            loadedDoc = true;
          }
          else {await delay(5000); continue}

          break;
        }catch(e){console.log(e); await delay(1000);}}

        if(!loadedDoc){
          let _status = await jobStatus(job)
          .then(v=>{
            console.log('job status: ', v)
            let _status = String([''].includes(v) ? 'waiting' : ['SUCCEEDED'].includes(v) ? 'error' : v)
            _status = _status.toLowerCase()
            setValues({status: _status, display_name: '...'})
          })
          .catch(e=>console.log(e))
        }
        // _setDataCompleted(
        //   prev=>{ prev[pos] = true; return prev;}
        // )
    }

    

    useEffect(()=>{
      getS3Json(`docs/${id}.json`)//`${email}/docs/${id}.json`)
      .then(async v=>{
        setDoc(v);
        console.log('docc', id, v)
        if(objectCheck(v)) {
          setValues(v);
          if(v.status == 'done') {
            
            let location = await getDownloadLocation(`${v.this_file.document_id}/${v.this_file.name}`);
            setLink(await getS3Link(location))
          }
        }
      })
      .catch(e=>null)
      const getJobStatus = async() => {
        let s = await jobStatus(job);
        let v =  !['SUCCEEDED', 'FAILED'].includes(String(s));
        try{if(!('asins' in values.extra)) {return false;}}catch(e){}
        return v
      }
      (async()=>{
        let s = await cacheItem(`reportJobWorking${job}`, getJobStatus, true, undefined, true, false)
        setWorking(s);
      })()
      
          
      // if(!status) rowRefresh();
    }, [])

    // const MicroIcon = (props) => {return (<>{!props.mchecked ? <FontAwesomeIcon {...{...props, icon: faMicrosoft}} ></FontAwesomeIcon> : 
    // <OverlayTrigger
    //   placement="left"
    //   trigger={['hover', 'focus']}
    //   overlay={
    //     <Tooltip>Download for Excel</Tooltip>
    //   }>
    // <FontAwesomeIcon icon={faMicrosoft}></FontAwesomeIcon></OverlayTrigger>}</>);};
    // const GoogleIcon = (props) => {return (<>{!props.mchecked ? <FontAwesomeIcon {...{...props, icon: faGoogle}}></FontAwesomeIcon>
    //  : <OverlayTrigger
    //   placement="left"
    //   trigger={['hover', 'focus']}
    //   overlay={
    //     <Tooltip>Download</Tooltip>
    //   }><FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon></OverlayTrigger>}</>);};
    const [redoLoading, _setRedoLoading] = useState(false);
    const setRedoLoading = useCallback((e) => { _setRedoLoading(e); }, [redoLoading]);
    return (
      <tr >
        <td className="border-0">
        {values.status == 'done' ? <Card.Link href={link} className="d-flex align-items-center">
              {!values ? '' : !values.this_file ? '' : !values.this_file.name ? '' : values.this_file.name.endsWith('.xlsx') ? <GoogleIcon mchecked={true}/> : <MicroIcon mchecked={true}/>}
            </Card.Link> : ''}
        {/* {icon ?  <GoogleIcon color='white'/>:
          <td className="border-0">
            <Card.Link href={link} className="d-flex align-items-center">
              <GoogleIcon mchecked={props.checked}/>
            </Card.Link>
            </td>}
            {icon2 ? <MicroIcon color='white'/>:
          <td className="border-0">
            <Card.Link href={link2} className="d-flex align-items-center">
              <MicroIcon mchecked={props.checked}/>
            </Card.Link>
          </td>} */}
        </td>
        <td onClick={()=>{if(isDev()){props.toastRef.current.showMessage(JSON.stringify(doc, undefined, 2), undefined)}}}
        className="fw-bold border-0">
          <Name name={name} /> 
        </td>
        <td className="border-0">
          <div style={{display: 'flex'}}>
          <GetStatus status={status || values.status} />
            <div><p>&nbsp;</p></div>
            {isDev() ? <div style={{float: 'right', cursor: 'pointer'}}
            >
              {!redoLoading  ? 
              <OverlayTrigger
              placement="right"
              trigger={['hover', 'focus']}
              overlay={
                <Tooltip> Redo / repair a report. </Tooltip>
              }>
              <FontAwesomeIcon icon={faTools}
                onClick={()=>{
                  if(!values.this_file) {
                    console.log('didnt work', values);
                    console.log(JSON.stringify(values))
                    return;}
                  setRedoLoading(true);
                  const setFalse = async() => {await timeout(5000); setRedoLoading(false);}
                  Api('document', '/document', undefined, {'doc': values}).then(v=>{
                    setFalse();
                  console.log('redo', values, JSON.stringify(values));
                  }).catch(e=>setFalse());
                }}/> 
          </OverlayTrigger> : true ? <CircularProgress size={20}/> : ''}
            </div> : ''}
          </div>
        </td>
      </tr>
    );
  };
  const MicroIcon = () => {return (<OverlayTrigger
    placement="left"
    trigger={['hover', 'focus']}
    overlay={
      <Tooltip>Microsoft Excel</Tooltip>
    }>
  <FontAwesomeIcon icon={faMicrosoft}></FontAwesomeIcon></OverlayTrigger>);};
  const GoogleIcon = () => {return (<OverlayTrigger
    placement="left"
    trigger={['hover', 'focus']}
    overlay={
      <Tooltip>Download Icon</Tooltip>
    }><FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon></OverlayTrigger>);};
  
  const SquishButton = styled(Button)`
    &:active {
      transform: scale(1.05);
    }
  `; //(props) => {
    // return (<Button {...props}> {props.children ? props.children : ''} </Button>)
  // }

  const [isLoading, setIsLoading] = useState('');
  return (<>
  <SquishButton onClick={async()=>{
    setIsLoading('.')
    refresh({status: true});
    await timeout(500);setIsLoading('..');
    await timeout(500);setIsLoading('...');
    await timeout(2000);setIsLoading('..');
    await timeout(500);setIsLoading('.');
    await timeout(500);setIsLoading('');
    }}> refresh {isLoading} </SquishButton>
  {/* <OverlayTrigger
      placement="left"
      trigger={['hover', 'focus']}
      overlay={
        <Tooltip>Turn this off to have tables refresh autimatically. This may help with the performance of this page if it's slow. </Tooltip>
      }><SquishButton onClick={()=>{localStorage.setItem('autoRefreshTables', !autoRefresh);setAutoRefresh(!autoRefresh)}}> {`turn ${!autoRefresh ? 'on' : 'off'} manual refresh`} </SquishButton></OverlayTrigger> */}
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
            {!props.checked ? <th className="border-0">Downloads</th> :
            <OverlayTrigger
                  /* trigger="click" */
                  placement="left"
                  overlay={
                    <Popover>
                      <Popover.Title> Downloads </Popover.Title>
                      <Popover.Content> Click the {<GoogleIcon/>} to donwload the file. </Popover.Content>
                    </Popover>
                  }><th className="border-0">
              <>{"Downloads"}&nbsp;&nbsp;&nbsp;</>
                
                {/* <p className="text-muted font-small m-0">
                  {"for Google"} &nbsp;
                  <GoogleIcon/>
                  {" or Excel"} &nbsp;
                  <MicroIcon/>
                  {""}
                </p> */}
              </th></OverlayTrigger>}
              <th className="border-0">Name</th>
              <th className="border-0">Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(r => <TableRow checked={props.checked} key={`report-${r.id}`} {...{...r, len: props.items.length, process_id: props.process_id, func: props.func, toastRef: props.toastRef}} />)}
            {/* {props.items.map(r => <TableRow checked={props.checked} key={`report-${r.id}`} {...{...r, len: props.items.length, process_id: props.process_id, func: props.func, toastRef: props.toastRef}} />)} */}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
    </>);
};