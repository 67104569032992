import React from 'react'
import { GoogleSignIn } from '../GoogleSignIn';
//import GoogleLogin from 'react-google-login';
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute, Text} from './SidebarElement';
import { Auth } from 'aws-amplify';
import { Routes } from 'src/routes';

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="about"  onClick={toggle}> About </SidebarLink>
                <SidebarLink to="discover"  onClick={toggle}> Discover </SidebarLink>
                <SidebarLink to="services"  onClick={toggle}> Services </SidebarLink>
                <SidebarLink to="signup"  onClick={toggle}> Sign Up </SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute onClick={()=>{toggle();Auth.federatedSignIn({ provider:"Google" })//window.location.href="/dashboard/overview"
              }}>Dashboard</SidebarRoute>

                {/* <AwesomeButton onPress={()=>{window.location.href = '/report'}} 
                type='primary'> 
                    Get Report 
                </AwesomeButton> */}
            </SideBtnWrap>
            <p></p>
            <SideBtnWrap>
                <SidebarRoute onClick={()=>{toggle();window.location.href=Routes.NewDashbord.path;
              }}>New Dashboard</SidebarRoute>
            </SideBtnWrap>
            <p></p>
            <SideBtnWrap>
              <Text> or </Text>
            </SideBtnWrap>
            <p></p>
            <SideBtnWrap>
              <Text> Sign In with </Text><GoogleSignIn opt={5}/>
            </SideBtnWrap>
            {/* <SideBtnWrap>
              <SidebarRoute onClick={()=>{window.location.href = '/login'}}>Sign In</SidebarRoute>
            </SideBtnWrap>
            <p></p> */}
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
