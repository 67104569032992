import React from "react";

let LOCALHOST = false;
let base_url = LOCALHOST ? 'http://localhost:3000' : 'https://orfos.website';

export const awsConfig = {
    "aws_project_region": process.env.REACT_APP_AUTH_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AUTH_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AUTH_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    //"oauth": {},
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        //identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AUTH_REGION, 
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID, 
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    },
    "oauth": {
        "domain":'junglr.auth.us-east-1.amazoncognito.com',// process.env.REACT_APP_AUTH_DOMAIN,
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": base_url,// + '/dashboard/overview',//process.env.REACT_APP_AUTH_REDIRECT_SIGNIN,
        "redirectSignOut": base_url, // process.env.REACT_APP_AUTH_REDIRECT_SIGNOUT,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export function editConfig(config){
    let awsConfig = config
    /* const config = awsConfig; let _ = {
        "aws_project_region": process.env.REACT_APP_AUTH_REGION,
        "aws_cognito_identity_pool_id": process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
        "aws_cognito_region": process.env.REACT_APP_AUTH_REGION,
        "aws_user_pools_id": process.env.REACT_APP_AUTH_USER_POOL_ID,
        "aws_user_pools_web_client_id": process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
        "oauth": {},
        Auth: {
            // REQUIRED - Amazon Cognito Identity Pool ID
            identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
            // REQUIRED - Amazon Cognito Region
            region: process.env.REACT_APP_AUTH_REGION, 
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID, 
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
        }
    }; */
    
    const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
    
    let productionBaseUrl = window.location.origin  // 'https://dev.dsg356dch93dv.amplifyapp.com'
    
    // Assuming you have two redirect URIs, and the first is for localhost and second is for production
    let [
    localRedirectSignIn,
    productionRedirectSignIn,
    ] = [awsConfig.oauth.redirectSignIn.split(",")[0], productionBaseUrl];
    
    let [
    localRedirectSignOut,
    productionRedirectSignOut,
    ] = [awsConfig.oauth.redirectSignOut.split(",")[0], productionBaseUrl];
    
    function getEndpoint (url) {
        const get_domain = (s) => {
            let domain = s.split('/')[0]//'' 
            //for c in s:
            //    if c == '/':
            //        break
            //    domain += c
            return s.replace(domain, '')
        }
        if (url.startsWith('http://')){
            url = get_domain(url.slice(7))}//[7:])}
        else if (url.startsWith('https://')){
            url = get_domain(url.slice(8))}//[8:])}
        else { 
            url = get_domain(url)}
        return url
    }
    productionRedirectSignIn = productionBaseUrl + getEndpoint(localRedirectSignIn)
    productionRedirectSignOut = productionBaseUrl + getEndpoint(localRedirectSignOut)
    
    let is = {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-1:c55d64ea-4765-4c7c-800a-2f96593bfa9a',

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'us-east-1',
    }
    Object.keys(is).map(k=>{
        if(!(k in awsConfig)){
            const e = is[k]
            awsConfig[k] = e
        }
    })
    
    
    const updatedAwsConfig = {
        ...awsConfig,
        oauth: {
            ...awsConfig.oauth,
            redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
            redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
        }/* ,  // https://docs.amplify.aws/lib/restapi/getting-started/q/platform/js/#manual-setup-reference-existing-rest-api
        API: {
            endpoints: [
                {
                    name: "test",
                    endpoint: "https://i4ufiz2ffcjz4c3izppgdidu7a0ijgwb.lambda-url.us-east-1.on.aws/",
                    service: "lambda",
                    region: "us-east-1"
                }
            ]
        } */
    }

    return updatedAwsConfig;
}