import {useEffect, useState, forwardRef, useCallback} from 'react';
import { STReport } from './Inputs';
import { Api } from 'src/auth/APIHandler';
// import { Button } from '@themesberg/react-bootstrap';
import { Button as MuiButton, CircularProgress, Input } from "@mui/material";
import { delay, isObject } from 'src/utils';
import { getGoogleChildrenFolder, getGoogleDrives } from 'src/utils/GoogleHelp';
import QueryReport from './Report';
import { getS3Json } from 'src/auth/util';
import { test2 } from './testQuery';

const RequireGoogleDriveLoggin = forwardRef((props, ref)=>{
    const {children} = props;
    const [loggedIn, setLoggedIn] = useState('loading');
    

    const get = async() => {
        let v = await Api('googleapi', '/googleapi', undefined, {api: 'logged_in'});
        console.log('gotten', v);
        if(!isObject(v)) {setLoggedIn('not-logged-in')}
        else if(!isObject(v.logged_in)) {setLoggedIn('not-logged-in')}
        else {setLoggedIn(v.logged_in.result ? 'logged-in' : 'not-logged-in')}
    }
    const googleHandle = () => {
        console.log('gool hit');
        (async ()=>{
          let name = 'googlepermissions'; // 'test3'
          let endpoint = '/google-permissions';  // '/test3'
          let obj = await Api(name, endpoint, undefined, {uri: ''});
          console.log('gool obj', obj);
          if(obj.hasOwnProperty('redirect')){
            console.log('gool sent');
            // https://www.w3schools.com/jsref/met_win_open.asp
            window.open(obj.redirect, '_blank', 'height=500,width=400');//,menubar=no,titlebar=no
          }
        })();
      }
    useEffect(()=>{
        get();
    }, [])
    return <>
        {loggedIn === 'loading' ? <CircularProgress size={20}/> : 
         loggedIn === 'not-logged-in' ? <MuiButton onClick={googleHandle}> google button </MuiButton> : 
         children
        }
    </>
})

const Ok = props => {
    const [display, _setDisplay] = useState('yay');
    const setDisplay = useCallback(v=>{
        _setDisplay(v);
    }, [display])
    const wow = async() => {
        setDisplay(prev=>'hiya ' + prev)
        return;
        let drives = await getGoogleDrives();
        [
            {
                "id": "0ADI9ffXZEfzeUk9PVA",
                "name": "My Drive"
            },
            {
                "id": "0AK-Aj8Z4i7soUk9PVA",
                "name": "Junglr Team Drive",
                "kind": "drive#drive"
            }
        ]
        setDisplay(JSON.stringify(drives, null, 2));
        let drive = drives[1]
        let v = await getGoogleChildrenFolder(drive['id'], '1bvRfvCLp21DSnYaBECLwnpJ6FMJ0PVJp');//drive['id']);
        await delay(1000);
        setDisplay(JSON.stringify(v, null, 2))
    }
    useEffect(()=>{
        wow();
    }, [])

    return <pre> {display} </pre>
}

export default function TestReport(){
    const [display, setDisplay] = useState('yay');
    const [loggedIn, setLoggedIn] = useState('loading')
    const [test, setTest] = useState(null);
    const get = async() => {
        console.log('fetching s3')
        setTest(await getS3Json("reports/search_term_report.json"))
        console.log('fetched s3')
    }
    useEffect(()=>{
        get();
    }, [])
    return <>
        {/* <STReport/> */}
        <QueryReport config={test//test2
        }/>
        {/* <pre> {JSON.stringify(test, null, 3)} </pre> */}
        {/* <RequireGoogleDriveLoggin>
            <Ok/>
        </RequireGoogleDriveLoggin> */}
    </>
}

