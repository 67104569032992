import React, {forwardRef} from 'react';
import { useSearchParams } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

function parse_query_string(query) {
    var vars = query.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      var key = decodeURIComponent(pair.shift());
      var value = decodeURIComponent(pair.join("="));
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = value;
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        var arr = [query_string[key], value];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(value);
      }
    }
    return query_string;
  }

//   var query = window.location.search.substring(1);
// var qs = parse_query_string(query);


export const Page = forwardRef((props, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const hidden = false;
    const route = 'authenticated'
    const email = 'ok@example.com'

    const directToLogin = (url, region) => {
        let params = `url=${url}&region=${region}`;
        const client = searchParams.get('client');
        if(client && typeof client === 'string'){
            params = `${params}&client=${client}`;
        }
        window.open(
            'https://zatk56r7rv4dhgkwhzy3f5xyoi0elarh.lambda-url.us-east-1.on.aws/auth?' + params
        )
    }

    const Button = props => {
        const {title, regions, onClick} = props;

        return <Tooltip title={typeof regions === 'string' ? regions : ''}><div style={{ textAlign: 'center', padding: '2%', margin: '3%', borderRadius: '15px', width: '10%', height: '7%', zIndex: 999, display: 'table', cursor: 'pointer', 
        backgroundColor: '#00a59e'}}
        onClick={onClick}>
          <p style={{fontWeight: 800, fontFamily: 'Poppins', color: 'white', textAlign: 'center', verticalAlign: 'middle', display: 'table-cell', cursor: 'pointer'}}>{title}</p>
        </div></Tooltip>
    }
    return <div className='fade-in' style={{transition: 'all 1s ease-in-out'}}>
    {/* <div style={{ position: 'relative', float: 'right', textAlign: 'center', padding: '.5%', margin: '1%', borderRadius: '15px', width: '10%', height: '7%', zIndex: 999, display: 'table', cursor: 'pointer', backgroundColor: '#ff3857'}}
    >
      <p style={{fontWeight: 800, fontFamily: 'Poppins', color: 'white', textAlign: 'center', verticalAlign: 'middle', display: 'table-cell', cursor: 'pointer'}}>Sign Out</p>
    </div> */}
    <div style={{zIndex: -5, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
     <div style={{zIndex: -5, backgroundColor: '#ccf656', width: '30%', height: '90%', borderRadius: '15px'}}>
     
      </div>
      </div>
    <div style={{zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}> 
      <div style={{zIndex: 10, backgroundColor: 'white', borderRadius: '15px', padding: '5%', paddingLeft: '10%', paddingRight: '10%'}}>
      <p style={{fontSize: '225%', fontWeight: 800, color: '#ff3857', fontFamily: 'Poppins', zIndex: 99, paddingBottom: '0%'}}> 
           Welcome! </p> 
        <pre style={{fontSize: '125%', color: '#000000', fontFamily: 'Roboto'}}> {`SP-API Access\nPlease click the region(s) of your Amazon Account`}
          <pre style={{fontSize: '75%', color: '#ababab', fontFamily: 'Roboto'}}>{`note: \n    If you are under more than one region, you will need to sign in for each one. Thank you`}</pre>
          <Button title='North America' regions='Canada, US, Mexico, Brazil' onClick={()=>directToLogin('https://sellercentral.amazon.com', 'NA')}/>
          <Button title='Europe' regions='Spain, UK, France, Netherlands, Germany, Italy, Sweden, Poland, Egypt, Turkey, Saudi Arabia, U.A.E., India, Belgium' onClick={()=>directToLogin('https://sellercentral-europe.amazon.com', 'EU')}/>
          <Button title='Far East' regions='Singapore, Australia, Japan' onClick={()=>directToLogin('https://sellercentral.amazon.com.au', 'FE')}/>
          {/* <p style={{fontSize: '125%', fontWeight: 800, cursor: 'pointer', color: '#ff3857', fontFamily: 'Poppins', zIndex: 99}} onClick={()=>directToLogin('https://sellercentral.amazon.com')}> 
            North America </p> 
          <p style={{fontSize: '125%', fontWeight: 800, cursor: 'pointer', color: '#ff3857', fontFamily: 'Poppins', zIndex: 99}} onClick={()=>directToLogin('https://sellercentral-europe.amazon.com')}> 
            Europe </p> 
          <p style={{fontSize: '125%', fontWeight: 800, cursor: 'pointer', color: '#ff3857', fontFamily: 'Poppins', zIndex: 99}} onClick={()=>directToLogin('https://sellercentral.amazon.com.au')}> 
            Far East </p>  */}
          </pre> 
        </div>
    
     
    </div>
    {/* <div>
      {hidden ? <></> : <pre>{JSON.stringify(email.data, undefined, 3)}</pre>}
    </div> */}
    {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: "50%", left: 0, width: '100%', height: '50%'}}>
      {hidden ? <pre> {!email ? '' : `   It appears your signed in with "${email}"\n which is no auhenticated with Junglr.\n Please contact Junglr to resolve this if you believe this is an error.\n`} 
        <p style={{fontWeight: 800, cursor: 'pointer', color: '#ff3857', fontFamily: 'Poppins'}} onClick={logOut}> Sign Out</p> </pre> : <></>}
    </div> */}
     {/* <pre style={{position: 'absolute', top: '100%', left: 0}}> config: {JSON.stringify(rest, undefined, 4)}</pre> */}
  </div>
})


export default Page;
