import React, {useState} from 'react';
//import Video from '../../../videos/video.mp4';
import Image from '../../../videos/bg3.jpg';
import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, ImgBg, BackDrop} from './HeroElements';
import {Button, Button2} from '../ButtonElement';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import {Link} from 'react-scroll';
import { theme } from '../Theme';
import { Routes } from 'src/routes';

const CompanyButton = styled(Link)`
    z-index: 999;
    border-radius: 50px;
    background: ${({primary}) => (primary ? theme.color1 : theme.darkColor1)};//'#01BF71' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${theme.lightColor3};
    font-size: ${({fontbig}) => (fontbig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    overflow: visible;

    &:hover {
        transition: all 0.2s ease-in-out;
        /* background: ${({primary}) => (primary ? theme.lightColor3 : theme.lightColor3)}; */
        color: white;
    }
`;

export const greeting = () => {
  var today = new Date()
  var curHr = today.getHours()
  var greet = '';
  if (curHr < 12) {
    greet = ('Good morning')
  } else if (curHr < 18) {
    greet = ('Good afternoon')
  } else {
    greet = ('Good evening')
  }
  return greet;
};

export const sendEmail = (to='daniel@junglr.com', subject='Getting Started with Junglr Data Managment', body=null, cc=null) => {
  if(body == null){body = `${greeting()} Junglr,\n\n\n`}
  var link = (`mailto:${to}?`
           + (cc ? `cc=${cc}&` : '')  // `?cc=myCCaddress@example.com`
           + "subject=" + encodeURIComponent(subject)  // "This is my subject")
           + "&body=" + encodeURIComponent(body))  // document.getElementById('myText').value))
  ;
  // window.open(link, '_blank');
  window.location.href = link;
}

const HeroSection = () => {
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const onHover2 = () => {
    setHover2(!hover2);
  }; 
  const onHover3 = () => {
    setHover3(!hover3);
  };
  const onHover4 = () => {
    setHover4(!hover4);
  }

  return (
    <HeroContainer id="Home">
        <HeroBg>
            {/* <VideoBg autoPlay loop muted src={Video} type="video/mp4">

            </VideoBg> */}
            <ImgBg src={Image}></ImgBg>
        </HeroBg>
        <BackDrop>
          <HeroContent>
              <HeroH1> Junglr App </HeroH1>
              <HeroP> Effortlessly Take Control of Amazon Data </HeroP>
              <HeroBtnWrapper>
              <CompanyButton style={{background: `rgba(0.0,0.0,0.0,${hover3 ? '0.0' : '0.0'})`, borderStyle: 'solid', borderColor: hover3 ? '#fff' : `#00000015`}}
              onClick={()=>{
                    window.open('https://www.junglr.com/')
                  }} smooth={true} duration={800} onMouseEnter={onHover3} onMouseLeave={onHover3}
                  primary="true" dark="true" fontbig="true"
                  > Company Site {hover3 ? <ArrowForward/> : <ArrowRight/>} </CompanyButton>

                  <Button onClick={()=>{
                    sendEmail();
                  }} smooth={true} duration={800} onMouseEnter={onHover2} onMouseLeave={onHover2}
                  primary="true" dark="true" fontbig="true"
                  > Get In Contact {hover2 ? <ArrowForward/> : <ArrowRight/>} </Button>

                  {/* <Button2 onClick={()=>{
                    Auth.federatedSignIn({ provider:"Google" })//window.location.href="/dashboard/overview"
                  }} smooth={true} duration={800} onMouseEnter={onHover4} onMouseLeave={onHover4}
                  primary="true" dark="true" fontbig="true"
                  > New Dashboard {hover4 ? <ArrowForward/> : <ArrowRight/>} </Button2> */}

                  <Button onClick={()=>{
                    Auth.federatedSignIn({ provider:"Google" })//window.location.href="/dashboard/overview"
                  }} smooth={true} duration={800} onMouseEnter={onHover} onMouseLeave={onHover}
                  primary="true" dark="true" fontbig="true"
                  > Members Dashboard {hover ? <ArrowForward/> : <ArrowRight/>} </Button>
                
                <CompanyButton style={{background: `rgba(0.0,0.0,0.0,${hover4 ? '0.0' : '0.0'})`, borderStyle: 'solid', borderColor: hover4 ? '#fff' : `#00000015`}}
                  onClick={()=>{
                    window.open(Routes.NewDashbord.path);
                  }} smooth={true} duration={800} onMouseEnter={onHover4} onMouseLeave={onHover4}
                  primary="true" dark="true" fontbig="true"
                  > New Dashboard {hover4 ? <ArrowForward/> : <ArrowRight/>} </CompanyButton>
                 

              
              </HeroBtnWrapper>
              
          </HeroContent>
          
          
        </BackDrop>
        
    </HeroContainer>
  )
}

export default HeroSection