import React from 'react';
import CBRForm from './CBR/index';
// import { ToastServerMessage } from './Custom/GetMessage';
import Table from './STR/STRTable';


export default () => {

  return (<>
      <div className='container'>
        
        <div className='MyForm'>
          <CBRForm/>
        </div>
        <div className='MyTable'>
          <Table checkURL='cbreport' tableURL='/cbreportdata' api='cbreportdata' process_id='cbreport'/>
        </div>
      </div>
    </>);
};


// import React, {useState, useEffect} from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faInfo, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
// import { Col, Row, Button, Dropdown, Form, InputGroup, Modal, OverlayTrigger } from '@themesberg/react-bootstrap';
// import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
// import { GeneralInfoForm } from "../components/Forms";
// import axios from "axios";
// import Progress from "../components/Progress";

// import Profile3 from "../assets/img/team/profile-picture-3.jpg";

// import { ReportTable, TransactionsTable } from "../components/Tables";
// import Table from './STR/STRTable';
// //import { pageReport } from "../data/tables";

// import { faFileAlt as faFile } from "@fortawesome/free-solid-svg-icons";
// import { CircularProgress, LinearProgress, Tooltip } from "@mui/material";
// import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
// import SavedCheck from "../components/Custom/SavedCheckbox";
// const pageReport = [
//   { id: 1,  icon: faFileAlt, icon2: faFileAlt, status: 76, name: '', link: '#', link2: 'false' },
//   { id: 2, icon: faFileAlt, icon2: faFileAlt, status: 555, name: '', link: '#/settings', link2: '#/settings' },
// ];

// const MicroIcon = (props) => {return (<>{!props.enabled 
//   ? <FontAwesomeIcon color='white' icon={faMicrosoft} ></FontAwesomeIcon>
//  : (!props.checked ? <FontAwesomeIcon {...{...props, icon: faMicrosoft}} ></FontAwesomeIcon> 
//  : <OverlayTrigger
//       placement="left"
//       trigger={['hover', 'focus']}
//       overlay={
//         <Tooltip>Download for Excel</Tooltip>
//       }>
//     <FontAwesomeIcon icon={faMicrosoft}></FontAwesomeIcon></OverlayTrigger>)}</>);};
// const GoogleIcon = (props) => {return (<>{!props.enabled 
//   ? <FontAwesomeIcon color='white' icon={faGoogle} ></FontAwesomeIcon>
//   : !props.checked ? <FontAwesomeIcon {...{...props, icon: faGoogle}}></FontAwesomeIcon>
//   : <OverlayTrigger
//   placement="left"
//   trigger={['hover', 'focus']}
//   overlay={
//     <Tooltip>Download for Google Sheets</Tooltip>
//   }><FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon></OverlayTrigger>}</>);};

// /* function SavedCheck(props){
//   const name = 'cbreport'
//   const [checked, setThisChecked] = useState(true);
//   const updateTable = async() => {
//     await fetch('/api/saved-check/'+name)
//     .then((response) => response.json())
//     .then((data) => {
//       props.setChecked(data.checked);
//       setThisChecked(data.checked);
//     })
//   };
//   useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
//     updateTable();
//   }, []);
//   return (<>
//             <Form>
//               <Form.Check label={props.title} id="checkbox1" htmlFor="checkbox1" 
//               defaultChecked={checked}
//               checked={checked}
//               onChange={(e) => {
//                 setThisChecked(e.target.checked);
//                 props.setChecked(e.target.checked);
//                 fetch('/api/set-check/'+name, {
//                   method: 'POST',
//                   headers: {
//                     'Content-Type': 'application/json'
//                     // 'Content-Type': 'application/x-www-form-urlencoded',
//                   },
//                   body: JSON.stringify({ checked: e.target.checked })
//                 });
//                 }}/>
//             </Form>
//           </>);
// } */

// function HelpInfo(props){
//   const [showDefault, setShowDefault] = useState(false);
//   const handleClose = () => setShowDefault(false);
//   return (<>
//     <React.Fragment>
//       <Button size="sm" href="#gray" bsPrefix="text" variant="primary" className="m-3" onClick={() => setShowDefault(true)}>
        
//         <p><FontAwesomeIcon icon={faInfo} /> click for help </p>
//       </Button>

//       <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
//         <Modal.Header>
//           <Modal.Title className="h6">Info</Modal.Title>
//           <Button variant="close" aria-label="Close" onClick={handleClose} />
//         </Modal.Header>
//         <Modal.Body>
//         <><p> Use: </p>
//               <p> To submit a report input a BusinessReport.csv and Bulk.xlsx file and click submit. </p>
//               <p> You may enter a custom name in the Report Name field </p>
//               <p> To use with Google Sheets click the {<FontAwesomeIcon icon={faGoogle} />} to download a xlsx file. </p>
//               <p>To use with Excel click the {<FontAwesomeIcon icon={faMicrosoft} />} to download an xlsm file. For Excel please enable macros to clean the Breakdown and data Sheet. If you are not able to enable macros please replace all '"='' with '='' and all '@' with '' (nothing) for Sheets "Breakdown" and "data" (Excel should have a find and replace feature on the top right of the {'"Home"'} tab)</p></>
//               <p> the "Toggle Information" checkbox adds information tags to some items (currently only the download icons)</p>
//               <p></p>
//               <p> Side note. entering {"'{i}'"} into the Report Name will add an index for that specific name. {"(this was for testing but could  be helpful)"}</p>
//          {/*  <p>With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.</p>
//           <p>The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.</p> */}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="gray" onClick={handleClose}>
//             Ok
//         </Button>
//           <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
//             Close
//         </Button>
//         </Modal.Footer>
//       </Modal>
//     </React.Fragment>
//   </>);
// }
// export default () => {
//   const [tableData, setTableData] = useState(pageReport);
//   const [lastTableData, setLastTableData] = useState(pageReport);
//   const [bulkFile, setBulkFiles] = useState(null);
//   const [reportFile, setReportFiles] = useState(null);
//   const [bulkInput_ref, setBulkInputRef] = useState("");
//   const [reportInput_ref, setReportInputRef] = useState("");
//   const [msgText, setMSGText] = useState("");
//   const [newNameText, setnewNameText] = useState("");

//   const [isSuccess, setIsSuccess] = useState(false);
//   const [barProgress, setProgress] = useState(0);

//   const [checked, setChecked] = useState(true);
//   const waitUpdateTable = async() => {
//     await timeout(5000); //for 10 sec delay
//     updateTable();
//   };
//   const checkTable = (_data) => {
//     try{
//       if(_data.length != lastTableData.length){ return true; }
//       for (let i = 0; i < _data.length; i++) {
//         const element = _data[i];
//         const element2 = lastTableData[i];
//         if( element.id != element2.id || element.status != element2.status || element.name != element2.name || element.link != element2.link || element.link2 != element2.link2)
//         { return true; }
//       }
//     }catch(err) {console.log(err); return true;}
//     return false;
//   }
//   const updateTable = async() => {
//     await fetch('/finalreportdata')
//     .then((response) => response.json())
//     .then((data) => {
//       if(checkTable(data.tableData)){
//         console.log("updating table");
//         try{
//         if(typeof(data) == 'string'){
//           var _data = JSON.parse(data);
//         }else{var _data = data;}
//         var new_data = [];
//         console.log(_data.tableData);console.log(data);
//         for (let i = 0; i < _data.tableData.length; i++) {
//           const el = _data.tableData[i];
//           new_data.push({ id: el.id,  icon: el.link=='false', icon2: el.link2=='false', status: el.status, name: el.name, link: el.link, link2: el.link2 },)
//         }
//         }catch(err){console.log(err); var new_data = [{id: 1,  icon: true, icon2: true, status: '<-- error', name: String(err), link: '/', link2: '/'}]}
//         setTableData(new_data);//_data.tableData);
//         setLastTableData(new_data);//_data.tableData);
//         console.log(new_data);//_data.tableData);
//       }
//     })
//   };
//   const timeout = (delay) => {
//     return new Promise( res => setTimeout(res, delay) );
//   }
//   const tableLoop = async() => {
//     while(true){
//       await updateTable();
//       await timeout(5000); //for 10 sec delay
//     }
//   }
//   useEffect(() => {//ref={(ref) => { setFileInputRef(ref) }}
//     var test = true
//     // Update the document title using the browser API
//     if (test) {
//       test = false
//       tableLoop();
//       //updateTable();
//     }
//     setProgress(0);
//   }, []);

//   const displayText = async(text) => {
//     var s = ''
//     for (let i = 0; i < text.length; i++) {
//       await timeout(10);
//       const element = text[i];
//       s += element;
//       setMSGText(s);
//     }
//     setMSGText(text);
//     await timeout(2000);
//     while (s.length > 0) {
//       s = s.substring(0, s.length - 1);
//       setMSGText(s);
//       await timeout(5);
//     }
    
//     setMSGText("");
//   }
//   const resetProgress = async() => {
//     await timeout(2000);
//     while (barProgress > 2) {
//       setProgress(barProgress - 5);
//       await timeout(5);
//     }
//     setProgress(0);
//   }
//   const click = () => {
    
//     var some_files = [bulkFile, reportFile]
//     console.log(some_files)
//     const formData = new FormData()
//     for (let index = 0; index < some_files.length; index++) {
//       const element = some_files[index]
//       if (!element) {
//         return
//       }
//       formData.append('File' + String(index + 1), element)
//     }
    
//     console.log('here')
//     console.log(formData)
//     setBulkInputRef("");
//     setReportInputRef("");
//     //displayText('Your files are being sent.');
//     //displayText(" sent.")
//     //const promise = new Promise((resolve, reject) => {
//       //resolve(
//         var url = '/cbreport'+(newNameText=='' ? '' : ('/'+ newNameText));
//         axios.post(url, formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//           onUploadProgress: (progressEvent) => {
//             const myprogress = Math.ceil((progressEvent.loaded / progressEvent.total) * 95);
//             setProgress(myprogress);
//           },
//           onDownloadProgress: (progressEvent) => {
//             const myprogress = Math.ceil(95 + (progressEvent.loaded / progressEvent.total) * 5);
//             setProgress(myprogress);
//           },
//         }).then(()=>{waitUpdateTable();
//           resetProgress();})
//         .catch((error)=>{waitUpdateTable();
//           resetProgress(); console.log(error);});
//         /* fetch(
//           '/cbreport', //?key=somekey',//'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
//           {
//             method: 'POST',
//             body: formData, //new FormData(objectToFormData({ File: selectedFile})),
//             Authorization: 'sometoken', //Bearer
//           },
//         ) */
//           /* .then(
//             //response => setLoaded(false,
//             (response) => {
//               if (response.redirected) {
//                 window.location.href = response.url
//               }
//             },

//             //)
//           )
//           .then() //(response)=>{if(response.redirected){ setLoaded(false);window.location.href = response.url; }})
//           .catch(function (e) {
//             new Notification(
//               'Looks like there was an error processing your requests. It has been logged.',
//             )
//             fetch('/error_report', { method: 'POST', body: String(e), Authorization: 'sometoken' })
//           }) */
//       //)
//     //})
//     /* trackPromise(promise) */
//   }

//   const renameFile = (originalFile, newName) => {
//       return new File([originalFile], newName, {
//           type: originalFile.type,
//           lastModified: originalFile.lastModified,
//       });
//   }
//   const bulkChangeHandler = (event) => {
//     console.log(event.target.files)
//     console.log(event.target.files[0])
//     setBulkFiles(renameFile(event.target.files[0], "bulk.xlsx"))
//   }

//   const reportChangeHandler = (event) => {
//     //console.log(event.target.files)
//     //console.log(event.target.files[0])
//     if(event.target.files.length > 0){
//       var _name = event.target.files[0].name
//       var ext = _name[_name.length-3]+_name[_name.length-2]+_name[_name.length-1]
//       console.log(ext); console.log(_name);
//       setReportFiles(renameFile(event.target.files[0], ext.toLowerCase() =="csv" ? "businessreport.csv" : "businessreport.xlsx"));
//     }
//   }

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       //console.log('do validate');
//       event.preventDefault();
//       click();
//     }
//   }
  
//   return (
//     <>
//       <Row> <Col><h1> Campaign Business Report &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
//         <HelpInfo/> </h1></Col> </Row>
//       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      
//       <Row>
//       <Col xs={12} xl={18}>
//         <Form>
//           <Form.Label>Bulk file (.xlsx) {/* { msgText } */}</Form.Label>
//           <Form.Control type="file" onChange={bulkChangeHandler} key={bulkInput_ref || '' } /* ref={(ref) => { setBulkInputRef(ref) }} */ />
//         </Form>
//         <p></p>
//         <Form>
//           <Form.Label>Business Report (.csv) {/* { msgText } */}</Form.Label>
//           <Form.Control type="file"  onChange={reportChangeHandler} key={reportInput_ref || '' } /* ref={(ref) => { setReportInputRef(ref) }} *//>
//         </Form>
//         <p></p>
//         <div>
//           <CircularProgress variant="determinate"  value={barProgress} color="inherit" />
//           </div>
//           <p></p>
//         <Row>
//           <Col xs={12} xl={18}>
//           <Form>
//             <Form.Group className="mb-3">
//               <Form.Label>Report Name { msgText }</Form.Label>
//               <InputGroup onChange={e => setnewNameText(e.target.value)}>
//                 <Form.Control type="text" placeholder="Enter Report Name..." 
//                 maxlength="100"
//                 onKeyPress={handleKeyDown}
//                 />
//                 <InputGroup.Text><FontAwesomeIcon icon={faFileAlt} /></InputGroup.Text>
//               </InputGroup>
//             </Form.Group>
//           </Form>
//           <Button onClick={()=>{click()}}>Submit</Button>
//           </Col>
//           <Col>
//             {/*  <Progress variant="dark" label="Upload Progress" size="md" value={barProgress} /> */}
           
//             {/* <p> { msgText } </p> */}
//           </Col>
          
//         </Row>
//          {/*  <GeneralInfoForm /> */}
//         </Col>

//         <div className="d-flex">
//         </div>
//         </Row>
//       </div>
//       <Row><Col><h1>&nbsp;</h1></Col></Row>
//       <Row><Col><h1>&nbsp;</h1></Col></Row>
//       <Row>
//         <Col>
//           <Table checkURL='cbreport' tableURL='/finalreportdata' process_id='cbreport'/>
//           {/* <SavedCheck title="Toggle Information" setChecked={setChecked} name='cbreport'/>
//           <ReportTable items={tableData} checked={checked} /> */}
//         </Col>
//       </Row>
//     </>
//   );
// };

