import React from "react";
import { ServiceCard, ServiceH2, ServiceIcon, ServiceP } from "./ServicesElements";

export const Card = ({icon, header, description, link}) => {
    return (
      <ServiceCard  //onClick={()=>{window.location.href=link}}
      >
        <ServiceIcon src={icon}/>
        <ServiceH2> {header} </ServiceH2>
        <ServiceP> {description} </ServiceP>
      </ServiceCard>
    );
  };

  export default Card