import React, {useState, useRef} from 'react';
import { API, Auth, Storage } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { RequireAuth } from '../auth/RequireAuth';
import { Api } from '../auth/APIHandler';
import { S3Excel, getEmail, getProperties, getS3Json, putS3Json } from '../auth/util';
import { CExcel } from 'src/components/Custom/Excel';
import * as XLSX from 'xlsx/xlsx.mjs';
import Spreadsheet, { DataEditor } from "react-spreadsheet";
import { cleanNumberString } from 'src/components/utilities';

const jobConfig = {
    func: 'job_status',
    args: ['865606ee-afe3-44d0-85c2-91dd7c11bb33'],
    kwargs: {},
}





//let pl = new Editor();

export function Test(){
    const ref = useRef(null);
    const {route, user} = useAuthenticator(context => [context.route, context.user]);
    const [d, setData] = useState('nothing')
    let i = 0;
    const func = async() => {
        let response = await Api('job', '/job', undefined, jobConfig);
        setData(JSON.stringify(response))
        return
        try{
        let d = await getEmail() 
        }catch(e){ console.log(e);setData(String(e))}
        setData(String(d))
        await Storage.put('test.json', '{"test": 123}')
        let t = await Storage.get('test.json');
        console.log(t, typeof(t))
        //try{console.log(getProperties(t))}catch(e){console.log(e)}
        setTest(String(t))
        let r = await fetch(String(t))
        .then(async (serverPromise) => {
          return serverPromise.json()
            .then((j) => j)
            .catch((e) => e)
        })
        .catch((e) => e);
        console.log(r)
    }
    const other = async() => {
        try {/* let attributes={};
            try{
                attributes = user.getUserAttributes()
            }catch(e){console.log(e)} */
            let payload = {}
            await Auth.currentSession().then(res=>{
                let accessToken = res.getAccessToken()
                let jwt = accessToken.payload//.getJwtToken()
                payload = jwt
                //You can print them to see the full objects
                console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
                console.log(`myJwt: ${jwt}`)
              })
            await Auth.currentAuthenticatedUser({
                bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
              })
            .then((user) => console.log('user', user, user.getUserAttributes(), user.getUserData()))
            .catch((err) => console.log('user err', err));
            let attributes = await Auth.currentUserPoolUser()//Auth.currentUserInfo()
            /* const { attributes } = await Auth.currentAuthenticatedUser(); */
            console.log('attributes', attributes, JSON.stringify(attributes))
            const request = {
                headers: {
                    //Aithorization: `${user.getSignInUserSession().getIdToken().jwtToken}`,
                    /* 'email': attributes ? ('email' in attributes ? attributes.email: null) : null,
                    'Accept': 'application/json, text/plain,*',  
                    'Accept-Encoding': 'gzip, deflate, br',  */
                    /* 'content-type': 'application/x-www-form-urlencoded',   */
                    'Content-Type': 'application/json',
                    /* 'sec-fetch-mode': 'cors', */
                },
                method: 'POST',
                body: JSON.stringify({'payload': payload})
            }
            console.log('request', request, JSON.stringify(request))
            const data = await API.post('test2','/test2', request)//'test2', '/test2')
            setData(String(data))
        }catch(e) {setData(String(e));console.log('', e)}
    }

    const _handleFile = async (e) => {
        console.log('reading input file:');
        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        console.log(JSON.stringify(workbook.SheetNames))

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: "",
        });
    
        //console.log(e.target.files[0]);
        //console.log(workbook);
        console.log(jsonData);
    }

    return (
        <RequireAuth>
            <div>
                <CExcel onChange={v=>console.log(v)} 
                data={[[{value: 'test', readOnly: true}, {value: '', number: true}, {value: '', number: true}, ]]} />
                <button onClick={()=>{window.location.href = '/'}}> home </button>
                <h1> {d} </h1>
                <button onClick={()=>{
                    i++;
                    console.log(`test${i}`)
                    ref.current.setData(
                        `test${i}`, [
                            [{ value: "Vanilla" }, { value: "Chocolate" }],
                            [{ value: "Strawberry" }, { value: "Cookies" }],
                          ]
                    )
                }}> another </button>
                <S3Excel columnLabels={["Asin" , "Product Group", "Target ACoS" ]}
                ref={ref} id='myTable' tableName={`test${i}`} initData={[
                        [{ value: "Vanilla" }, { value: "Chocolate" }],
                        [{ value: "Strawberry" }, { value: "Cookies" }],
                      ]}/>

                <input
                type="file"
                onInput={(e) => _handleFile(e)}
                />
            </div>
        </RequireAuth>
    )
}

