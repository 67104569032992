import * as XLSX from 'xlsx';

export const write_json_to_excel = (dict_string, config) => {
    // global excel_file
    try{
        let jsVar = JSON.parse(dict_string)
        let _dict = {}

        try{
            _dict = JSON.parse(config)
        }catch(e){ 
            // write(_path, '{"spend":100000, "sales":100000, "cpc":100000, "ctr":100, "acos":100}')
        }

        const do_math = (value, name) => {
            try{
                const v = value / _dict[name];
                if(isNaN(v)) return value;
                return v;
            }catch(e){}
            return value
        }
        const work_data = (data, name) => {
            if (Array.isArray(data)){
                return data.map(v=>do_math(v, name))
            }
            return data
        }

        `'''workbook = xlsxwriter.Workbook('Example3.xlsx')

        # By default worksheet names in the spreadsheet will be
        # Sheet1, Sheet2 etc., but we can also specify a name.
        worksheet = workbook.add_worksheet("My sheet")'''`

        // Some data we want to write to the worksheet.
        let scores = [['dates'].concat(jsVar['timeSeries']['categories'])]
        jsVar['timeSeries']['metrics'].forEach(d => {
            try{
                scores.push([d['name']].concat(work_data(d['dataSet'], d['name'])))
            }catch(e){}
        });
        // console.log(scores)
        // return scores;
        
        // rotate matrix https://stackoverflow.com/questions/15170942/how-to-rotate-a-matrix-in-an-array-in-javascript
        // matrix[0].map((val, index) => matrix.map(row => row[index]).reverse())
        scores = scores[0].map((val, index) => scores.map(row => row[index]).reverse());
        // console.log(scores)
        // For counter-clockwise rotation (Thanks to @karn-ratana):
        // matrix[0].map((val, index) => matrix.map(row => row[row.length-1-index]));

        const headers = scores[0];//scores.map(row=>row[0]);
        const MYdata = scores.slice(1).map(row=>headers.reduce((o, k, i) => ({...o, [k]: row[i]}), {}))
        // [
        //     // {"title":Title1, "website":"Foo"},
        //     // {"title":Title2, "website":"Bar"}
        // ]
        // console.log(MYdata)
        const worksheet = XLSX.utils.json_to_sheet(MYdata);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        return XLSX.writeFile(workbook, "YearToYear.xlsx");
        // for d in jsVar['timeSeries']['metrics']:
        //     try:
        //         scores.append([d['name']] + work_data(d['dataSet'], d['name']))
        //     except:
        //         pass
        // import pandas as pd
        // import openpyxl

        // df = pd.DataFrame(scores)
        // df = df.T
        // df.to_excel(excel_file, sheet_name='new_sheet_name', index=False, header=False)
    }catch(e){
        try{
            // if not os.path.exists('saves'):
            //     os.makedirs('saves')
            // log = f'epoch: "{time.time()}"\\n\\nerror: "{e}"\\n\\n{traceback.format_exc()}\\n\\n\\n'
            // with open(save_dir('error_logs.txt'), 'a') as f:
            //     f.write(log)
        }catch(e){}
        return False
    }
    return True
}