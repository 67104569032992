import { isObject, trim } from "lodash";
import { useRef, useState, useEffect, useCallback, useImperativeHandle, forwardRef } from "react";
import { S3Excel, getEmail, getS3Json, putS3Json, savedIindex } from "src/auth/util";
import { CollapsableAdvancedOptions, MyInputText } from "../STR/STRFormElements";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import Menu from "src/components/Custom/MenuBar";
import { Drop, MenuText } from "src/components/Custom/MenuBar/InputMenu";
import useWindowDimensions from "src/components/Custom/useWindowDimensions";
import { Accordion, Button, Col, Form, InputGroup, Modal, Row } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faFolder } from "@fortawesome/free-solid-svg-icons";
import { RequireGoogleDriveLoggin, getGoogleChildrenFolder, getGoogleDrives } from "src/utils/GoogleHelp";
import { CircularProgress } from "@mui/material";
import { delay, tryParseJSON, tryStringifyJSON } from "src/utils";
import { Api } from "src/auth/APIHandler";
import { uploadUniqueS3File } from "src/documents/submit";

const Example = forwardRef((props, ref)=>{
    useImperativeHandle(ref, ()=>({

    }), [])
    return <></>
})

export const InputContainer = forwardRef((props, ref)=>{
    const {style, children} = props;
    
    return <div style={{
        ...(isObject(style) ? style : {}),
        borderRadius: '10px',
        backgroundColor: '#262B40',
        color: '#000000',
        paddingTop: 25, paddingBottom: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        
        rowGap: '10px',
        columnGap: '2em',
    }}>
        {children}
    </div>
})

export const InputBox = forwardRef((props, ref)=>{
    const {style, children} = props;
    
    return <div style={{
        ...(isObject(style) ? style : {}),
        borderRadius: '10px',
        backgroundColor: '#202436',
        color: '#ffffff',
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
    }}>
        {children}
    </div>
})

export const Profile = forwardRef((props, ref)=>{

})

const Date = forwardRef((props, ref) => {
    const {onChange, defaultValue, ...rest} = props;
  
    const [date, __setDate] = useState(null);  // defaultValue || dayjs());
    const _setDate = useCallback(e => { __setDate(e); }, [date]);
  
    useImperativeHandle(ref, () => ({
      getDate(){ return date; },
      setDate(_date){ _setDate(_date); }
    }));
  
    return (<DatePicker {...rest} value={date} onChange={(d) =>  {console.log('set ddate', d)
    _setDate(d);onChange(d);}} />)
  })

export const API = forwardRef((props, ref)=>{
    const dateToString = date => date.format('YYYY-MM-DD');
    const { height, width } = useWindowDimensions();

    useImperativeHandle(ref, ()=>({
        async ready(){try{
            return !!_profileInfo.value.profile && !!_profileInfo.value.start && !!_profileInfo.value.end;
        }catch(e){return false}},
        async table(){return [{..._profileInfo.value, profile: JSON.stringify(_profileInfo.value.profile)}]}, 
        async start(){}
    }), []);

    const _profileInfo = useRef({value: {profile: null, start: null, end: null}});
    const __setProfileInfo = v => {_profileInfo.value = (typeof v === 'function' ? v(_doc.value) : v);}
    const [profileInfo, _setProfileInfo] = useState({profile: null, start: null, end: null});
    const setProfileInfo = useCallback((e) => { __setProfileInfo(e); _setProfileInfo(e); }, [profileInfo]);
    const [profiles, setProfiles] = useState([]);
    const [profileLabel, setProfileLabel] = useState("Choose Account Profile");
    const inputMenuRef = useRef(null);

    

    const updateProfiles = async() => {
        const _profiles = await getS3Json('AMAZON_ADS_API/amazon_api_profiles.json');
        
        let ps = [];
        Object.keys(_profiles).forEach(k=>{
          ps = ps.concat(_profiles[k]);
        })
        
        let pss = [];
        ps.forEach(v=>{
          pss.push({value: v, name: `${v.countryCode} - ${v.accountInfo.name}`})
        })
        
        inputMenuRef?.current?.setLocalData(pss);
        setProfiles(pss);
      }

      useEffect(() => {
        updateProfiles()
      }, []);

    const today = dayjs().add(1, 'day');
    const tomorrow = dayjs().subtract(120, 'day');
    return <div style={{display:'flex', flexWrap: 'wrap', width: '90%'}}>
    
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{...(width > 1203 ? {display: 'flex'} : {gridAutoColumns: 'auto'}),
            // justifyContent: 'space-between', 
            backgroundColor: '#fff', padding: '10px 10px 10px 10px', margin: '10px 10px 10px 10px', borderRadius: '5px'}}>
              
              <div style={{display: 'flex'}}>
                <Date label="Start Date" defaultValue={today} minDate={tomorrow} 
                // value={value}
                onChange={(date) => setProfileInfo({...profileInfo, start: dateToString(date)})}//setValue(newValue)} 
                disableFuture/> 
                
                <h1 style={{color: '#ababab'}}> - </h1>
                <Date label="End Date" defaultValue={today} minDate={tomorrow} 
                // value={value}
                onChange={(date) => setProfileInfo({...profileInfo, end: dateToString(date)}) }//setValue(newValue)} 
                disableFuture/> 
              </div>
            </div>
          </LocalizationProvider>
          <div style={{width: '60%', height: 50}}>
              <MenuText ref={inputMenuRef}
              className='child2' data={profiles} 
              select={(n, v)=>{ 
                setProfileLabel(n);
                setProfileInfo({...profileInfo, profile: v}); //setProfiles(v);  
              }} 
                title={profileLabel}/>
              </div>
    </div>
})


export const CBReport = forwardRef((props, ref)=>{
    const {allTables} = props;
    useImperativeHandle(ref, ()=>({
        async ready(){
            return _doc.value;},
        async table(){return [{doc: _doc.value.this_id}]}, 
        async start(){}
    }), []);

    const [menuLabel, setMenuLabel] = useState("Choose a C. B. Report");
    const [menuValue, setMenuValue] = useState(""); 

    const _doc = useRef({value: false}).current;
    const [doc, _setDoc] = useState(null);
    const setDoc = useCallback((e) => { _doc.value = typeof e === 'function' ? e(_doc.value) : e;
        _setDoc(e); }, [doc]);

    const [menuData, setMenuData] = useState([{value: "something", name: "Just a moment..",}]);

    const objCheck = (obj) => {
        try{ return isObject(obj) && 'this_id' in obj && 'display_name' in obj && 'asins' in obj.extra && obj.this_file.name.endsWith('x'); } catch (e){}
        return false;
        //old  --> // if(!isObject(obj)){return false;} if(!('extra' in obj && 'this_file' in obj)){return false;}  if(!isObject(obj.this_file)){return false;} if(typeof obj.this_file.name != 'string'){return false;} return 'this_id' in obj && 'display_name' in obj && 'asins' in obj.extra && obj.this_file.name.endsWith('x');
      }
    const updateMenu = async() => {
        console.log('menuu start')
        const email = await getEmail();
        await getS3Json(`jobs/${email}/cbreport.json`)
        .then(async d=>{
    
          let j = await Promise.all(d.map(async v=> await getS3Json(`docs/${v.id}.json`).then(jj=>jj).catch(e=>{console.log(e); return 'error';})));
          j = j.filter(o=>objCheck(o));
          j = j.map(v=>{return {value: JSON.stringify(v), name: v.display_name};});
          console.log('manuu data', j)
          if(Array.isArray(j)){setMenuData(j)} })
        .catch(e=>{console.log(e);})
      };

      useEffect(() => {
        updateMenu();
      }, []);

    const putTable = async __data => {
        const tryJson = j => {try{return JSON.parse(j);}catch(e){}return j;}
        let _data = tryJson(__data);
        
        // console.log('asin table ', table);
        if(_data.hasOwnProperty('this_id')) {
            setDoc(_data);
            if(isObject(allTables)){
                console.log(allTables)
                if('BidCalculationExternalInput' in allTables){
                    const _ref = allTables['BidCalculationExternalInput'][Object.keys(allTables['BidCalculationExternalInput']).slice(0)[0]];
                    setBidCalculator(_ref, _data.this_id);
                }
                if('AsinTableExternalInput' in allTables){
                    const _ref = allTables['AsinTableExternalInput'][Object.keys(allTables['AsinTableExternalInput']).slice(0)[0]];
                    setAsinTable(_ref, _data);
                }
            }else{ console.log('not obj is', typeof allTables)}
            // excelRef.current.setData(`asinTable-${_data.this_id}`, table);
        }else{ console.log('no doc id') }
    }
    
    const setAsinTable = async(excelRef, _data) => {
        const email = await getEmail();
        let d = await getS3Json(`${email}/allAsins.json`);
        let allAsins = isObject(d) ? d : {};
        const asins = _data.extra.asins;
        let table = [[{ value: "default", readOnly: true }, { value: "N / A", readOnly: true }, { value: (allAsins.hasOwnProperty("default") ? allAsins.default[2].value : "40.0"), number: true }]];
        asins.forEach(a=>{
        let adGroup = ''; let acos = '';
        if(allAsins.hasOwnProperty(a)){
            adGroup = allAsins[a][1].value;
            acos = allAsins[a][2].value;
        }
        table.push(
            [{ value: String(a), readOnly: true }, { value: adGroup }, { value: acos, number: true }]
        )
        })

        if(_data.hasOwnProperty('this_id')) {
            console.log('excel', excelRef, excelRef)
            excelRef.setData(`asinTable-${_data.this_id}`, table);
        }
    }

    const setBidCalculator = async(advanceOptions, documentId) => {
        console.log('advanceOptions', advanceOptions, advanceOptions)
        advanceOptions.update(String(documentId));
    }

    return <div style={{width: '90%', height: 150}}>
        <Menu className='child2' title={menuLabel} data={menuData} 
        select={(n, v)=>{ setMenuLabel(n); setMenuValue(v); putTable(v); }}/>
    </div>
});

export const AsinTable = forwardRef((props, ref)=>{
    const excelRef = useRef(null);
    useImperativeHandle(ref, ()=>({
        async ready(){return true},
        async table(){
            let tableData = await excelRef.current.getData();
            const fix = data => data.map(row=>({Asin: row[0].value, "Product Group": row[1].value, "Target ACoS": Number(row[2].value)}))
            return fix(tableData);
        }, 
        async start(){},
        setData(documentId, table){
            console.log('setting data', `asinTable-${documentId}`, table)
            excelRef.current.setData(`asinTable-${documentId}`, table);
        }
    }))

    return <>
        <S3Excel 
            ref={excelRef} 
            tableName={`emptyAsinsTest`} 
            columnLabels={["Asin" , "Product Group", "Target ACoS" ]}
            initData={[
              [{ value: "default", readOnly: true }, { value: "N / A", readOnly: true }, { value: "40.0", number: true }],
            ]}
            onChange={async _tableData=>{
              let email = await getEmail();
              let d = await getS3Json(`${email}/allAsins.json`);
              let allAsins = isObject(d) ? d : {};
              _tableData.forEach(row=>{allAsins[row[0].value] = row;});
              putS3Json(`${email}/allAsins.json`, allAsins);
            }}
          />
    </>
});

export const BidCalculation = forwardRef((props, ref)=>{
    const advanceOptions = useRef(null);
    
    useImperativeHandle(ref, ()=>({
        async ready(){
            try{this.table();return true;}catch(e){return false;}
            return true},
        async table(){
            let bidData = await advanceOptions.current.getData();
            return bidData;
        }, 
        async start(){},
        update(documentId){
            advanceOptions.current.update(String(documentId));
        }
    }))

    return <div style={{width: '90%'}}>
        <CollapsableAdvancedOptions ref={advanceOptions} name={'default'} />
    </div>
})

export const ToGoogleSpreadsheet = forwardRef((props, ref)=>{
    const { output_query, defaultFolder, template} = props;
    const textRef = useRef(null);
    const folderRef = useRef(null);
    const [driveSet, setDriveSet] = useState(false);
    const [drives, _setDrives] = useState([]);
    const requireLoginRef = useRef();
    const setDrives = useCallback(v=>_setDrives(v), [drives]);
    const simpleDisplayRef = useRef();
    const SimpleDisplay = forwardRef((props, ref)=>{
        let lastFolder = localStorage.getItem('ToGoogleSpreadsheet');
        if(isObject(defaultFolder)){if('id' in defaultFolder && 'name' in defaultFolder){ lastFolder = tryStringifyJSON(defaultFolder, _=>lastFolder);}}
        console.log('lastFolder', lastFolder)
        const [selectedFolder, _setSelectedFolder] = useState(!!lastFolder ? tryParseJSON(lastFolder, ()=>{}) : {});
        const setSelectedFolder = useCallback(v=>_setSelectedFolder(v), [selectedFolder]); 
        useImperativeHandle(ref, ()=>({
            set(v){setSelectedFolder(v);localStorage.setItem('ToGoogleSpreadsheet', JSON.stringify(v))},
            get(){return selectedFolder;}}))
        return <>
            <p style={{color: '#4A5073', fontWeight: 'bold', marginTop: 40, marginBottom: 20}}> save to: 
                <p style={{backgroundColor: '#ffffff', paddingLeft: 10, paddingRight: 10, borderRadius: 10, width: 'fit-content'}}>{selectedFolder?.name?.join('/')}/</p>
            </p>
        </>
    });

    const addToTable = async (name, spreadsheetId) => {
        const email = await getEmail();
        const sheetKey = `reports/${email}/spreadSheetStatus/${output_query}/${spreadsheetId}.json`
        const key = `tables/${email}/${output_query}.json`;
        let table = await getS3Json(key);
        if(!Array.isArray(table)) {table = [];}
        table.unshift({spreadsheetId: spreadsheetId, name: name, status: sheetKey});
        putS3Json(sheetKey, {spreadsheetId: spreadsheetId, name: name, status: 'waiting'})
        putS3Json(key, table);
    }

    useImperativeHandle(ref, ()=>({
        async text(){
            return await getText();
        },
        setText(txt){
            textRef.current.setText(txt);
        },
        async ready(){return trim(await getText()) != '' && (!!simpleDisplayRef?.current?.get()?.name ? simpleDisplayRef?.current?.get()?.name.length > 0 : false);},
        async table(){
            const email = await getEmail();
            const txt = await getText();
            const folder = simpleDisplayRef.current.get()
            const to_folder_id = folder.id.slice(-1)[0];

            let response = await Api('googleapi', '/googleapi', undefined, {
                'api': 'create_copy_from_template',
                'name': txt, 'folder_id':(to_folder_id || '1_OkhrIWEunjMWT5FzhVn-bWlA3StQBLC'), 'template_id': (template || '1oVSM4fdD0uxFwgbLzhsuUZVs3sIcZKDO1eQ-qTJhupg')
            })
            
            // console.log(response);
            const spreadsheetId = response.spreadsheet_id;
            addToTable(txt, spreadsheetId);
            return [{spreadsheet_id: spreadsheetId, email: email}]  // to_folder_id, template_sheet_id
        }, 
        async start(){}
    }));

    const getText = async() => {
        let fileName = textRef.current.getText();
        if(fileName.includes('{i}') || fileName.includes('[i]')){
            let ind = await savedIindex(fileName.replace('[i]', '').replace('{i}', ''))
            fileName = fileName.replace('[i]', String(ind)).replace('{i}', String(ind))
        }
        return fileName;
    }
    const fetchDrives = async() => {
        const _drives = await getGoogleDrives();
        setDriveSet(true);
        if(Array.isArray(_drives)) setDrives(_drives);
        else{//failed
            requireLoginRef.current.logOut(); 
            (async()=>{await delay(5000);requireLoginRef.current.logOut();})()
        }
        
    }
    useEffect(()=>{fetchDrives()}, [])

    let first = true;
    useEffect(()=>{if(first){first = false;if(drives.length > 0 && !localStorage.getItem('ToGoogleSpreadsheet')){
        console.log('setting', localStorage.getItem('ToGoogleSpreadsheet'))
        simpleDisplayRef?.current?.set({id: [drives[0].id], name: [drives[0].name]})}}}, [drives])

    const Folder = forwardRef((props, ref)=>{
        const {driveName, driveId, folder} = props;
        const {id: ids, name: names} = folder;
        names[0] = driveName;
        const [id, name] = [ids.slice(-1)[0], names.slice(-1)[0]];
        const [closed, _setClosed] = useState(true);
        const setClosed = useCallback(v=>_setClosed(v), [closed])

        const [folders, _setFolders] = useState({'set': false, 'folders': []});
        const setFolders = useCallback(v=>_setFolders(v), [folders]);
        const fetchFolders = async() => {
            let _folders = await getGoogleChildrenFolder(driveId, id);
            if(Array.isArray(_folders)){
                localStorage.setItem(id, JSON.stringify(_folders))
            }
            setFolders({set: true, folders: _folders});
        }
        

        const get = folders => {
            try{
                return folders?.folders?.map(folder=><Folder key={id} driveId={driveId} driveName={driveName} folder={folder}/>);
            }catch(e){

                return <pre> error: {JSON.stringify(folders, null, 3)} </pre>
            }
        }
        

        return <Accordion defaultActiveKey={id} onSelect={e=>{
            // setSelectedFolder({id: ids, name: names})
            let state = e === null ? 'closing' : 'opening';
            if(state === 'opening' && !folders.set) {
                let lastFolders = localStorage.getItem(id);
                if(lastFolders){lastFolders = tryParseJSON(lastFolders, ()=>null)}
                if(!lastFolders){fetchFolders();}else{setFolders({set: true, folders: lastFolders})}
                simpleDisplayRef?.current?.set({id: ids, name: names});
            }
            setClosed(e === null)}}>
            <Accordion.Item /* eventKey={eventKey} */>
            <Accordion.Button className="d-flex justify-content-between align-items-center">
            <span>
            <span className="sidebar-icon"><FontAwesomeIcon icon={faFolder} /> </span>
            <span className="sidebar-text">{name}</span>
            {/* <p style={{position: 'relative', float: 'center', backgroundColor: 'white', borderRadius: 10}}> select </p> */}
            </span>
            </Accordion.Button>
            <Accordion.Body className="multi-level">
                <div style={{marginBottom: 20}}>
                    {!folders.set ? <CircularProgress size={20}/> : get(folders)}
                </div>
                <div>
                    <Button variant="white" size="sm" onClick={async()=>{setFolders({'set': false, 'folders': []}); await delay(100); fetchFolders()}}> refresh content </Button>
                </div>
            </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    })

    
    
    return <RequireGoogleDriveLoggin ref={requireLoginRef} variant='not primary'>
        <div style={{width: '90%'}}>
            <MyInputText ref={textRef} onEnter={()=>{}}/>
            <SimpleDisplay ref={simpleDisplayRef}/>
            {driveSet ? "" : <CircularProgress size={20}/>}
            {drives?.map(drive=><Folder key={drive.id} driveId={drive.id} driveName={drive.name} folder={{id: [drive.id], name: [drive.name]}}/>)}
        </div>
    </RequireGoogleDriveLoggin>
});

export const FromExcel = forwardRef((props, ref)=>{
    const {name="file", title="Upload File", tables={}} = props;
    const advanceOptions = useRef(null);
    const [fileRef, setBulkInputRef] = useState("");
    // const [bulkFile, setBulkFiles] = useState(null);
    const file = useRef({file: null}).current;

    const bulkChangeHandler = (event) => {
        // setBulkFiles(renameFile(event.target.files[0], "bulk.xlsx"))
        const _file = event.target.files[0];
        let ext = _file.name.split('.').slice(-1)[0];

        console.log('from', _file.name, 'to', `${name}.${ext}`)
        file.file = renameFile(event.target.files[0], `${name}.${ext}`);
    }
    
    const renameFile = (originalFile, newName) => {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    useImperativeHandle(ref, ()=>({
        async ready(){
            return file.file !== null;
        },
        async table(){
            let data = [
                {name: file.file.name, s3Path: await uploadUniqueS3File(file.file), tables: tables}
            ];
            return data;
        }, 
        async start(){},
        update(documentId){
            
        }
    }))

    return <div style={{width: '90%', paddingTop: 20, paddingBottom: 40}}>
        <Form>
          <Form.Label> {title} {/* { msgText } */}</Form.Label>
          <Form.Control type="file" onChange={bulkChangeHandler} key={fileRef || '' } /* ref={(ref) => { setBulkInputRef(ref) }} */ />
        </Form>
    </div>
})

// export const STReport = forwardRef((props, ref)=>{
//     const {allTables} = props;
//     const allRefs = useRef({}).current;

//     const AllInputs = {
//         ToGoogleSpreadsheet: ToGoogleSpreadsheet,
//         CBReport: CBReport,
//         API: API,
//         AsinTable: AsinTable,
//         BidCalculation: BidCalculation,
//     }

//     const uniqueKey = (key, keys) => {
//         return key;
//         let k = key;
//         if(!keys.includes(key)) return key;
//         let i = 1;
//         while(keys.includes(`${key}${i}`)){i++;}
//         return `${key}${i}`;
//     }
    
//     return <InputContainer>
//         {Object.keys(AllInputs).map(k=>{const C = AllInputs[k]; 
//             return <InputBox><C ref={r=>{allRefs[uniqueKey(k, Object.keys(allRefs))] = r}} allTables={allRefs}/></InputBox>;})}
//         {/* <ToGoogleSpreadsheet ref={r=>{allRefs['ToGoogleSpreadsheet'] = r}}/>
//         <CBReport />
//         <AsinTable />
//         <API />
//         <BidCalculation /> */}
//     </InputContainer>
// })


const AllInputs = {
    ToGoogleSpreadSheetInput: ToGoogleSpreadsheet,
    ApiExternalInput: API,
    CBReportExternalInput: CBReport,
    AsinTableExternalInput: AsinTable,
    BidCalculationExternalInput: BidCalculation,
    FromExcel: FromExcel,
}

export default AllInputs;

