import styled from "styled-components";
import { theme } from "../Theme";

export const ServicesContainer = styled.div`
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${theme.darkColor1};

    @media screen and (max-width: 768px) {
        height: 2400px;
    }
    @media screen and (max-width: 480px) {
        height: 2400px;
    }
`;

export const ServiceWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;


export const ServiceCard = styled.div`
    background: ${theme.lightColor3};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        //cursor: pointer;
    }
`;

export const ServiceIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`;
export const ServicesH1 = styled.h1`
    color: ${theme.lightColor3};// ? '#f7f8fa' : '#010606')}
    font-size: 2.5rem;
    margin-bottom: 64px;
    
    @media screen and (max-width: 480px) {
        front-size: 2rem;
    }
`;
export const ServiceH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
    
    color: ${theme.darkColor1};// ? '#f7f8fa' : '#010606')}
    /*
    @media screen and (max-width: 480px) {
        front-size: 32px;
    } */
`;


export const ServiceP = styled.p`
    //color: ${theme.color1};//#01bf71;
    font-size: 1rem;
    text-align: center;
    color: ${theme.darkColor1};
`;

