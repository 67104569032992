import React, {useState} from 'react';
import { API, Auth } from 'aws-amplify';
import { RequireAuth } from '../auth/RequireAuth';


export async function Api(name, endpoint, headers=null, obj=null, method='POST'){
    const func = async() => {
        try {
            let payload = {}
            let i = 0;
            //const c = () => {console.log(i);i++;}
            await Auth.currentSession().then(res=>{
                let accessToken = res.getAccessToken()
                let jwt = accessToken.payload//.getJwtToken()
                payload = jwt
                //You can print them to see the full objects
                /* console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
                console.log(`myJwt: ${jwt}`) */
              })
            /* await Auth.currentAuthenticatedUser({
                bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
              })
            .then((user) => console.log('user', user, user.getUserAttributes(), user.getUserData()))
            .catch((err) => console.log('user err', err)); */
            let attributes = await Auth.currentUserPoolUser()
            //console.log('attributes', attributes, JSON.stringify(attributes))
            let body = {'payload': payload};
            if (typeof(obj) == "object"){body = {'payload': payload, ...obj}}
            let _headers = {
                'Content-Type': 'application/json',
            }
            if (typeof(headers) == "object"){_headers = {..._headers, ...headers}}
            const request = {
                headers: _headers,
                method: method,
                body: JSON.stringify(body)
            }
            //console.log('request', request, JSON.stringify(request))
            let data = ''
            await API.post(name, endpoint, request)
            .then((v)=>{data =  v})
            .catch((e)=>{data =  e})  // 'test2','/test2',
            //c()
            //console.log(data)
            return data
        }catch(e) {return e}
    }
    return func()
}

