import React, { Component, useState, useEffect, useCallback, useImperativeHandle, forwardRef, useRef } from 'react';
import { distance } from "../Levenshtein";
import { Input } from '@material-ui/core';
import _css from '!!raw-loader!./my.css';
//import "./my.css";
import { AnimatePresence, motion, Variants } from "framer-motion"//"framer-motion/dist/framer-motion";
import styled from 'styled-components';
import { timeout } from 'src/components/utilities';
import zIndex from '@mui/material/styles/zIndex';
const Style =  styled.div`
all: initial !important;
${_css}
/** Additional customization if necessary (e.g. positioning) */
`;


const itemVariants = {//: Variants
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 }
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
  };

  

  const _sortOptions = (_options, value) => {
    if(typeof(value) != 'string') return _options;
    if(value.length < 1) return _options;

    const dis = (a, b) => {
      let total = 0;
      a.split(' ').forEach(s=>{
        let m = Number.MAX_VALUE;
        b.split(' ').forEach(c=>{
          m = Math.min(m, distance(s, c));
        })
        total += m;
      })

      return total;
    }

    return [].concat(_options).sort((a, b) => dis(value, a.name) > dis(value, b.name) ? 1 : -1);
  }

  const sortOptions = (options, value) => {
    return _sortOptions(options, value)
  }
  
  export const MenuText = forwardRef((props, ref) => {
    const [isOpen, _setIsOpen] = useState(false);
    const setIsOpen = useCallback((e) => { _setIsOpen(e); }, [isOpen]);
    const [data, _setData] = useState(props.data ? props.data : []);
    const setData = useCallback((e) => { _setData(e); }, [data]);

    // input vars
    const [inputText, _setInputText] = useState('');
    const setInputText = useCallback((e) => { _setInputText(e); }, [inputText]);
    const [inputPosition, _setInputPosition] = useState(0);
    const setInputPosition = useCallback((e) => { _setInputPosition(e); }, [inputPosition]);

    useImperativeHandle(ref, () => ({
      setLocalData (d){
        console.log('setting data ', d);

        setData(d);
      }
  
    }));

    const inputChange = (e) => {
      let v = e.target.value;
      setInputText(v)
      console.log(v, typeof v);
      if(typeof v !== 'string') return;
      let newData = sortOptions(data, v);
      console.log('new data ', newData)
      setData(newData)
    
    }
    
    useState(()=>{
        (async()=>{})()
    }, [])

    return (<Style>
      <div className='menu'>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        className="menu"
      >
        <motion.button
          whileTap={{ scale: 0.97 }}
          onClick={() => setIsOpen(true)}
          disabled={isOpen}
        >
          {!isOpen ? props.title : <Input 
          style={{width: '100%'}}
          autoFocus
          value={inputText} 
          onBlur={async() => {await timeout(200); setIsOpen(false)}}

          // id='myInput'
          // onClick={()=>{
          //   setInputPosition(document.getElementById('myInput').selectionStart);
          // }}
          onKeyDown={e=>{
            // console.log(e.key);
            // if(e.key === ' ') {e.preventDefault();
            //   const p = inputPosition;
            //   setInputPosition(e.target.selectionStart);
            //   setInputText(
            //     prev=>[inputText.slice(0, p), ' ', inputText.slice(p)].join('')//https://stackoverflow.com/questions/4364881/inserting-string-at-position-x-of-another-string//inputText+' '
                
            //   )
            //   Promise.resolve().then(() => {
            //     e.target.setSelectionRange(inputPosition+1, inputPosition+1);
            //   });
            // }
              
            if(e.key === 'Enter' && data.length > 0) {
              let item = data[0];
              props.select(item.name, item.value);
              setIsOpen(false);
            }
          }}
          
          // onKeyUp={ e => {
          //     setInputPosition(e.target.selectionStart);
          // }}
          onChange={inputChange}/>}

          <motion.div
          // onClick={() => setIsOpen(false)}
            variants={{
              open: { rotate: 180 },
              closed: { rotate: 0 }
            }}
            transition={{ duration: 0.2 }}
            style={{ originY: 0.55 }}
          >
            <svg width="15" height="15" viewBox="0 0 20 20">
              <path d="M0 7 L 20 7 L 10 16" />
            </svg>
          </motion.div>
        </motion.button>
        <v /* style={{ scaleX: scrollYProgress }} */>
        <motion.ul
          variants={{
            open: {
              clipPath: "inset(0% 0% 0% 0% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.7,
                delayChildren: 0.3,
                staggerChildren: 0.05
              }
            },
            closed: {
              clipPath: "inset(10% 50% 90% 50% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3
              }
            }
          }}
          style={{ pointerEvents: isOpen ? "auto" : "none" }}
        >
          <v>
          {!isOpen ? '' : data?.map(function(item, i){
            return <motion.li 
            key={i} 
            onClick={()=>{
              props.select(item.name, item.value);
              setIsOpen(false);
            }} 
            variants={itemVariants}
            > 
            {item.name} 
            </motion.li>
          })}
          </v>
        </motion.ul>
        </v>
      </motion.nav>
      </div>
      </Style>
    );
  });

export class Drop extends Component {
    static defaultProps = {
      value: '',
      options:  ['1', '2', 'three'],
      startOpen: false,
      forceValue: false,
      onChange: e=>{},
    }
    state = {
      value: this.props.value,
      options: this.props.options,
      open: this.props.startOpen,
      forceValue: this.props.forceValue,
    }
  
    handleInputChange = (e) => {
      this.setState({
        value: e.target.value, 
        options: this._sortOptions(this.state.options, e.target.value),
        open: true
      });
    }
  
    handleItemClick = (r) => {
      this.setState({value: r, open: true});
      this.props.onChange({target: {value: r}})
    }
  
    onExit = () => {
      if(this.state.forceValue){
        let v = this.sortOptions();
        console.log('v1: ', v)
  
        let val = v[0];
        console.log('val: ', val)
        this.props.onChange({target: {value: val}})
      }
    }
  
    handleKeyDown = e => {
      if(e.key == 'Enter') {
        this.onExit();
      }
    }
  
    sortOptions = () => {
      return this._sortOptions(this.state.options, this.state.value)
    }
  
    _sortOptions = (_options, value) => {
      if(typeof(value) != 'string') return _options;
      if(value.length < 1) return _options;
  
      return [].concat(_options).sort((a, b) => distance(a, value) > distance(b, value) ? 1 : -1);
    }
    
    render () {
      const {value, options, open} = this.state;
      return (<>
        <div style={{backgroundColor: "white", textAlign: 'center', transition: "all .3s ease-in-out"}}
        onClick={()=>{this.setState({open: !open});}}>
          <Input />
          <p> {value} </p>
        </div>
        <div style={{backgroundColor: "white", textAlign: 'center', transform: open ? "scale(1)" : "scale(0)", transition: "all .3s ease-in-out"}}>
          {(open ? options : [])?.map(r => <p onClick={()=>{this.handleItemClick(r)}}> {r} </p>)}
          {/* {(open ? options : [])?.map(r => <p onClick={()=>{this.setState({value: r, open: false});this.onValueChange(r);}}> {r} </p>)} */}
        </div>
      </>);
    }
  }