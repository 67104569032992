import React from 'react';
import { getEmail, UPLOAD_FOLDER } from '../auth/util';
import { v4 as uuidv4 } from 'uuid';
import { Api } from '../auth/APIHandler';
import { Storage } from 'aws-amplify';
import { timeout } from 'src/components/utilities';

//async for dependencies
export async function getDownloadLocation(name){ await timeout(1); return [UPLOAD_FOLDER, name].join('/') }

export const uploadS3UUID = async (file, addPrefix=false, onUploadProgress=(e) => {}) => {
  const uid = uuidv4(); 
  let fileName = uid + file.name;
  const path = await getDownloadLocation(fileName)
  console.log(path)
  const r = await Storage.put(path, file, {progressCallback: onUploadProgress});
  console.log(r)
  return (addPrefix ? 'public' : '') + fileName;
}

export const uploadUniqueS3File = async (file, addPrefix=false, onUploadProgress=(e) => {}) => {
    const uid = uuidv4(); 
    let fileName = uid + file.name;
    const path = await getDownloadLocation(fileName)
    console.log(path)
    const r = await Storage.put(path, file, {progressCallback: onUploadProgress});
    console.log(r)
    return 'public/' + path;
}

export async function submitJob(processId, documentName, _files, extra=null, omitXLSM=false){
    let documentIDxlsx = String(uuidv4());
    let documentIDxlsm = String(uuidv4());
    

    //console.log(`started job`);
    const email = await getEmail();
    if(`${email}` == '[object Promise]'){
        await email.then(d=>{console.log(d)})
    }
    //console.log(`e: ${email}, id: ${processId}, d: ${documentName}, fs: ${_files}`);
    let files = {
        //'': {name: '', email: email}
    }
    _files.forEach(e => {
        //console.log(`e: ${e}`);
        files[e.id] = {name: e.name, email: email}
        //console.log(`vals: files[${e.id}] = {name: ${e.name}, email: ${email}}`);
    });
    
    let doc = {
        display_name: documentName,
        this_id: documentIDxlsx,
        process_id: processId,
        files: files,
        email: email,
        start_time: parseFloat(Date.now()) / 1000.,  // miliseconds to seconds
        override_path: false,
        this_file: { document_id: documentIDxlsx, name: documentName+'.xlsx', email: email},
        extra: extra,
    }
    if(omitXLSM){
        return [await Api('document', '/document', undefined, {'doc': doc})];
    }
    let xlsmDoc = JSON.parse(JSON.stringify(doc));  // copy json
    xlsmDoc.this_file = {document_id: documentIDxlsm, name: documentName+'.xlsm', email: email}  // .xlsx -> .xlsm
    xlsmDoc.this_id = documentIDxlsm
    
    console.log(`doc: ${JSON.stringify(doc)}`);
    return [await Api('document', '/document', undefined, {'doc': doc}), 
        await Api('document', '/document', undefined, {'doc': xlsmDoc})]
}




